import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Alert from '../../../assets/img/icons/Alert';
import Sucesso from '../../../assets/img/icons/Sucesso';

/* eslint-disable */
const ModalMensagem = (props) => {
  const { modal, toggle, mensagem, sucesso, continuar, tipo, marginLeft } = props;
  const mensagemUser = props.user.mensagem;

  function verifyOption() {
    window.location.reload();
    toggle();
  }

  const marginLeftcss = marginLeft ? marginLeft : '9rem';

  function getTitle() {
    if (sucesso) {
      if (continuar == 'code') return '';

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="icon" style={{ marginLeft: `${marginLeftcss}`, marginBottom: '0.8rem' }}>
            <Sucesso />
          </div>
          <h1 style={{ paddingLeft: '10rem' }}>{tipo}</h1>
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="icon" style={{ marginLeft: '3rem', marginBottom: '0.8rem' }}>
          <Alert />
        </div>
        <h1 style={{ paddingLeft: '3.2rem' }}>Algo inesperado aconteceu</h1>
      </div>
    );
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>
          <div className="title">{getTitle()}</div>
        </ModalHeader>

        <ModalBody style={{ display: 'flex', justifyContent: 'space-around' }}>
          {mensagem != '' && mensagem != null ? mensagem : mensagemUser}
        </ModalBody>
        <ModalFooter>
          <Button
            color={sucesso == true ? 'success' : 'danger'}
            onClick={() => verifyOption()}
            style={styles.button}
            size="sm"
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const styles = {
  button: {
    width: '100%',
  },
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(ModalMensagem);
