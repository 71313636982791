import Dashboard from './views/Dashboard/Dashboard.jsx';
import ViewUser from './views/User/ViewUser.jsx';
import Cobans from './views/Cobans/Cobans.jsx';
import AntecipationID from './views/Anticipation/AntecipationID.jsx';
import CardSales from './views/CardSales/CardSales.jsx';
import CardSalesInn from './views/CardSalesInn/CardSalesInn.jsx';
import Payment from './views/Payment/Payment.jsx';
import DigitalServices from './views/DigitalServices/DigitalServices.jsx';
import BilletSales from './views/BilletSales/BilletSales.jsx';
import TransferBetweenAccounts from './views/TransferBetweenAccounts/TransferBetweenAccounts';
import Withdrawals from './views/Transfer/Transfer';
import Log from './views/Log/Log';
import Configurations from './views/Configs/Configurations.jsx';
import Loan from './views/Loan/Loan';
import GerenciarClientes from './views/User/GerenciarClientes.js';
// import Notifications from './views/Notifications/index.js';
import Notifications from './views/Notificacao/Notificacao';
import Report from './views/Report/Report';
import Products from './views/Products/Products.jsx';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fas fa-th-large',
    component: Dashboard,
    layout: '/admin',
    id: '1',
  },
  {
    path: '/beneficios',

    name: 'Produtos/benefícios',
    icon: 'fas fa-th-large',
    component: Products,
    layout: '/admin',
    id: '38',
  },
  {
    path: '/gerenciar-usuarios',
    name: 'Gerenciar Clientes',
    icon: 'fas fa-th-large',
    component: GerenciarClientes,
    layout: '/admin',
    id: '2',
  },

  {
    path: '/pagamento',
    name: 'Pagamentos',
    icon: 'fas fa-th-large',
    component: Payment,
    layout: '/admin',
    id: '23',
  },

  {
    path: '/relatorios-parametrizados',
    name: 'Relatórios Parametrizados',
    icon: 'fas fa-th-large',
    component: Report,
    layout: '/admin',
    id: '35',
  },

  {
    path: '/checkouts',
    name: 'Transferências P2P',
    icon: 'fas fa-th-large',
    component: TransferBetweenAccounts,
    layout: '/admin',
    id: '7',
  },

  {
    path: '/transferencias',
    name: 'Transferências',
    icon: 'fas fa-th-large',
    component: Withdrawals,
    layout: '/admin',
    id: '34',
  },

  {
    path: '/transacao/boletos',
    name: 'Transações Boletos',
    icon: 'fas fa-th-large',
    component: BilletSales,
    layout: '/admin',
    id: '9',
  },

  {
    path: '/transacao/cartao',
    name: 'Transações Cartões',
    icon: 'fas fa-th-large',
    component: CardSales,
    layout: '/admin',
    id: '18',
  },

  {
    path: '/transacao/Recebimento-Cartao',
    name: 'Recebimentos de Cartões',
    icon: 'fas fa-th-large',
    component: CardSalesInn,
    layout: '/admin',
    id: '36',
  },

  {
    invisible: false,
    path: '/solicita/emprestimo',
    name: 'Empréstimos',
    icon: 'fas fa-th-large',
    component: Loan,
    layout: '/admin',
    id: '27',
  },
  {
    path: '/Perfis',
    name: 'Perfis/Usuários',
    icon: 'fas fa-th-large',
    component: Cobans,
    layout: '/admin',
    id: '3',
  },
  {
    invisible: true,
    path: '/gerenciar-usuarios/view',
    name: 'Usuário',
    icon: 'fas fa-th-large',
    component: ViewUser,
    layout: '/admin',
    id: '2',
  },

  {
    path: '/log',
    name: 'Logs',
    icon: 'fas fa-th-large',
    component: Log,
    layout: '/admin',
    id: '17',
  },

  {
    path: '/configuracoes',
    name: 'Configurações',
    icon: 'fas fa-th-large',
    component: Configurations,
    layout: '/admin',
    id: '15',
  },

  {
    path: '/servicos-digitais',
    name: 'Serviços Digitais',
    icon: 'fas fa-th-large',
    component: DigitalServices,
    layout: '/admin',
    id: '30',
  },

  {
    invisible: true,
    path: '/antecipacoes/view',
    name: 'Antecipação',
    icon: 'fas fa-th-large',
    component: AntecipationID,
    layout: '/admin',
    id: '21',
  },

  {
    invisible: false,
    path: '/contabil/razonete',
    name: 'Razonete',
    icon: 'fas fa-th-large',
    component: AntecipationID,
    layout: '/admin',
    id: '31',
  },

  {
    invisible: false,
    path: '/notificacoes',
    name: 'Notificações',
    component: Notifications,
    layout: '/admin',
    id: '33',
  },
];
export default routes;
