import React from 'react';
import { Col, Row } from 'reactstrap';

function ScoreBio(props) {
  const { score, status, createdAt, gender } = props.data;
  return (
    <div>
      <Row xs="2">
        <Col>
          <span>Score: </span>
        </Col>
        <Col>{score}</Col>
      </Row>
      <Row xs="2">
        <Col>
          <span>Descrição:</span>
        </Col>
        <Col>
          <p style={{ color: 'green' }}>{status}</p>
        </Col>
      </Row>
      <Row xs="2">
        <Col>
          <span>Data de envio: </span>
        </Col>
        <Col>{createdAt}</Col>
      </Row>
      <Row xs="2">
        <Col>
          <span>Sexo identificado: </span>
        </Col>
        <Col>{gender}</Col>
      </Row>
    </div>
  );
}

export default ScoreBio;
