import styled from 'styled-components';

export const Container = styled.div`
  .swal-modal {
    .swal-text {
      background-color: #fefae3;
      padding: 17px;
      border: 1px solid #f0e1a1;
      display: block;
      margin: 22px;
      font-size: 40px;
      text-align: center;
      color: #61534e;
    }
  }
`;
