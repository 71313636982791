import React from 'react';
import { Spring } from 'react-spring/renderprops';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
const CustomContainer = (props) => {
  return (
    <Spring from={{ opacity: -20 }} to={{ opacity: 1 }}>
      {(props1) => (
        <div style={props1}>
          <Container fluid style={{ position: 'relative', top: -60, ...props.style }}>
            <Row>
              <Col xl="12">
                <Card className="shadow">
                  <CardBody>{props.children}</CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </Spring>
  );
};

export default CustomContainer;
