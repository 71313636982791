/* eslint-disable */
const formatTextCamelCase = (text) => {
  let textFormated = [];
  let i = 0;

  try {
    textFormated = text.toLowerCase().split(' ');
    textFormated &&
      textFormated.map((elem) => {
        const novo = elem.split('');
        novo[0] = novo[0].toUpperCase();
        textFormated[i] = novo.join('');
        i++;
      });

    return textFormated.join(' ') + '';
  } catch (error) {
    return '';
  }
};

export default formatTextCamelCase;
