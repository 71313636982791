import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Spring } from 'react-spring/renderprops';
import { Card, Container, Row } from 'reactstrap';
import Button from '../../components/Button/Button.style';
import InputDate from '../../components/Filter/components/InputDate';
import * as Styled from '../../components/Filter/styles/Filter.style';
import Icon from '../../components/Icon/Icon.styles.js';
import Loading from '../../components/Loader/Loading';
import StatusContainerValue from '../../components/StatusContainer/StatusContainerValue.js';
import Table from '../../components/Table/Table';
import NoRegisterFound from '../../components/Texts/NoRegisterFound.jsx';
import { validationPassword } from '../../store/coban/actions';
import { getExtract } from '../../store/extract/actions';
import { getBalanceHistory, listaUser } from '../../store/user/actions.js';
import { formatMoney } from '../../utils/formatMoneyBRL';
import { removeSpecialCharacters } from '../../utils/functions.js';
import { query } from '../../utils/query';
import valueEmpty from '../../utils/valueEmpty.js';
import FormUserNew from './FormUserNew';

/* eslint-disable */
const ViewUser = (props) => {
  const dispatch = useDispatch;
  const params = query(props.location);
  const [reloadCards, setReloadCards] = useState(false);
  const [senha, setSenha] = useState({
    SENHA: '',
    STATUS: false,
    ERRO: false,
  });
  const [filter, setFilter] = useState({
    CPFCNPJ_CLIENT: params.user,
    LIMIT: '',
    DATA_INICIO: moment().add(-7, 'days').format('YYYY-MM-DD'),
    DATA_FIM: moment().format('YYYY-MM-DD'),
    PAGE: 1,
    SIZE: 10,
  });

  useEffect(() => {
    const params = query(props.location);
    var LIMIT = filter.LIMIT;
    const CPFCNPJ_CLIENT = removeSpecialCharacters(params.user);
    var DATA_INICIO = filter.DATA_INICIO;
    var DATA_FIM = filter.DATA_FIM;
    props.listaUser(CPFCNPJ_CLIENT);
    props.getBalanceHistory({ CPFCNPJ_CLIENT, LIMIT, DATA_INICIO, DATA_FIM });
  }, []);

  const { saldo } = useSelector((state) => state.user);
  const fichaGrafica = saldo && saldo.HISTORICO;
  const [typeTransaction, setTypeTransactions] = useState(1);
  const { loadingExtract, messageExtract, extract } = useSelector((state) => state.extract);
  const tabs = ['Extrato'];

  useEffect(() => {
    if (typeTransaction === 1) {
      const params = query(props.location);
      const CPFCNPJ_CLIENT = removeSpecialCharacters(params.user);

      const objectParams = {
        CPFCNPJ_CLIENT: CPFCNPJ_CLIENT,
        DATA_INICIO: filter.DATA_INICIO,
        DATA_FIM: filter.DATA_FIM,
        SIZE: filter.SIZE,
        PAGE: filter.PAGE,
      };

      props.getBalanceHistory(objectParams);
    }
  }, [typeTransaction]);

  const searchWithFilter = () => {
    if (typeTransaction === 1) {
      props.getBalanceHistory(filter);
    }
    setReloadCards(!reloadCards);
  };

  const clickButton = () => {
    props.validationPassword({ SENHA: senha.SENHA }, (res) => {
      const { retorno } = res;
      retorno === 'sucesso'
        ? setSenha({ ...senha, STATUS: true, ERRO: false })
        : setSenha({ ...senha, STATUS: false, ERRO: true });
    });
  };

  const renderFilter = () => {
    return (
      <div style={{ padding: '30px 0px 2.5px 0px' }}>
        {/* retirado para aguardar o desenvilvimento de senhas
        {localStorage.getItem('nivelAcesso') != 1 && senha.STATUS === false && (
          <>
            <h4>Digite a senha de permissão para visualizar a ficha gráfica</h4>
            <Styled.InputsContainer className="Senha Acesso">
              <InputPassword
                label="Senha"
                name="SENHA"
                value={senha.SENHA}
                setValue={(value) => setSenha({ ...senha, SENHA: value })}
                erro={senha.ERRO}
                erroMenssagem={'Senha incorreta'}
              />
              <div>
                <Button
                  width={'110px'}
                  marginTop={'34px !important'}
                  marginLeft={'15px'}
                  onClick={() => clickButton()}
                >
                  Confirmar
                </Button>
              </div>
            </Styled.InputsContainer>
          </>
        )} */}

        {localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
          <Styled.InputsContainer>
            <div>
              <Skeleton animation={false} width={60} />
              <Skeleton animation={false} width={100} />
            </div>
            <div>
              <Skeleton animation={false} width={60} />
              <Skeleton animation={false} width={100} />
            </div>
            <Skeleton animation={false} width={100} />
          </Styled.InputsContainer>
        ) : localStorage.getItem('nivelAcesso') == 1 || senha.STATUS === true ? (
          <Styled.FilterContainer>
            <Styled.InputsContainer>
              {/* <InputSearch
    width="30vw"
    value={params.user}
    setValue={(value) => setFilter({ ...filter, SEARCH: value })}
  /> */}
              <InputDate
                label="Data Inicial"
                name="DATA_INICIO"
                value={filter.DATA_INICIO}
                setValue={(value) => setFilter({ ...filter, DATA_INICIO: value })}
              />
              <InputDate
                label="Data Final"
                name="DATA_FIM"
                value={filter.DATA_FIM}
                setValue={(value) => setFilter({ ...filter, DATA_FIM: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <Button
                  width={'110px'}
                  marginTop={'34px !important'}
                  marginLeft={'15px'}
                  onClick={() => searchWithFilter()}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </Button>
              </div>
            </Styled.InputsContainer>
          </Styled.FilterContainer>
        ) : null}
      </div>
    );
  };

  const renderTable = () => (props.user.loading ? true : false);

  useEffect(() => renderTable, [props.user.loading]);

  return props.user.loading_user ? (
    <Loading color="#2405f2" />
  ) : props.user.user == null ? (
    <h3>Usuário não encontrado</h3>
  ) : (
    <Spring from={{ opacity: -31 }} to={{ opacity: 1 }}>
      {(props) => (
        <div style={props}>
          <div style={{ position: 'relative', top: -100 }}>
            <div className="header">
              <Container fluid></Container>
            </div>
            <FormUserNew
              user={props.user && props.user.user}
              saldo={props.user && props.user.saldo}
              reloadCards={reloadCards}
              searchWithFilter={searchWithFilter}
            />

            <Card
              style={{
                marginRight: '35px',
                marginLeft: '35px',
                marginTop: '20px',
              }}
            >
              <Row
                xs="2"
                style={{
                  marginTop: '15px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              >
                {tabs &&
                  tabs.map((tab, index) => {
                    return (
                      <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px', paddingLeft: '1rem' }}>
                        Extrato
                      </Typography>
                    );
                  })}
              </Row>
              {typeTransaction === 1 && (
                <Row style={{ marginBottom: '20px' }}>
                  <div className="col" style={{ marginLeft: '25px', marginRight: '25px' }}>
                    {renderFilter()}
                    {renderTable() ? (
                      <Loading color="#2405f2" />
                    ) : (
                      <div>
                        {fichaGrafica && fichaGrafica.length > 0 ? (
                          localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
                            <>
                              <Skeleton animation={false} fullWidht height={60} />
                              <Styled.InputsContainer
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                                <Skeleton animation={false} width={150} />
                              </Styled.InputsContainer>
                            </>
                          ) : localStorage.getItem('nivelAcesso') == 1 || senha.STATUS == true ? (
                            <Table
                              style={{
                                textAlign: 'center',
                                tableLayout: 'fixed',
                              }}
                              headers={[
                                {
                                  info: 'Data/Hora',
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: 'Tipo',
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: 'Descrição',
                                  style: { textAlign: 'left' },
                                },
                                {
                                  info: 'Valor',
                                  style: { textAlign: 'center' },
                                },
                                {
                                  info: 'Saldo Anterior',
                                  style: { textAlign: 'right' },
                                },
                                {
                                  info: 'Saldo',
                                  style: { textAlign: 'right' },
                                },
                              ]}
                            ></Table>
                          ) : null
                        ) : localStorage.getItem('nivelAcesso') != 1 && senha.STATUS == false ? (
                          <Skeleton animation={false} fullWidht height={60} />
                        ) : (
                          <NoRegisterFound />
                        )}

                        <InfiniteScroll
                          dataLength={fichaGrafica && fichaGrafica.length}
                          hasMore={true}
                          height={props.user && props.user.saldo && props.user.saldo.HISTORICO.length}
                        >
                          <Table
                            style={{ textAlign: 'center', tableLayout: 'fixed' }}
                            data={
                              fichaGrafica
                                ? localStorage.getItem('nivelAcesso') == 1 || senha.STATUS == true
                                  ? fichaGrafica.map((log, index) => {
                                      return {
                                        data: [
                                          {
                                            info: log.DATAHORA,
                                            style: { textAlign: 'center' },
                                          },
                                          {
                                            info: log.TIPO ? log.TIPO : '-',
                                            style: { textAlign: 'center' },
                                          },
                                          {
                                            info: log.DESCRICAO,
                                            style: { textAlign: 'left' },
                                          },
                                          {
                                            info: valueEmpty(
                                              log.VALOR ? (
                                                <StatusContainerValue code={log.TIPO} description={log.VALOR} />
                                              ) : null,
                                            ),
                                            style: {
                                              textAlign: 'center',
                                              whiteSpace: 'nowrap',
                                            },
                                          },
                                          {
                                            info: log.SALDO_ANTERIOR ? `R$${formatMoney(log.SALDO_ANTERIOR)}` : '-',
                                            style: { textAlign: 'right' },
                                          },
                                          {
                                            info: log.SALDO_ATUAL ? `R$${formatMoney(log.SALDO_ATUAL)}` : '-',
                                            style: { textAlign: 'right' },
                                          },
                                        ],
                                      };
                                    })
                                  : null
                                : null
                            }
                          />
                        </InfiniteScroll>
                      </div>
                    )}
                  </div>
                </Row>
              )}
            </Card>
          </div>
        </div>
      )}
    </Spring>
  );
};

const mapStateToProps = ({ user, extract }) => {
  return { user, extract };
};

export default connect(mapStateToProps, {
  listaUser,
  getBalanceHistory,
  getExtract,
  validationPassword,
})(ViewUser);
