import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table.jsx';
// reactstrap components
import moment from 'moment';
import { Badge, Form, Row } from 'reactstrap';
import GenericButton from '../../components/Buttons/Button.js';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import InputDate from '../../components/Filter/components/InputDate.js';
import InputSearch from '../../components/Filter/components/InputSearch.js';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults.js';
import InputStatus from '../../components/Filter/components/InputStatus.js';
import Filter from '../../components/Filter/Filter.js';
import * as Styled from '../../components/Filter/styles/Filter.style';
import Icon from '../../components/Icons/IconsButton.js';
import Loading from '../../components/Loader/Loading.jsx';
import { getBilletTransactions, getBilletTransactionsID } from '../../store/transactions/actions.js';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL.js';
import ModalInformationsBillet from './ModalInformationsBillet.jsx';

export default function BilletSales() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [id, setId] = useState('');
  const [filter, setFilter] = useState({
    limit: 10,
    search: '',
    page: 1,
    status: 0,
    dataInicial: '',
    dataFinal: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    dispatch(getBilletTransactions(filter));
  }, [filter.page]);

  const {
    listBilletSales: { PAGINATION, RETORNO },
    detailBilletSales,
    loading_transaction,
  } = useSelector((state) => state.transactions);

  let { page } = filter;

  const clickInfo = (ID) => {
    dispatch(getBilletTransactionsID(ID));
    setId(ID);
    setModal(true);
  };

  const close = () => {
    setModal(!modal);
  };

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = PAGINATION.prevPage;
        break;
      case 'next':
        page = PAGINATION.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page });
  };

  return (
    <div>
      <CustomContainer>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <Styled.InputsContainer>
              <InputSearch
                width="25vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
              />

              <InputStatus
                setValue={(value) => setFilter({ ...filter, status: value })}
                options={['Todas', 'Aguardando', 'Aprovado', 'Cancelado', 'Vencido']}
                codeValue={['', 1, 2, 3, 4]}
              />

              <InputDate
                label="Data Inicial"
                name="dataInicial"
                value={filter.dataInicial}
                setValue={(value) => setFilter({ ...filter, dataInicial: value })}
              />

              <InputDate
                label="Data Final"
                name="dataFinal"
                value={filter.dataFinal}
                setValue={(value) => setFilter({ ...filter, dataFinal: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <GenericButton
                  marginTop={'25px !important'}
                  width={'140px'}
                  heigth={'35px'}
                  type="submit"
                  color="primary"
                  size="sm"
                  onClick={() => {
                    dispatch(getBilletTransactions(filter));
                    filter.page = '1';
                  }}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
              </div>
            </Styled.InputsContainer>
            <div>
              <InputSelectAmountResults
                options={['10', '25', '50', '100', '1000']}
                value={filter.limit}
                setValue={(value) => setFilter({ ...filter, limit: value })}
              />
            </div>
          </Form>
        </Filter>

        <Row>
          <div className="col">
            {!PAGINATION ? (
              <h4 style={{ textAlign: 'center' }}>Carregando Transações</h4>
            ) : (
              <div>
                {loading_transaction ? (
                  <Loading color="#11cdef" />
                ) : (
                  <Table
                    headers={[
                      {
                        info: 'Data Emissão',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'Nome',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'CPF/CNPJ',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Beneficiário',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'CPF/CNPJ Beneficiário',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Vencimento',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'Valor',
                        style: { textAlign: 'right' },
                      },
                      {
                        info: 'STATUS',
                        style: { textAlign: 'center' },
                      },
                    ]}
                    data={
                      RETORNO &&
                      RETORNO.map((sale) => {
                        return {
                          data: [
                            {
                              info: sale.DATAREGISTRO,
                              style: { textAlign: 'center' },
                            },
                            {
                              info: sale.NOME,
                              style: { textAlign: 'left' },
                            },
                            {
                              info: formatCPF(sale.CPFCNPJ),
                              style: { textAlign: 'left' },
                            },
                            {
                              info: sale.NOMEBENEFICIARIO,
                              style: { textAlign: 'left' },
                            },
                            {
                              info: formatCPF(sale.CPFCNPJBENEFICIARIO),
                              style: { textAlign: 'left' },
                            },
                            {
                              info: sale.DATAVENCIMENTO,
                              style: { textAlign: 'center' },
                            },
                            {
                              info: `R$ ${formatMoney(sale.VALORDOCUMENTO)}`,
                              style: { textAlign: 'right' },
                            },
                            {
                              info:
                                sale.STATUSID === '1' ? (
                                  <Badge color="info">{sale.STATUS}</Badge>
                                ) : sale.STATUSID === '2' ? (
                                  <Badge color="success">{sale.STATUS}</Badge>
                                ) : sale.STATUSID === '3' ? (
                                  <Badge color="danger">{sale.STATUS}</Badge>
                                ) : sale.STATUSID === '4' ? (
                                  <Badge color="secondary">{sale.STATUS}</Badge>
                                ) : null,
                              style: { textAlign: 'center' },
                            },
                          ],
                          onClick: () => clickInfo(sale.ID),
                        };
                      })
                    }
                    pagination={PAGINATION}
                    previousPage={{ onClick: () => tablePagination('prev') }}
                    nowPage={{ page, onClick: PAGINATION }}
                    nextPage={{ onClick: () => tablePagination('next') }}
                  />
                )}
              </div>
            )}
          </div>
        </Row>
      </CustomContainer>
      <ModalInformationsBillet
        toggle={close}
        modal={modal}
        title={'Dados do boleto'}
        data={detailBilletSales}
        ID={id}
      />
    </div>
  );
}
