import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { formatMoney } from '../../../utils/formatMoneyBRL';
import formatCPF from '../../../utils/formatCPF';
import formatCNPJ from '../../../utils/formatCNPJ';
import addStatusStyle from '../utils/verifyStatus';

const ModalInformationsLoan = (props) => {
  const { modal, toggle, title, data } = props;
  const { VALOR, DATA, ID, DESCRICAO_STATUS, NOME, CPFCNPJ, NOME_PRODUTO, CARACTERISTICA } = data;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>{title}</h3>
      </ModalHeader>
      <ModalBody
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          wordBreak: 'break-word',
        }}
      >
        <Row style={styles.metade}>
          <Col>
            <h5>Código da Transação:</h5>
            <p>{ID}</p>
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Status:</h5>
            <p>{addStatusStyle(DESCRICAO_STATUS)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Nome:</h5>
            <p>{NOME}</p>
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>CPF/CNPJ:</h5>
            <p>{CPFCNPJ && CPFCNPJ.length === 11 ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Data/Hora:</h5>
            <p>{DATA}</p>
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Valor:</h5>
            <p>R$ {formatMoney(VALOR)}</p>
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Característica:</h5>
            <p>{CARACTERISTICA}</p>
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Nome do Produto:</h5>
            <p>{NOME_PRODUTO}</p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

export default ModalInformationsLoan;
