import React from 'react';
import { connect } from 'react-redux';
// reactstrap components
import { Badge, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';

// core components
import CustomContainer from '../../components/Custom/CustomContainer';
import Loading from '../../components/Loader/Loading';
import ModalAntecipacao from '../../components/Modais/Antecipacao/ModalAntecipacao';
import Table from '../../components/Table/Table.jsx';
import { getAnticipation, getAnticipationID } from '../../store/transactions/actions';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL';
import { query } from '../../utils/query';

/* eslint-disable */

class AnticipationID extends React.Component {
  state = {
    ID: null,
    modal: false,
    collapseSales: [],
    selecionados: [],
    naoSelecionados: [],
    toggle: true,
  };

  componentDidMount() {
    const params = query(this.props.location);
    if (params.antecipacao) {
      this.props.getAnticipationID(params.antecipacao, (data) => {
        var length = data.VENDAS ? data.VENDAS.length : 0;
        this.setState({
          ID: params.antecipacao || '',
          collapseSales: new Array(length).fill(false),
        });
      });
    } else {
    }
  }

  selecionaCheckbox = (selecionados, ID) => {
    const encontra = selecionados.find((elemento) => elemento == ID);
    if (encontra == undefined) {
      selecionados.push(ID);
    } else {
      selecionados = selecionados.filter((data) => data != ID);
    }
    this.setState({ selecionados });
  };

  async clickButton(selecionados) {
    const { listSales } = this.props.transactions;
    const total = listSales.map(function (item, indice) {
      return item.ID;
    });

    var filtered = [];
    total.map((valor) => {
      if (!selecionados.includes(valor)) {
        const obj = { IDVENDA: valor, MOTIVO: '' };
        filtered.push(obj);
      }
    });
    await this.setState({ naoSelecionados: filtered });
    this.setState({ modal: true });
  }

  clickCheck() {
    if (this.state.selecionados.length > 0) this.setState({ selecionados: [] });
    else {
      const selecionados = this.props.transactions.listSales.map(function (item, indice) {
        return item.ID;
      });
      this.setState({ selecionados });
    }
  }

  render() {
    const { loading_transactions, DADOS_VENDA, listSales } = this.props.transactions;
    const { CPFCNPJ, LUCRO_TOTAL, MOTIVOREPROVACAO, STATUS_ANTECIPACAO, VALOR_ANTECIPACAO, VALOR_DESCONTADO, NOME } =
      DADOS_VENDA;
    const { collapseSales } = this.state;

    return (
      <CustomContainer>
        <Row className="ml-2" xs="6">
          <Col>
            <Row>
              <b>ID Antecipação: </b>
              <span id="antecipationID" data-status="1" style={styles.headerInfos}>
                {this.state.ID}
              </span>
            </Row>
            <Row>
              <b>CPF/CNPJ: </b>
              <span id="antecipationCPFCNPJ" style={styles.headerInfos}>
                {formatCPF(CPFCNPJ)}
              </span>
            </Row>
            <Row>
              <b>Nome:</b>
              <span id="antecipationCPFCNPJ" style={styles.headerInfos}>
                {NOME}
              </span>
            </Row>
          </Col>
          <Col>
            <Row>
              <b>Total antecipável: </b>
              <span id="valorAntecipavel" style={styles.headerInfos}>
                R$ {formatMoney(VALOR_ANTECIPACAO)}
              </span>
            </Row>
            <Row>
              <b>Total de taxas: </b>
              <span id="valorTaxas" style={styles.headerInfos}>
                {' '}
                R$ {formatMoney(VALOR_DESCONTADO)}
              </span>
            </Row>
          </Col>
          <Col>
            <Row>
              <b>Qtd. de vendas: </b>
              <span id="qtdVendasAntecipaveis" style={styles.headerInfos}>
                {listSales.length || 0}
              </span>
            </Row>
          </Col>
          {STATUS_ANTECIPACAO == 1 && (
            <Col>
              <Row>
                <b>ID Dos selecionados: </b>
                <span style={styles.headerInfos}>{this.state.selecionados + ' '}</span>
              </Row>
            </Col>
          )}
        </Row>
        <Row>
          {loading_transactions ? (
            <Loading color="#11cdef" />
          ) : (
            <Col>
              <Row className="mb-3" style={{ marginLeft: 9.5 }}>
                {STATUS_ANTECIPACAO == 1 && (
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" onChange={() => this.clickCheck()} /> Selecionar Todas
                    </Label>
                  </FormGroup>
                )}
              </Row>
              <Row>
                <Col>
                  <Table
                    headers={[
                      'DATA',
                      'CÓDIGO DE TRANSAÇÃO',
                      'VENDA DIGITADA',
                      'IRIA RECEBER',
                      'FORMA DE PAGAMENTO',
                      'PARCELAS PAGAS',
                      'À RECEBER ANTECIPÁVEL',
                      'VALOR DESCONTADO',
                      'STATUS',
                    ]}
                    data={listSales.map((a) => {
                      return {
                        data: [
                          STATUS_ANTECIPACAO == 1 ? (
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={this.state.selecionados.includes(a.ID)}
                                onChange={() => this.selecionaCheckbox(this.state.selecionados, a.ID)}
                              />{' '}
                              {a.DATA}
                            </Label>
                          ) : (
                            a.DATA
                          ),
                          a.ID,
                          'R$ ' + formatMoney(a.VENDADIGITADA),
                          'R$ ' + formatMoney(a.VALOR_TOTAL_PARCELAS),
                          a.PARCELAS + 'X de R$ ' + formatMoney(a.VALORPARCELADO),
                          a.PARCELAS_PAGAS + ' de ' + a.PARCELAS,
                          'R$ ' + formatMoney(a.VALORRECEBER),
                          'R$' + formatMoney(a.VALORDESCONTADO),
                          a.STATUS == 'AGUARDANDO' ? (
                            <Badge color="info">{a.STATUS}</Badge>
                          ) : a.STATUS == 'APROVADO' ? (
                            <Badge color="success">{a.STATUS}</Badge>
                          ) : a.STATUS == 'CANCELADO' ? (
                            <Badge color="danger">{a.STATUS}</Badge>
                          ) : a.STATUS == 'VENCIDO' ? (
                            <Badge color="secondary">{a.STATUS}</Badge>
                          ) : null,
                        ],
                      };
                    })}
                  />
                </Col>
              </Row>
              <Row className="mt-3" style={{ marginLeft: 0 }}>
                <Button color="secondary" size="sm" onClick={() => (window.location.href = '/admin/antecipacoes')}>
                  voltar
                </Button>{' '}
                {STATUS_ANTECIPACAO == 1 && (
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => this.clickButton(this.state.selecionados, this.state.naoSelecionados)}
                  >
                    Antecipar/exportar
                  </Button>
                )}
              </Row>
            </Col>
          )}
        </Row>
        <ModalAntecipacao
          modal={this.state.modal}
          fechar={() => this.setState({ modal: false })}
          toggle={this.state.toggle}
          selecionados={this.state.selecionados}
          naoSelecionados={this.state.naoSelecionados}
          IDANTECIPACAO={this.state.ID}
        ></ModalAntecipacao>
      </CustomContainer>
    );
  }
}

const styles = {
  headerInfos: {
    marginLeft: 5,
  },
};

const mapStateToProps = ({ transactions }) => {
  return { transactions };
};

export default connect(mapStateToProps, {
  getAnticipation,
  getAnticipationID,
})(AnticipationID);
