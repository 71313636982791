import { FormControl, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { Controller } from 'react-hook-form';
import * as Styled from './SelectOptions.styles';

/* eslint-disable */

const SelectOptions = ({
  options,
  control,
  name,
  width,
  height,
  variant,
  margin,
  padding,
  initialValue,
  disabled,
  label,
  className,
  borderRadius,
  required,
  error,
  onClick,
}) => {
  return (
    <Styled.ContainerStyled
      width={width}
      margin={margin}
      borderRadius={borderRadius}
      role="select-container"
      className={className}
    >
      <FormControl variant={variant}>
        {label ? <InputLabel id={name}>{label}</InputLabel> : null}
        <Controller
          name={name}
          control={control}
          defaultValue={initialValue}
          render={({ field }) => (
            <Styled.SelectStyled
              {...field}
              height={height}
              width={width}
              padding={padding}
              disabled={disabled}
              defaultValue={initialValue}
              className={className}
              required={required}
              error={error}
            >
              {options &&
                options.map((option) => (
                  <MenuItem
                    key={option.ID || option.id}
                    value={option.ID || option.id}
                    onClick={(e) => (onClick ? onClick(option.ID || option.id) : null)}
                  >
                    {option.NOME || option.nome}
                  </MenuItem>
                ))}
            </Styled.SelectStyled>
          )}
        ></Controller>
      </FormControl>
    </Styled.ContainerStyled>
  );
};

export default SelectOptions;
