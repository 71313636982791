import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import AnimatedLoading from '../../components/Loader/AnimatedLoading';
import StatusContainerTed from '../../components/StatusContainer/StatusContainerTed';
import Table from '../../components/Table/Table.jsx';
import { getTedHistory, tedAproved, tedNegaded, tedRejected } from '../../store/transactions/actions';
import formatCNPJ from '../../utils/formatCNPJ';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL';
import ModalMensagem from './component/ModalMensagem';

const ModalInfoWithdrawals = (props) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [modalMensagem, setModalMensagem] = React.useState(false);
  const [sucesso, setSucesso] = React.useState(false);
  const [mensagem, setMensagem] = React.useState('');
  const [tipo, setTipo] = React.useState('');
  const [loaded, setLoaded] = React.useState(false);
  const { modal, toggle, title, data } = props;
  const {
    ID,
    NOME,
    DATA,
    HORA,
    STATUS,
    VALOR,
    NOME_BANCO_ORIGEM,
    NOME_BANCO_DESTINO,
    TAXA,
    AGENCIA_ORIGEM,
    AGENCIA_DESTINO,
    DESCRICAO,
    NUM_CONTA_DESTINO,
    NUM_CONTA_ORIGEM,
    DIG_CONTA_DESTINO,
    DIG_CONTA_ORIGEM,
    CPFCNPJ,
    VALOR_TOTAL,
    IDSTATUS,
    NOME_TRANSFERENCIA,
    CPFCNPJ_TRANSFERENCIA,
  } = data;

  /* eslint-disable */

  React.useEffect(() => {
    dispatch(getTedHistory(ID));
    setExpanded(false);
  }, [ID, dispatch]);

  const { historyTed } = useSelector((state) => state.transactions);

  async function deny() {
    await dispatch(
      tedNegaded(
        {
          ID: ID,
          CPFCNPJ_USUARIO: CPFCNPJ,
          CPFCNPJ_COBAN: localStorage.getItem('CPFCNPJ'),
          VALOR: VALOR,
        },
        (res) => {
          setLoaded(false);
          openModalMessage(res.data, 'Reprovado');
        },
      ),
    );
  }

  async function rejected() {
    await dispatch(
      tedRejected(
        {
          ID: ID,
          CPFCNPJ_COBAN: localStorage.getItem('CPFCNPJ'),
        },
        (res) => {
          setLoaded(false);
          openModalMessage(res.data, 'Reprovado');
        },
      ),
    );
  }

  async function aproved() {
    await dispatch(
      tedAproved(
        {
          ID: ID,
          CPFCNPJ_USUARIO: CPFCNPJ,
          CPFCNPJ_COBAN: localStorage.getItem('CPFCNPJ'),
          VALOR: VALOR,
        },
        (res) => {
          setLoaded(false);
          openModalMessage(res.data, 'Aprovado');
        },
      ),
    );
  }

  const openModalMessage = (res, tipo) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);

    setMensagem(mensagem);
    setTipo(tipo);
    setModalMensagem(true);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3>{title}</h3>
        </ModalHeader>
        <ModalBody
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            wordBreak: 'break-word',
          }}
        >
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Código da Transação</h5>
              <p>{ID}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Tipo Transação</h5>
              <p>{DESCRICAO ? DESCRICAO : '-----'}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Data / Hora</h5>
              <p>
                {DATA} {HORA}
              </p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Status Transação</h5>
              <StatusContainerTed code={IDSTATUS} description={STATUS} />
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Valor</h5>
              <p>R$ {formatMoney(VALOR)}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Valor Total</h5>
              <p>R$ {formatMoney(VALOR_TOTAL)}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Taxa</h5>
              <p>R$ {formatMoney(TAXA)} </p>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col>
              <h4>Origem</h4>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Nome</h5>
              <p>{NOME}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>CPF/CNPJ</h5>
              <p>{CPFCNPJ && CPFCNPJ.length === 11 ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Banco</h5>
              <p>{NOME_BANCO_ORIGEM}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Agência</h5>
              <p>{AGENCIA_ORIGEM}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Conta</h5>
              <p>
                {NUM_CONTA_ORIGEM}-{DIG_CONTA_ORIGEM}
              </p>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col>
              <h4>Destino</h4>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Nome</h5>
              <p>{NOME_TRANSFERENCIA}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>CPF/CNPJ</h5>
              <p>
                {CPFCNPJ_TRANSFERENCIA && CPFCNPJ_TRANSFERENCIA.length === 11
                  ? formatCPF(CPFCNPJ_TRANSFERENCIA)
                  : formatCNPJ(CPFCNPJ_TRANSFERENCIA)}
              </p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Banco</h5>
              <p>{NOME_BANCO_DESTINO}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Agência</h5>
              <p>{AGENCIA_DESTINO}</p>
            </Col>
          </Row>
          <Row style={{ width: '49%' }}>
            <Col>
              <h5>Conta</h5>
              <p>
                {NUM_CONTA_DESTINO}-{DIG_CONTA_DESTINO}
              </p>
            </Col>
          </Row>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            sx={{
              width: '100%',
              boxShadow: '0',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                bgcolor: 'rgb(236, 239, 240)',
                borderRadius: '5px',
                paddingTop: '0.4rem',
              }}
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                <h5>Historico TED</h5>
              </Typography>
              <Typography>
                {expanded === false ? (
                  <h6>
                    {historyTed.length > 0
                      ? historyTed[historyTed.length - 1].STATUS_FINAL + ' | ' + historyTed[historyTed.length - 1].DATA
                      : null}
                  </h6>
                ) : null}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Table
                  headers={[
                    {
                      info: 'Status',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: 'Descrição',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: 'Data',
                      style: { textAlign: 'center' },
                    },
                  ]}
                  data={historyTed.map((info, index) => {
                    return {
                      data: [
                        {
                          info: info.STATUS_FINAL,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: info.RETORNO,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: info.DATA,
                          style: { textAlign: 'center' },
                        },
                      ],
                    };
                  })}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ModalBody>
        {IDSTATUS === '1' && localStorage.getItem('nivelAcesso') != '6' ? (
          <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button
              color="danger"
              size="sm"
              block
              onClick={() => {
                deny();
                setLoaded(true);
              }}
            >
              Reprovar
            </Button>
            <Button
              color="success"
              size="sm"
              block
              onClick={() => {
                aproved();
                setLoaded(true);
              }}
            >
              Aprovar
            </Button>
          </ModalFooter>
        ) : null}
        {IDSTATUS === '6' && localStorage.getItem('nivelAcesso') != '6' ? (
          <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button
              color="danger"
              size="sm"
              block
              onClick={() => {
                rejected();
                setLoaded(true);
              }}
            >
              Reprovar
            </Button>
          </ModalFooter>
        ) : null}
      </Modal>
      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        tipo={tipo}
        toggle={() => setModalMensagem}
      />
      <AnimatedLoading isActive={loaded} />
    </>
  );
};

export default ModalInfoWithdrawals;
