import CustomContainer from '../../components/Custom/CustomContainer';
import Tabs from '../../components/Tabs/TabsPage';
import React, { useState } from 'react';
import { SendNotification, ViewNotification } from './components/index';

const Notificacoes = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Enviar Notificações', 'Histórico de notificações'];

  return (
    <CustomContainer>
      <Tabs
        selectedTab={tabIndex}
        setSelectedTab={setTabIndex}
        tabs={labels}
        variant="fullWidth"
        account={true}
        color={'#000'}
      />
      {tabIndex === 0 ? (
        <SendNotification value={tabIndex} index={0} />
      ) : tabIndex === 1 ? (
        <ViewNotification value={tabIndex} index={1} />
      ) : null}
    </CustomContainer>
  );
};

export default Notificacoes;
