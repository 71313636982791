import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const CardStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ddeaff;
  margin: 0.8%;
  padding: 0.8% 1%;
  min-width: 98px;
  min-height: 52px;
  max-width: 98px;
  max-height: 52px;
`;
export const CardNãoPossuiStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ddeaff;
  margin: 0.8%;
  padding: 0.8% 1%;
  width: 30%;
  height: 34%;
  min-width: 98px;
  min-height: 52px;
  max-width: 98px;
  max-height: 52px;
`;
export const ButtonMoreCards = styled.button`
  border-radius: 18px;
  border: none;
  background-color: #ddeaff;
  margin: 0.8%;
  padding: 0.8% 1%;
  width: 6%;
  height: 4%;
  cursor: pointer;
  :hover {
    color: #0092d4;
  }
`;
