import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

/* eslint-disable */
const ButtonGroupCartao = (props) => {
  const [hoverButton, setHoverButton] = useState({});

  function handleHoverButton(id, bool) {
    setHoverButton({ ...hoverButton, [id]: bool });
  }

  function handleClick(operation) {
    props.setModalSenhaUnica(true);
    props.setOperation(operation);
  }

  return (
    <ButtonGroup size="sm" style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginLeft: '-1rem' }}>
      <Button
        color="primary"
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        style={{ borderRadius: '5px', transform: hoverButton[10] ? 'translateY(-.1rem)' : '' }}
        id="10"
        onClick={() => handleClick('solicitarCartao')}
      >
        Solicitar Cartão
      </Button>

      {localStorage.getItem('nivelAcesso') == 1 && props.user.STATUS == 7 && (
        <>
          <Button
            onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
            onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
            id="11"
            color="primary"
            style={{ borderRadius: '5px', transform: hoverButton[11] ? 'translateY(-.1rem)' : '' }}
            onClick={() => handleClick('cartaoNaoNominal')}
          >
            Cadastrar Cartão Não Nominal
          </Button>
          <Button
            color="primary"
            onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
            onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
            style={{ borderRadius: '5px', transform: hoverButton[12] ? 'translateY(-.1rem)' : '' }}
            id="12"
            onClick={() => handleClick('atualizarSolicitacaoCartao')}
          >
            Atualizar Solicitação Cartão
          </Button>
        </>
      )}
    </ButtonGroup>
  );
};

export default ButtonGroupCartao;
