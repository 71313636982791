import * as dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'reactstrap';
import GenericButton from '../../../components/Buttons/Button';
import InputDate from '../../../components/Filter/components/InputDate';
import InputSearch from '../../../components/Filter/components/InputSearch';
import InputSelectAmountResults from '../../../components/Filter/components/InputSelectAmountResults.js';
import Filter from '../../../components/Filter/Filter';
import { InputsContainer } from '../../../components/Filter/styles/Filter.style';
import Icon from '../../../components/Icons/IconsButton';
import Loading from '../../../components/Loader/Loading';
import Table from '../../../components/Table/Table';
import { listMensagens } from '../../../store/notifications/actions';
import Modal from './modal/DetailMensagem';
import * as Styled from './ViewNotification.styles.jsx';

const ViewNotification = () => {
  const dispatch = useDispatch();
  const [idConta, setIdConta] = useState('');
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: moment().format('YYYY-MM-DD'),
    LIMIT: 10,
    PAGE: 1,
  });

  const header = [
    {
      info: 'Data de Registro',
      style: { textAlign: 'left' },
    },
    {
      info: 'Título',
      style: { textAlign: 'left' },
    },
  ];

  function inspectUser(id) {
    setIsModalInfoOpen(true);
    setIdConta(id);
  }

  useEffect(() => {
    dispatch(listMensagens(filter));
  }, [filter.PAGE]);

  const { listMensagem, paginationListMensagem, loadingListMensagem } = useSelector((state) => state.notifications);

  const paginationPage = async (type = 'atual', pageClick) => {
    var { PAGE } = filter;

    switch (type) {
      case 'prev':
        PAGE = paginationListMensagem.prevPage;
        break;
      case 'next':
        PAGE = paginationListMensagem.nextPage;
        break;
      default:
        if (pageClick) PAGE = pageClick;
        break;
    }

    await setFilter({ ...filter, PAGE });
  };

  return (
    <div>
      <Filter>
        <Form className="form" onSubmit={(e) => e.preventDefault()} style={{ marginTop: '1.5rem' }}>
          <InputsContainer>
            <InputSearch
              width="20vw"
              placeholder="Título"
              value={filter.SEARCH}
              setValue={(value) => setFilter({ ...filter, SEARCH: value })}
            />
            <InputDate
              label="Data Inicial"
              name="dataInicial"
              value={filter.DATA_INICIAL}
              setValue={(value) => setFilter({ ...filter, DATA_INICIAL: value })}
            />

            <InputDate
              label="Data Final"
              name="dataFinal"
              value={filter.DATA_FINAL}
              setValue={(value) => setFilter({ ...filter, DATA_FINAL: value })}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '0.6rem',
              }}
            >
              <GenericButton
                type="submit"
                onClick={() => {
                  dispatch(listMensagens(filter));
                  filter.PAGE = '1';
                }}
                marginTop={'25px !important'}
                width={'140px'}
                heigth={'35px'}
              >
                <Icon className="fas fa-search"></Icon>Buscar
              </GenericButton>
            </div>
          </InputsContainer>
        </Form>
        <Styled.infoInput value={'Total de Usuários ' + paginationListMensagem.totalResult}></Styled.infoInput>
        <div>
          <InputSelectAmountResults
            options={['10', '25', '50', '100', '1000']}
            value={filter.LIMIT}
            setValue={(value) => setFilter({ ...filter, LIMIT: value })}
          />
        </div>
      </Filter>
      {loadingListMensagem ? (
        <Loading color="#11cdef" />
      ) : listMensagem !== undefined && paginationListMensagem.totalResult > 0 ? (
        <Table
          previousPage={{ onClick: () => paginationPage('prev') }}
          nowPage={{ PAGE: filter.PAGE, onClick: paginationPage }}
          nextPage={{ onClick: () => paginationPage('next') }}
          pagination={paginationListMensagem}
          headers={header}
          data={listMensagem.map((val) => {
            return {
              data: [
                {
                  info: dayjs(val.data).format('DD/MM/YYYY HH:mm:ss') || '-----',
                  align: 'left',
                },
                {
                  info: val.titulo,
                  align: 'left',
                },
              ],
              onClick: () => inspectUser(val),
            };
          })}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <h2>Sem registros</h2>
        </div>
      )}

      {isModalInfoOpen && (
        <Modal openModal={isModalInfoOpen} closeModal={(e) => setIsModalInfoOpen(false)} Data={idConta} />
      )}
    </div>
  );
};

export default ViewNotification;
