export function isCpf(cpf) {
  if (cpf.length === 11) return true;
  return false;
}

export function isCnpj(cnpj) {
  if (cnpj.length === 14) return true;
  return false;
}

export default isCpf;
