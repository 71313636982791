import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import GenericButton from '../../components/Buttons/Button';
import CustomContainer from '../../components/Custom/CustomContainer';
import InputDate from '../../components/Filter/components/InputDate';
import InputSearch from '../../components/Filter/components/InputSearch';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults.js';
import InputStatus from '../../components/Filter/components/InputStatus';
import Filter from '../../components/Filter/Filter';
import { InputsContainer } from '../../components/Filter/styles/Filter.style';
import Icon from '../../components/Icons/IconsButton';
import Loading from '../../components/Loader/Loading';
import ModalBloquear from '../../components/Modais/Bloquear/ModalBloquear';
import ModalCliente from '../../components/Modais/Cliente/ModalCliente';
import ModalSenha from '../../components/Modais/Confirmar/ModalSenha';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import Table from '../../components/Table/Table';
import { listarCoban } from '../../store/coban/actions';
import { getAllUsers } from '../../store/dashboard/actions';
import { getTransactionsType } from '../../store/transactions/actions';
import {
  criarContaDigital,
  detalhesUsuarioBloqueado,
  detalhesUsuarioNegado,
  getUsersExcel,
  listaUser,
  pegarRiscoUsuario,
  revertUser,
  setStatusContaDigital,
} from '../../store/user/actions';
import formatCNPJ from '../../utils/formatCNPJ';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL';
import { removeSpecialCharacters } from '../../utils/functions';
import ModalMensagem from '../Transaction/ModalMensagem';
import * as Styled from './GerenciarClientes.styles';

/* eslint-disable */
function GerenciarClientes(props) {
  const dispatch = useDispatch();
  const [modalDenyUser, setModalDenyUser] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalPendingUser, setModalPendingUser] = useState(false);
  const [modalBlockedUser, setModalBlockedUser] = useState(false);
  const [modalFinishUser, setModalFinishUser] = useState(false);
  const [modalUnblockUser, setModalUnblockUser] = useState(false);
  const [modalPasswordDenyUser, setModalPasswordDenyUser] = useState(false);
  const [modalPasswordContaDigital, setModalPasswordContaDigital] = useState(false);
  const [modalContaDigital, setModalContaDigital] = useState(false);
  const [message, setMessage] = useState('');
  const [modalMessage, setModalMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState('');
  const [parceiros, setParceiros] = useState('');
  const [idParceiros, setIdParceiros] = useState('');
  const [filter, setFilter] = useState({
    limit: 10,
    search: '',
    page: 1,
    perfil: '',
    status: '',
    tipo_usuario: '',
    dataInicial: '',
    dataFinal: moment().format('YYYY-MM-DD'),
  });

  let { page } = filter;

  useEffect(() => {
    dispatch(getAllUsers(filter));
    dispatch(listarCoban());
  }, [filter.page]);

  const { userPagination, listUsers, loading_dashboard, pendingUsers } = useSelector((state) => state.dashboard);
  const { parceiro } = useSelector((state) => state.transactions);
  const selectedPendingUsers = [!pendingUsers, filter.status == '1', !pendingUsers];

  //use effect feito para não ter os dois modais abertos do usuário pendente ao mesmo tempo
  useEffect(() => {
    if (modalContaDigital) {
      closeModalPendingUser();
    }
  }, [modalContaDigital]);

  useEffect(() => {
    if (pendingUsers) {
      setFilter((prev) => ({ ...prev, status: '1' }));
    }
  }, [pendingUsers]);

  useEffect(() => {
    dispatch(pegarRiscoUsuario());
    dispatch(getTransactionsType());
  }, []);

  useEffect(() => {
    if (parceiro) {
      setParceiros(parceiro.map((parceiro) => parceiro.DESCRICAO));
      setIdParceiros(parceiro.map((parceiro) => parceiro.ID));
    }
  }, [parceiro]);

  const { list } = useSelector((state) => state.coban);

  const { detalheUserBloqueado, detalheUserNegado, solicitacaoContaDigital } = useSelector((state) => state.user);

  const arrayCobanNames = list.map(function (coban) {
    return coban.NOME;
  });
  arrayCobanNames.unshift('Todos');

  const arrayCobanNamesCode = list.map(function (coban) {
    return coban.NOME;
  });
  arrayCobanNamesCode.unshift('');

  const toggleModalDenyUser = () => setModalDenyUser(!modalDenyUser);
  const toggleModalLoading = () => setModalLoading(!modalLoading);
  const toggleModalContaDigital = () => {
    setModalContaDigital(!modalContaDigital);
  };
  const toggleModalUnblockUser = () => setModalUnblockUser(!modalUnblockUser);
  const toggleModalPendingUser = () => setModalPendingUser(!modalPendingUser);
  const toggleModalBlockedUser = () => setModalBlockedUser(!modalBlockedUser);
  const toggleModalFinishUser = () => setModalFinishUser(!modalFinishUser);

  const closeModalDenyUser = () => setModalDenyUser(false);
  const closeModalLoading = () => setModalLoading(false);
  const openModalDenyUser = () => setModalDenyUser(true);

  const closeModalBlockedUser = () => setModalBlockedUser(false);
  const openModalBlockedUser = () => setModalBlockedUser(true);
  const closeModalFinishUser = () => setModalFinishUser(false);
  const openModalFinishUser = () => setModalFinishUser(true);
  const closeModalPendingUser = () => setModalPendingUser(false);
  const closeModalContaDigital = () => setModalContaDigital(false);

  const openModalPendingUser = () => setModalPendingUser(true);
  const openModalLoading = () => setModalLoading(true);
  const openModalUnblockUser = () => setModalUnblockUser(true);

  const openModalPasswordDenyUser = () => setModalPasswordDenyUser(true);
  const openModalPasswordContaDigital = () => setModalPasswordContaDigital(true);

  function openModalMessage(res) {
    const { mensagem, erro, retorno } = res;
    setMessage(mensagem);
    setModalMessage(true);
    closeModalLoading();
    if (retorno && retorno == 'sucesso') setSuccess(true);
    if (!erro) setSuccess(true);
  }

  const revertUserToPendingStatus = () => {
    openModalLoading();
    dispatch(
      revertUser({ CPFUSUARIO: userData.CPFCNPJ }, (res) => {
        toggleModalDenyUser();
        openModalMessage(res);
      }),
    );
  };

  const aprovarContaDigital = () => {
    openModalLoading();
    dispatch(
      criarContaDigital({ CPFCNPJ_USUARIO: userData.CPFCNPJ }, (res) => {
        openModalMessage(res);
      }),
    );
  };

  const nextModal = (user) => {
    setUserData(user);
    if (user.CODSTATUS === '1' || user.CODSTATUS === '6') {
      dispatch(listaUser(removeSpecialCharacters(user.CPFCNPJ)));
      openModalPendingUser();
    } else if (user.CODSTATUS === '3') {
      dispatch(detalhesUsuarioNegado(removeSpecialCharacters(user.CPFCNPJ)));
      openModalDenyUser();
    } else if (user.CODSTATUS === '4') {
      dispatch(detalhesUsuarioBloqueado(removeSpecialCharacters(user.CPFCNPJ)));
      openModalBlockedUser();
    } else if (user.CODSTATUS === '8') {
      dispatch(listaUser(removeSpecialCharacters(user.CPFCNPJ)));
      openModalFinishUser();
    }
  };

  const { loading_user, user, loading_user_list } = useSelector((state) => state.user);

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = userPagination.prevPage;
        break;
      case 'next':
        page = userPagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }

    await setFilter({ ...filter, page });
  };

  return (
    <div>
      <CustomContainer>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <InputsContainer>
              <InputSearch
                width="20vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
              />

              <InputStatus
                pendingUsers={selectedPendingUsers}
                inputName={'Perfil'}
                setValue={(value) => setFilter({ ...filter, perfil: value })}
                options={arrayCobanNames}
                codeValue={arrayCobanNamesCode}
              />
              <InputStatus
                pendingUsers={selectedPendingUsers}
                setValue={(value) => setFilter({ ...filter, status: value })}
                options={[
                  'Todos',
                  'Aguardando',
                  'Aprovado',
                  'Negado',
                  'Bloqueado',
                  'Pré Aprovado',
                  'Aguardando Conta Digital',
                  'Aprovado Conta Digital',
                  'Encerrado Conta Digital',
                ]}
                codeValue={['', 1, 2, 3, 4, 5, 6, 7, 8]}
              />
              <InputStatus
                pendingUsers={selectedPendingUsers}
                inputName={'Parceiro'}
                setValue={(value) => setFilter({ ...filter, tipo_usuario: value })}
                options={['Todos', ...parceiros]}
                codeValue={['', ...idParceiros]}
              />

              <InputDate
                label="Data Inicial"
                name="dataInicial"
                value={filter.dataInicial}
                setValue={(value) => setFilter({ ...filter, dataInicial: value })}
              />

              <InputDate
                label="Data Final"
                name="dataFinal"
                value={filter.dataFinal}
                setValue={(value) => setFilter({ ...filter, dataFinal: value })}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '0.6rem',
                }}
              >
                <GenericButton
                  type="submit"
                  onClick={() => {
                    dispatch(getAllUsers(filter));
                    filter.page = '1';
                  }}
                  marginTop={'25px !important'}
                  width={'140px'}
                  heigth={'35px'}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
                <GenericButton
                  onClick={() => {
                    dispatch(getUsersExcel(filter));
                  }}
                  marginTop={'25px !important'}
                  backgroundColor={'#ffffff'}
                  borderColor={'#2405F2'}
                  width={'140px'}
                  heigth={'35px'}
                  marginLeft={'10px'}
                  color={'#2405F2'}
                >
                  <Icon className="fas fa-file-export"></Icon>Exportar
                </GenericButton>
              </div>
            </InputsContainer>
          </Form>
          <Styled.infoInput value={'Total de Usuários ' + userPagination.totalResult}></Styled.infoInput>
          <div>
            <InputSelectAmountResults
              options={['10', '25', '50', '100', '1000']}
              value={filter.limit}
              setValue={(value) => setFilter({ ...filter, limit: value })}
            />
          </div>
        </Filter>
        {!userPagination ? (
          <h4 style={{ textAlign: 'center' }}>Carregando Transações</h4>
        ) : (
          <div>
            {loading_dashboard ? (
              <Loading color="#11cdef" />
            ) : (
              <Table
                headers={[
                  {
                    info: 'Data de Registro',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'CPF/CNPJ',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Nome',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Conta',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Perfil',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Parceiro',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Status',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Saldo',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'Ações',
                    style: { textAlign: 'center' },
                  },
                ]}
                data={listUsers.map((user) => {
                  return {
                    data: [
                      {
                        info: user.DATA,
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.CPFCNPJ
                          ? user.CPFCNPJ.length == 11
                            ? formatCPF(user.CPFCNPJ)
                            : formatCNPJ(user.CPFCNPJ)
                          : null,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: user.NOME,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: user.CONTA ? user.CONTA : '-----',
                        style: { textAlign: 'left' },
                      },

                      {
                        info: user.CODSTATUS != '1' ? user.NOMECOBAN : '-----',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: user.STATUS_CONTA_DIGITAL ? (
                          <StatusContainer
                            code={user.TIPO_USUARIO.toUpperCase()}
                            description={user.TIPO_USUARIO}
                          ></StatusContainer>
                        ) : (
                          '-----'
                        ),
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.STATUS ? (
                          <StatusContainer code={user.CODSTATUS} description={user.STATUS}></StatusContainer>
                        ) : (
                          '-----'
                        ),
                        style: { textAlign: 'center' },
                      },
                      {
                        info: user.CODSTATUS === '7' ? `R$ ${formatMoney(user.SALDO)}` : '-----',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: (
                          <Styled.IconContainer>
                            <div
                              onClick={() => {
                                props.history.push(`${props.match.path}/view?user=${user.CPFCNPJ}`, [
                                  user.STATUS_CONTA_DIGITAL,
                                ]);

                                dispatch(setStatusContaDigital(user.ID_CONTA_DIGITAL));
                              }}
                            >
                              {user.CODSTATUS === '2' ||
                              user.CODSTATUS === '5' ||
                              user.CODSTATUS === '6' ||
                              user.CODSTATUS === '7' ? (
                                <Tooltip title="Ver Perfil" arrow>
                                  <div>{<Styled.UserEditIcon />}</div>
                                </Tooltip>
                              ) : null}
                            </div>

                            {/* <div
                              onClick={() => {
                                nextModal(user);
                              }}
                            >
                              {' '}
                              {user.CODSTATUS === '5' ? (
                                <Tooltip title="Aprovar Conta Digital" arrow>
                                  <div>
                                    <Styled.DigitalAccountIcon />
                                  </div>
                                </Tooltip>
                              ) : null}{' '}
                            </div> */}
                          </Styled.IconContainer>
                        ),
                        style: { textAlign: 'center' },
                      },
                    ],
                    onClick: () => nextModal(user),
                  };
                })}
                pagination={userPagination}
                previousPage={{ onClick: () => tablePagination('prev') }}
                nowPage={{ page, onClick: userPagination }}
                nextPage={{ onClick: () => tablePagination('next') }}
              />
            )}
          </div>
        )}
      </CustomContainer>
      <Modal isOpen={modalDenyUser} toggle={toggleModalDenyUser}>
        <ModalHeader>Detalhes da Negação</ModalHeader>
        <ModalBody>
          <div>
            <Styled.ModalSubtitle>Observação</Styled.ModalSubtitle>
            <p>{detalheUserNegado.ERRO_STATUS}</p>
          </div>
          <div>
            <Styled.ModalSubtitle>Data de Reprovação</Styled.ModalSubtitle>
            <p>{moment(detalheUserNegado.DATA_UPDATE).format('DD/MM/YYYY')}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <>
            <Button size="sm" block onClick={() => closeModalDenyUser()}>
              Cancelar
            </Button>
            <Styled.RevertButton size="sm" block onClick={() => openModalPasswordDenyUser()}>
              Reverter usuário
            </Styled.RevertButton>
          </>{' '}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalLoading} toggle={toggleModalLoading}>
        <ModalBody>
          <Styled.LoadingModalContainer>
            <Styled.Spinner size="100px" />
            <Styled.LoadingModalTitle>Carregando</Styled.LoadingModalTitle>
          </Styled.LoadingModalContainer>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalBlockedUser} toggle={toggleModalBlockedUser}>
        <ModalHeader>Detalhes do Bloqueio</ModalHeader>
        <ModalBody>
          <div>
            <Styled.ModalSubtitle>Observação</Styled.ModalSubtitle>
            <p>{detalheUserBloqueado.ERRO_STATUS}</p>
          </div>
          <div>
            <Styled.ModalSubtitle>Data de Bloqueio</Styled.ModalSubtitle>
            <p>{moment(detalheUserBloqueado.DATA_UPDATE).format('DD/MM/YYYY')}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <>
            <Button size="sm" block onClick={() => closeModalBlockedUser()}>
              Cancelar
            </Button>
            <Styled.RevertButton size="sm" onClick={() => openModalUnblockUser()} block>
              Reverter usuário
            </Styled.RevertButton>
          </>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalFinishUser} toggle={toggleModalFinishUser}>
        <ModalHeader>Detalhes do Encerramento</ModalHeader>
        <ModalBody>
          {user != null && !loading_user_list && user.CONTA_BACEN.length > 0 ? (
            <div>
              <div>
                <Styled.ModalSubtitle>Data de Encerramento</Styled.ModalSubtitle>
                <p>{moment(user.CONTA_BACEN[0].DT_ENCERRAMENTO).format('DD/MM/YYYY')}</p>
              </div>
              <div>
                <Styled.ModalSubtitle>Justificativa</Styled.ModalSubtitle>
                <p>{user.CONTA_BACEN[0].MOTIVO_ENCERRAMENTO}</p>
              </div>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <>
            <Button size="sm" block onClick={() => closeModalFinishUser()}>
              Voltar
            </Button>
            {/* <Styled.RevertButton
              size="sm"
              onClick={() => openModalUnblockUser()}
              block
            >
              Reverter usuário
            </Styled.RevertButton> */}
          </>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalContaDigital} toggle={toggleModalContaDigital}>
        <ModalHeader>Solicitação de conta digital</ModalHeader>
        <ModalBody>
          <Styled.ModalSubtitle>Deseja solicitar conta digital?</Styled.ModalSubtitle>
        </ModalBody>
        <ModalFooter>
          <>
            <Button size="sm" block onClick={() => toggleModalContaDigital()}>
              Cancelar
            </Button>
            <Styled.RevertButton
              size="sm"
              block
              onClick={() => {
                openModalPasswordContaDigital();
                closeModalContaDigital();
              }}
            >
              Sim
            </Styled.RevertButton>
          </>
        </ModalFooter>
      </Modal>

      <ModalSenha
        modal={modalPasswordDenyUser}
        close={() => setModalPasswordDenyUser(false)}
        dados={userData.CPFCNPJ}
        nextFunction={revertUserToPendingStatus}
      />

      <ModalSenha
        modal={modalPasswordContaDigital}
        close={() => setModalPasswordContaDigital(false)}
        dados={userData.CPFCNPJ}
        nextFunction={aprovarContaDigital}
      />

      <ModalCliente modal={modalPendingUser} toggle={toggleModalPendingUser} type={'1'}></ModalCliente>

      <ModalBloquear
        isOpen={modalUnblockUser}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={toggleModalUnblockUser}
        userData={userData}
      ></ModalBloquear>

      <ModalMensagem
        modal={modalMessage}
        toggle={() => {
          setModalMessage(false);
        }}
        mensagem={message}
        sucesso={success}
      />
    </div>
  );
}

export default GerenciarClientes;
