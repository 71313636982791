import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnimatedLoading from '../../../components/Loader/AnimatedLoading';
import { updateCoban, updatePerfil, validationPassword } from '../../../store/coban/actions';
import {
  alteraRepresentante,
  alteraUsuario,
  atualizaSolicitacaoCartao,
  deleteAccounting,
  deleteIdDispositivo,
  liberarAcessoDispositivo,
  recuperaSenhaUsuario,
  revertUser,
  solicitarCartaoUsuario,
  solicitarContaDigital,
  taxaAtualizar,
} from '../../../store/user/actions';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';

/* eslint-disable */
const ModalSenha = (props) => {
  const { close, modal, nextFunction, operation, dados } = props;
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [modalMessage, setModalMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loaded, setLoaded] = useState(false);

  function openModalMessage(res) {
    const { mensagem, erro, retorno } = res;
    setMessage(mensagem);
    setModalMessage(true);
    if ((retorno && retorno == 'sucesso') || !erro) setSuccess(true);
    if ((retorno && retorno == 'erro') || erro) setSuccess(false);
    /* if (erro) setSuccess(false); */
    /*   if (!erro) setSuccess(true); */
  }

  async function clickButton() {
    await props.validationPassword({ SENHA: password }, (res) => {
      const { erro } = res;
      if (erro == true) {
        openModalMessage(res);
      } else if (operation == 'alterarTaxa') {
        const cpfcnpj = props.user.user.CPFCNPJ;
        const valor = dados;

        props.taxaAtualizar(cpfcnpj, valor, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'alterarCadastro') {
        const fields = props.user.allFields;

        props.alteraUsuario(fields, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'removerPerfil') {
        props.updatePerfil(dados, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'removerCoban') {
        props.updateCoban(dados, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'alterarRepresentante') {
        const fields = props.user.user.REPRESENTANTES;

        props.alteraRepresentante({ REPRESENTANTES: fields }, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'criarContaDigital') {
        const cpf_cnpj = props.user.user.CPFCNPJ;
        const id_coban = props.user.user.ID_COBAN;

        props.solicitarContaDigital({ CPFUSUARIO: cpf_cnpj, ID_COBAN: id_coban }, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'recuperarSenha') {
        const cpf_cnpj = props.user.user.CPFCNPJ;

        props.recuperaSenhaUsuario({ CPFCNPJ: cpf_cnpj }, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'liberarAcessoDispositivo') {
        const cpf_cnpj = props.user.user.CPFCNPJ;

        props.liberarAcessoDispositivo({ CPFCNPJ_CLIENTE: cpf_cnpj }, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'atualizarSolicitacaoCartao') {
        const cpf_cnpj = props.user.user.CPFCNPJ;

        props.atualizaSolicitacaoCartao({ CPFCNPJ_USUARIO: cpf_cnpj }, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'excluirConta') {
        const { CPFCNPJ, ID } = dados;
        props.deleteAccounting(CPFCNPJ, ID, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'removeDispositivo') {
        const { CPFCNPJ } = props.user.user;

        props.deleteIdDispositivo(CPFCNPJ, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'reverterUsuario') {
        const CPFCNPJ = dados;

        props.revertUser({ CPFUSUARIO: CPFCNPJ }, (res) => {
          openModalMessage(res);
        });
      } else if (operation == 'sendDocument') {
        close();
        props.setModalDoc(true);
      } else if (operation == 'changeProfile') {
        close();
        props.setModalAlterarCoban({
          open: true,
          title: 'Alterar Perfil',
        });
      } else if (operation == 'cartaoNaoNominal') {
        close();
        props.setModalQrCode(true);
      } else if (operation == 'trocaSenha') {
        props.modalTrocaSenha();
      } else if (operation == 'block') {
        close();
        props.setModalBlock(true);
      } else if (operation == 'solicitarCartao') {
        const cpf_cnpj = props.user.user.CPFCNPJ;
        setLoaded(true);

        props.solicitarCartaoUsuario({ CPFCNPJ_USUARIO: cpf_cnpj }, (res) => {
          openModalMessage(res);
          setLoaded(false);
        });
      } else if (operation == 'maisContaBancaria') {
        props.setModalAlteraConta(true);
      } else if (operation == 'operarSaldoCliente') {
        props.setModalSaldo(true);
      } else if (operation == 'encerrarConta') {
        props.setModalJustificativa(true);
      } else {
        close();
        nextFunction();
      }
    });
    setPassword('');
  }

  return (
    <Modal isOpen={modal} toggle={close} autoFocus={false}>
      <ModalHeader toggle={close}>Por favor, digite a senha antes de continuar: </ModalHeader>
      <Form onSubmit={(e) => e.preventDefault()}>
        <ModalBody>
          <Input
            autoFocus={true}
            size="md"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></Input>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            disabled={password == '' ? true : false}
            size="sm"
            color="success"
            onClick={() => {
              clickButton();
            }}
            block
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Form>
      <ModalMensagem
        modal={modalMessage}
        toggle={() => {
          setModalMessage(false);
        }}
        mensagem={message}
        sucesso={success}
      />
      <AnimatedLoading isActive={loaded} />
    </Modal>
  );
};

const mapStateToProps = ({ coban, user }) => {
  return { coban, user };
};
export default connect(mapStateToProps, {
  validationPassword,
  deleteIdDispositivo,
  alteraUsuario,
  deleteAccounting,
  recuperaSenhaUsuario,
  alteraRepresentante,
  liberarAcessoDispositivo,
  solicitarContaDigital,
  taxaAtualizar,
  updateCoban,
  updatePerfil,
  revertUser,
  solicitarCartaoUsuario,
  atualizaSolicitacaoCartao,
})(ModalSenha);
