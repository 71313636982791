import { CONTABIL_ALL, CONTABIL_LIST } from './types';

const INITIAL_STATE = {
  loading_contabil: false,
  erro_contabil: false,
  erro_message_contabil: null,
  listAccounting: [],
  pagination: {},
  RECEITA: [],
  RESULTADO: [],
  DESPESA: [],
  taxaFail: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTABIL_ALL:
      return { ...state, ...action.payload };

    case CONTABIL_LIST:
      var listAccounting = state.listAccounting;
      listAccounting = [...listAccounting, ...action.payload.listAccounting];
      return {
        ...state,
        ...action.payload,
        listAccounting,
      };

    default:
      return state;
  }
};
