import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { Button, CardFooter } from 'reactstrap';
import { Document as ModalDocument } from '../modals/index';

/* eslint-disable */

function AccordionDocumentsUser({ setOpenDocument, openDocument, user: { DOCUMENTOS, CPFCNPJ }, accept }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-content">
        <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
          Documentos
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography> {CPFCNPJ.length == 11 ? 'Cliente' : 'Empresa'} </Typography>
        <ModalDocument state={openDocument} setState={(el) => setOpenDocument(el)} accept={accept} />
        <Typography>
          <CardFooter>
            {DOCUMENTOS &&
              DOCUMENTOS.map((docs) => {
                return [
                  <Button
                    style={{ color: '#606060', backgroundColor: '#FFFFFF', border: '0.5px solid LightGray' }}
                    size="sm"
                    onClick={() => {
                      setOpenDocument({
                        openDocument: true,
                        documentLabel: docs.DESCRICAO_CATEGORIA,
                        urlImage: docs.CAMINHO_ARQUIVO,
                      });
                    }}
                  >
                    {docs.DESCRICAO_CATEGORIA}
                  </Button>,
                ];
              })}
          </CardFooter>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionDocumentsUser;
