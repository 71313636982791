import React, { Fragment } from 'react';

// reactstrap components
import { Spring } from 'react-spring/renderprops';
import { CardFooter, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';

/* eslint-disable */
class CustomTable extends React.Component {
  state = { filtro: { limit: 10, search: '' } };
  render() {
    const { headers, data, previousPage, nowPage, nextPage, pagination, style } = this.props;

    return (
      <Spring from={{ opacity: 1 }} to={{ opacity: 1 }}>
        {(props) => (
          <div style={props}>
            <Fragment>
              <Table style={{ ...style }} hover className="align-items-center table-flush" striped responsive>
                <thead>
                  <tr
                    style={{
                      backgroundColor: `${localStorage.getItem('nivelAcesso') != '6' ? '#5B6A82' : '#111111'}`,
                      color: '#FFF',
                    }}
                  >
                    {headers && headers.length
                      ? headers.map((header) => (
                          <th
                            scope="col"
                            style={{
                              ...header.style,
                              fontSize: '12px',
                            }}
                          >
                            {header.info}
                          </th>
                        ))
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {data && data.length
                    ? data.map((row) =>
                        row && row.length ? (
                          <tr className="table-tr">
                            {row && row.length
                              ? row.map((collum) => <td style={{ textAlign: 'right' }}>{collum}</td>)
                              : null}
                          </tr>
                        ) : (
                          <tr
                            style={{
                              tableLayout: 'fixed',
                              cursor: row && row.onClick ? 'pointer' : 'unset',
                            }}
                            className="table-tr"
                            onClick={() => (row && row.onClick ? row.onClick(row.data) : null)}
                          >
                            {row && row.data && row.data.length
                              ? row.data.map((collum) => {
                                  return (
                                    <td
                                      style={{
                                        ...collum.style,
                                        fontSize: '10px',
                                      }}
                                    >
                                      {collum.info}
                                    </td>
                                  );
                                })
                              : null}
                          </tr>
                        ),
                      )
                    : null}
                </tbody>
              </Table>
              {(previousPage || nextPage) && pagination ? (
                <CardFooter>
                  <nav className="clearfix" aria-label="...">
                    <div>
                      {pagination.totalResult > 0 ? (
                        <p className="float-left">Total de páginas: {pagination.totalPages}</p>
                      ) : (
                        <h3
                          className="ml-2"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Nenhum registro encontrado
                        </h3>
                      )}
                    </div>
                    <div className="float-right">
                      <Pagination
                        className="pagination  mb-0"
                        listClassName="float-right mb-0"
                        style={{ flexWrap: 'wrap' }}
                      >
                        {(nowPage.page || nowPage.PAGE) !== 1 ? (
                          <PaginationItem className="float-right">
                            <PaginationLink onClick={(e) => previousPage.onClick()} tabIndex="-1">
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Anterior</span>
                            </PaginationLink>
                          </PaginationItem>
                        ) : null}

                        {nowPage ? (
                          <PaginationItem>
                            <PaginationLink>{nowPage.page || nowPage.PAGE}</PaginationLink>
                          </PaginationItem>
                        ) : null}
                        {(nowPage.page || nowPage.PAGE) !== pagination.totalPages && pagination.totalPages ? (
                          <PaginationItem>
                            <PaginationLink onClick={(e) => nextPage.onClick()}>
                              <i className="fas fa-angle-right" />
                              <span className="sr-only">Próximo</span>
                            </PaginationLink>
                          </PaginationItem>
                        ) : null}
                      </Pagination>
                    </div>
                  </nav>
                </CardFooter>
              ) : null}
            </Fragment>
          </div>
        )}
      </Spring>
    );
  }
}

const styles = {
  form: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
  },
};
export default CustomTable;
