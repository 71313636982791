import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Col } from 'reactstrap';
import '../../assets/vendor/nucleo/css/nucleo.css';
import AlertaErro from '../../components/Alertas/AlertaSolicitacao';
import AnimatedLoading from '../../components/Loader/AnimatedLoading';
import ModalBloquear from '../../components/Modais/Bloquear/ModalBloquear';
import ModalSaldo from '../../components/Modais/Cliente/ModalSaldo';
import ModalSenha from '../../components/Modais/Confirmar/ModalSenha';
import ModalAlteraConta from '../../components/Modais/Contas/ModalAlteraConta';
import ModalJustificativa from '../../components/Modais/Justificativa/Justificativa';
import ModalQRCODE from '../../components/Modais/ModalQRCODE/ModalQRCODE';
import { renderTaxasUsuario } from '../../components/Taxes/renderTaxas';
import { listarCartao, listarCoban, listarSaldoGeral } from '../../store/coban/actions';
import { addSTATE } from '../../store/globals.js';
import { buscaCep, pegarRiscoUsuario } from '../../store/user/actions';
import debounce from '../../utils/debounce';
import ShippingFormModal from '../Shipping/ShippingModalUser';
import ModalMensagem from '../Transaction/ModalMensagem';
import AccordionAppliedFees from './components/AccordionAppliedFees/AccordionAppliedFees';
import AccordionCardsUser from './components/AccordionCardsUser/AccordionCardsUser';
import AccordionDocumentsUser from './components/AccordionDocumentsUser/AccordionDocumentsUser';
import AccordionInfoUser from './components/AccordionInfoUser/AccordionInfoUser';
import AccordionVerifications from './components/AccordionVerifications/AccordionVerifications';
import ButtonGroupCartao from './components/ButtonGroupCartao';
import ButtonGroupCliente from './components/ButtonGroupCliente';
import ButtonGroupConta from './components/ButtonGroupConta';
import ModalCardUser from './components/modals/components/ModalCardUser/ModalCardUser';
import ModalVincularProduto from './components/modals/components/ModalVincularProduto/ModalVincularProduto';
import {
  Confirmacao as ModalConfirmacao,
  ModalTrocaSenha,
  VincularCartaoSwap as ModalVincularCartaoSwap,
} from './components/modals/index';
import { default as ModalAlteraCoban, default as ModalAprovaUser } from './ModalAlteraCoban';
import './style.css';

/* eslint-disable */
export default function FormUserNew(props) {
  const { user, cartaoContaDigital, risco } = useSelector((state) => state.user);
  const { CARTOES, SALDO } = useSelector((state) => state.coban);
  const allCards = { ...CARTOES };
  const saldoGeral = { ...SALDO };
  const dispatch = useDispatch();
  const [infoPessoaisRedux] = useState({
    nome: user.NOME,
    email: user.EMAIL,
    cpf_cnpj: user.CPFCNPJ,
    nasc_fund: user.NASCIMENTO,
    telefone: user.TELEFONE,
    endereco: user.ENDERECO,
    bairro: user.BAIRRO,
    cidade: user.CIDADE,
    numero: user.NUMERO,
    uf: user.UF,
    cep: user.CEP,
    complemento: user.COMPLEMENTO,
    digits_variable: user.CNPJMATRIZ && user.CNPJMATRIZ.length == 14 ? user.CNPJMATRIZ.substr(11, 14) : '',
    matricula: user.MATRICULA,
    empresa: user.EMPRESATRABALHA,
    nome_coban: user.NOMECOBAN,
    conta: user.CONTA,
    agencia: user.AGENCIA,
    nome_mae: user.NOME_MAE,
    StatusSolicitacaoCartao: user.STATUS_CONTA_PARCEIRO,
    data_encerramento: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].DT_ENCERRAMENTO : null,
    data_abertura: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].DT_INSERT : null,
    cartaoContaDigital: [],
    riscoPLD: user.RISCO_PLD ? user.RISCO_PLD : null,
    conveniada: user.CONVENIADA,
  });
  const [informacoesPessoais, setInformacoesPessoais] = useState({
    nome: user.NOME,
    email: user.EMAIL,
    cpf_cnpj: user.CPFCNPJ,
    nasc_fund: user.NASCIMENTO,
    telefone: user.TELEFONE,
    endereco: user.ENDERECO,
    bairro: user.BAIRRO,
    cidade: user.CIDADE,
    numero: user.NUMERO,
    uf: user.UF,
    cep: user.CEP,
    complemento: user.COMPLEMENTO,
    digits_variable: user.CNPJMATRIZ && user.CNPJMATRIZ.length == 14 ? user.CNPJMATRIZ.substr(11, 14) : '',
    matricula: user.MATRICULA,
    empresa: user.EMPRESATRABALHA,
    nome_coban: user.NOMECOBAN,
    conta: user.CONTA,
    agencia: user.AGENCIA,
    nome_mae: user.NOME_MAE,
    StatusSolicitacaoCartao: user.STATUS_CONTA_PARCEIRO,
    status_cartao_cc: 'Cartão Não Solicitado',
    data_encerramento: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].DT_ENCERRAMENTO : null,
    data_abertura: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].DT_INSERT : null,
    cartaoContaDigital: [],
    alteraCliente: true,
    modalJustificativa: false,
    alert: false,
    riscoPLD: user.RISCO_PLD ? user.RISCO_PLD : null,
    conveniada: user.CONVENIADA,
  });
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalSenhaUnica, setModalSenhaUnica] = useState(false);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [modalSaldo, setModalSaldo] = useState(false);
  const [sucesso, setSucesso] = useState('');
  const [modalAlterarCoban, setModalAlterarCoban] = useState({ open: false, title: '' });
  const [infoCard, setInfoCard] = useState({
    open: false,
    cartao: {},
  });
  const [openAccordionTaxes, setOpenAccordionTaxes] = useState(false);
  const [cartao, setCartao] = useState({});
  const [tipo, setTipo] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [nextFunction, setNextFunction] = useState('');
  const [abreAlerta, setAbreAlerta] = useState(false);
  const [operation, setOperation] = useState('');
  const [aprovaUser, setAprovaUser] = useState(false);
  const [openDocument, setOpenDocument] = useState({});
  const [openAccordionInfo, setOpenAccordionInfo] = useState(true);
  const [modalVincularCartaoSwap, setModalVincularCartaoSwap] = useState(false);
  const [idSwap, setIdSwap] = useState('');
  const [modalSenha, setModalSenha] = useState(false);
  const [trocaSenha, setTrocaSenha] = useState(false);
  const [modalVincularProduto, setModalVincularProduto] = useState({ open: false, PRODUTOS: '' });
  const [idCartao, setIdCartao] = useState('');
  const [modalDoc, setModalDoc] = useState(false);
  const [modalQRCODE, setModalQrCode] = useState(false);
  const [modalAlteraConta, setModalAlteraConta] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);
  const [modalJustificativa, setModalJustificativa] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [reloadProdutos, setReloadProdutos] = useState(false);
  const [card, setCard] = useState('');
  const [filter, setFilter] = useState({
    limit: 10,
    search: '',
    page: 1,
    Tipo: '',
  });

  let { page } = filter;

  function alterValue(e) {
    const { name } = e.target;
    const valor = e.target.value;
    const value = valor.toUpperCase();

    switch (name) {
      case 'Nome/Razão Social':
        setInformacoesPessoais((prev) => ({ ...prev, nome: value }));
        break;

      case 'CPF/CNPJ':
        setInformacoesPessoais((prev) => ({ ...prev, cpf_cnpj: value }));
        break;

      case 'Nascimento/Fundação':
        setInformacoesPessoais((prev) => ({ ...prev, nasc_fund: value }));
        break;

      case 'EMAIL':
        setInformacoesPessoais((prev) => ({ ...prev, email: value }));
        break;

      case 'TELEFONE':
        setInformacoesPessoais((prev) => ({ ...prev, telefone: value }));
        break;

      case 'ENDERECO':
        setInformacoesPessoais((prev) => ({ ...prev, endereco: value }));
        break;

      case 'BAIRRO':
        setInformacoesPessoais((prev) => ({ ...prev, bairro: value }));
        break;

      case 'CIDADE':
        setInformacoesPessoais((prev) => ({ ...prev, cidade: value }));
        break;

      case 'NUMERO':
        setInformacoesPessoais((prev) => ({ ...prev, numero: value }));
        break;

      case 'UF':
        setInformacoesPessoais((prev) => ({ ...prev, uf: value }));
        break;

      case 'CEP':
        setInformacoesPessoais((prev) => ({ ...prev, cep: value }));

      case 'Conveniada':
        setInformacoesPessoais((prev) => ({ ...prev, conveniada: value }));

        if (valor.length >= 8) {
          debounce(() => {
            dispatch(
              buscaCep(informacoesPessoais.cep, (res) => {
                if (res == true) {
                  const { cep } = user;
                  setInformacoesPessoais((prev) => ({
                    ...prev,
                    endereco: cep.rua != '' ? cep.rua : this.state.endereco,
                    bairro: cep.bairro != '' ? cep.bairro : this.state.bairro,
                    cidade: cep.cidade != '' ? cep.cidade : this.state.cidade,
                    uf: cep.uf != '' ? cep.uf : this.state.uf,
                  }));
                }
              }),
            );
          }, 2000);
        }
        break;

      case 'COMPLEMENTO':
        setInformacoesPessoais((prev) => ({ ...prev, complemento: value }));
        break;

      case 'CPF_MATRIZ':
        setInformacoesPessoais((prev) => ({ ...prev, cpf_cnpj_matriz: value }));
        break;

      case 'CNPJ_MATRIZ':
        setInformacoesPessoais((prev) => ({ ...prev, digits_variable: value }));
        break;
      case 'MATRICULA':
        setInformacoesPessoais((prev) => ({ ...prev, matricula: value }));
        break;

      case 'EMPRESA':
        setInformacoesPessoais((prev) => ({ ...prev, empresa: value }));
        break;
      case 'NOME_COBAN':
        setInformacoesPessoais((prev) => ({ ...prev, nome_coban: value }));
        break;
      case 'CONTA':
        setInformacoesPessoais((prev) => ({ ...prev, conta: value }));
        break;
      case 'AGENCIA':
        setInformacoesPessoais((prev) => ({ ...prev, agencia: value }));
        break;
      case 'RiscoPLD':
        setInformacoesPessoais((prev) => ({ ...prev, riscoPLD: value.includes('-') ? null : value }));
        break;
      case 'Conveniada':
        setInformacoesPessoais((prev) => ({ ...prev, conveniada: value }));
        break;
      case 'DATA_ABERTURA':
        setInformacoesPessoais((prev) => ({ ...prev, data_abertura: value }));
        break;
      case 'DATA_ENCERRAMENTO':
        setInformacoesPessoais((prev) => ({ ...prev, data_encerramento: value }));
        break;
      case 'NOME_MAE':
        setInformacoesPessoais((prev) => ({ ...prev, nome_mae: value }));

      default:
        break;
    }
  }

  async function validate() {
    const campos = [
      'nome',
      'nome_mae',
      'email',
      'telefone',
      'nasc_fund',
      'uf',
      'bairro',
      'cidade',
      'endereco',
      'cpf_cnpj',
      'numero',
      'cep',
      'matricula',
      'empresa',
      'riscoPLD',
    ];

    for (const campo of campos) {
      if (informacoesPessoais[campo] === '' || informacoesPessoais[campo] === null) {
        if (campo === 'complemento') continue;
        if (campo === 'matricula') continue;
        if (campo === 'empresa') continue;
        if (campo === 'nome_mae') continue;

        setAbreAlerta(true);
        return;
      }

      const allFields = {
        NOME: informacoesPessoais.nome,
        CPFCNPJ_CLIENTE: informacoesPessoais.cpf_cnpj,
        NOME_MAE: informacoesPessoais.nome_mae,
        NUMERO: informacoesPessoais.numero,
        ENDERECO: informacoesPessoais.endereco,
        BAIRRO: informacoesPessoais.bairro,
        CIDADE: informacoesPessoais.cidade,
        UF: informacoesPessoais.uf,
        EMAIL: informacoesPessoais.email,
        NASCIMENTO: informacoesPessoais.nasc_fund,
        TELEFONE: informacoesPessoais.telefone,
        CEP: informacoesPessoais.cep,
        COMPLEMENTO: informacoesPessoais.complemento,
        MATRICULA: informacoesPessoais.matricula,
        EMPRESA: informacoesPessoais.empresa,
        CPFCNPJMATRIZ:
          user.CNPJMATRIZ && user.CNPJMATRIZ.length == 14
            ? informacoesPessoais.cpf_cnpj_matriz + informacoesPessoais.digits_variable
            : informacoesPessoais.cpf_cnpj_matriz,
        RISCO_PLD: informacoesPessoais.riscoPLD,
        CONVENIADA: informacoesPessoais.conveniada,
      };

      dispatch(
        addSTATE('DEFAULT_TYPE', '', {
          allFields: allFields,
        }),
      );
    }
    setModalSenhaUnica(!modalSenhaUnica);
    setOperation('alterarCadastro');

    return;
  }

  const alterAlert = () => setInformacoesPessoais((prev) => ({ ...prev, alert: !prev.alert }));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openModalMessageAction = (res, tipo) => {
    const { mensagem, retorno } = res.data;

    res.status && res.status != 200 ? setSucesso(false) : setSucesso(true);

    setMensagem(mensagem);
    setTipo(tipo);
    setModalMensagem(true);
  };

  useEffect(() => {
    dispatch(listarCoban());
    dispatch(pegarRiscoUsuario());
    dispatch(listarCartao(user.CPFCNPJ));
    dispatch(listarSaldoGeral(user.CPFCNPJ));
    setInformacoesPessoais((prev) => ({
      ...prev,
      cartaoContaDigital: [...informacoesPessoais.cartaoContaDigital, cartaoContaDigital],
    }));
  }, []);

  useEffect(() => {
    dispatch(listarCartao(user.CPFCNPJ));
    dispatch(listarSaldoGeral(user.CPFCNPJ));
  }, [props.reloadCards, reloadProdutos]);

  return (
    <>
      <div style={{ width: '95%', margin: '4rem auto 0 auto', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <AccordionInfoUser
          informacoesPessoais={informacoesPessoais}
          isConvened={user.CONVENIADA}
          validate={validate}
          risco={risco}
          alterAlert={alterAlert}
          infoPessoaisRedux={infoPessoaisRedux}
          renderTaxasUsuario={renderTaxasUsuario}
          setInformacoesPessoais={setInformacoesPessoais}
          alterValue={alterValue}
          setOpenAccordionInfo={setOpenAccordionInfo}
          openAccordionInfo={openAccordionInfo}
        />
        <AccordionDocumentsUser
          setOpenDocument={setOpenDocument}
          openDocument={openDocument}
          user={user}
          accept={props.accept}
        />
        <AccordionVerifications informacoesPessoais={informacoesPessoais} user={user} />
        <AccordionCardsUser
          allCards={allCards}
          saldoGeral={saldoGeral}
          filter={filter}
          setFilter={setFilter}
          setCard={setCard}
          setInfoCard={setInfoCard}
          user={user}
          searchWithFilter={props.searchWithFilter}
          setReloadProdutos={setReloadProdutos}
          reloadProdutos={reloadProdutos}
        />
        {user.TAXAS && (
          <AccordionAppliedFees
            user={user}
            renderTaxasUsuario={renderTaxasUsuario}
            openAccordionTaxes={openAccordionTaxes}
            setOpenAccordionTaxes={setOpenAccordionTaxes}
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ width: '32%' }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-content">
              <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
                Cartão
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonGroupCartao
                  user={user}
                  setModalSenhaUnica={setModalSenhaUnica}
                  state={informacoesPessoais}
                  setOperation={setOperation}
                  alteraCliente={informacoesPessoais.alteraCliente}
                />
              </Col>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ width: '32%' }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-content">
              <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
                Cliente
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonGroupCliente
                  setOpenAccordionTaxes={(e) => setOpenAccordionTaxes(e)}
                  setState={(el) => setInformacoesPessoais(el)}
                  user={user}
                  setModalSenhaUnica={setModalSenhaUnica}
                  state={informacoesPessoais}
                  setOperation={setOperation}
                  infoPessoaisRedux={infoPessoaisRedux}
                  setOpenAccordionInfo={(bool) => setOpenAccordionInfo(bool)}
                  alteraCliente={informacoesPessoais.alteraCliente}
                />
              </Col>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ width: '32%' }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-content">
              <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
                Conta
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ButtonGroupConta
                user={user}
                setModalSenhaUnica={setModalSenhaUnica}
                modalSenhaUnica={modalSenhaUnica}
                infoPessoaisRedux={infoPessoaisRedux}
                setOperation={setOperation}
                alteraCliente={informacoesPessoais.alteraCliente}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <AlertaErro
        style={{ backgroundColor: 'danger' }}
        isOpen={abreAlerta}
        toggle={() => setAbreAlerta(!abreAlerta)}
        mensagem="Não é possível salvar com campos vazios. Por favor preencha-os!"
      ></AlertaErro>

      <ModalConfirmacao
        user={user}
        modalConfirmacao={modalConfirmacao}
        state={cartao}
        setCartao={setCartao}
        setLoaded={setLoaded}
        toggleConfirmacaoCallback={(el) => setModalConfirmacao(!modalConfirmacao)}
        openModalMessageActionCallback={(el) => openModalMessageAction(el)}
      />
      <AnimatedLoading isActive={loaded} />
      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        tipo={tipo}
        toggle={() => setModalMensagem(false)}
      />

      <ModalVincularProduto
        modal={modalVincularProduto.open}
        produtos={modalVincularProduto.PRODUTOS}
        cpfcnpj={infoPessoaisRedux.cpf_cnpj}
        setReloadProdutos={setReloadProdutos}
        reloadProdutos={reloadProdutos}
        toggle={(e) => setModalVincularProduto(false)}
        idSwap={idSwap}
        idCartao={idCartao}
      />

      <ModalSenha
        modal={modalSenhaUnica}
        setModalQrCode={setModalQrCode}
        close={() => setModalSenhaUnica(false)}
        nextFunction={nextFunction}
        operation={operation}
        dados={informacoesPessoais.dados}
        setModalDoc={setModalDoc}
        setModalAlterarCoban={setModalAlterarCoban}
        setModalAlteraConta={setModalAlteraConta}
        setModalBlock={setModalBlock}
        setModalSaldo={setModalSaldo}
        setModalJustificativa={setModalJustificativa}
      />

      <Alert isOpen={visible} color="warning" toggle={() => setVisible(false)}>
        {messageAlert}
      </Alert>

      <ModalBloquear
        isOpen={modalBlock}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => setModalBlock(!modalBlock)}
      />
      <ModalAlteraCoban
        modal={modalAlterarCoban.open}
        toggle={() => setModalAlterarCoban({ open: false, title: '' })}
        cpfCliente={infoPessoaisRedux.cpf_cnpj}
        title={modalAlteraConta.title}
      />
      <ModalCardUser
        info={infoCard}
        setReloadProdutos={setReloadProdutos}
        reloadProdutos={reloadProdutos}
        toggle={() => setInfoCard((prev) => ({ ...prev, open: false }))}
        setModalSenha={setModalSenha}
        setIdSwap={setIdSwap}
        setIdCartao={setIdCartao}
        isConvened={user.CONVENIADA == 'S' ? true : false}
        setCartao={setCartao}
        setModalConfirmacao={setModalConfirmacao}
        modalConfirmacao={modalConfirmacao}
        setModalVincularProduto={setModalVincularProduto}
      />
      <ModalAlteraConta
        modal={modalAlteraConta}
        toggle={() => {
          setModalAlteraConta(false);
        }}
        data={informacoesPessoais.dados}
        option={informacoesPessoais.options2}
      />
      <ModalSaldo
        toggle={() => {
          setModalSaldo(false);
        }}
        modal={modalSaldo}
        dadoCPFCNPJ={infoPessoaisRedux.cpf_cnpj}
      />
      <ShippingFormModal modal={modalDoc} sendDoc={true} close={() => setModalDoc(false)} />
      <ModalQRCODE
        isOpen={modalQRCODE}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => setModalQrCode(!modalQRCODE)}
      />
      <ModalJustificativa
        isOpen={modalJustificativa}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          setModalJustificativa(!modalJustificativa);
        }}
      />
      <ModalVincularCartaoSwap
        modal={modalVincularCartaoSwap}
        toggle={(e) => setModalVincularCartaoSwap(false)}
        idSwap={idSwap}
      />
      <ModalSenha
        modal={modalSenha}
        close={() => setModalSenha(false)}
        operation={'trocaSenha'}
        modalTrocaSenha={(e) => setTrocaSenha(true)}
      />
      <ModalAprovaUser
        modal={aprovaUser}
        toggle={() => setAprovaUser(!aprovaUser)}
        title="Selecione o coban para o usuário"
        option="approve"
      />
      <ModalTrocaSenha isOpen={trocaSenha} id={idSwap} toggle={(e) => setTrocaSenha(false)} />
    </>
  );
}
