import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import { SmallerModal, ContainerMensagem } from './ConfirmationSend,styles';
import Input from '../../../../components/TextField/index';
import { useForm } from 'react-hook-form';
import { NotificationDetail } from '../../../../assets/icons/index';

const DetailMensagem = (props) => {
  const { control } = useForm({});

  return (
    <Modal open={props.openModal} close={props.closeModal}>
      <SmallerModal>
        <NotificationDetail size={'80'} />
        <h3>{'Detalhes de envio'}</h3>
        <ContainerMensagem>
          <Input
            width={'28.042vw'}
            title="TITULO"
            name="titulo"
            variant="standard"
            value={props.Data.titulo}
            disabled
            multiline={true}
            control={control}
            color={'#000 !important'}
          />
          <Input
            width={'28.042vw'}
            margin={'0.8rem 0 0.8rem 0'}
            title="MENSAGEM"
            name="mensagem"
            variant="standard"
            value={props.Data.mensagem}
            disabled
            multiline={true}
            control={control}
            color={'#000 !important'}
          />
          {props.Data.id_topico && (
            <Input
              width={'28.042vw'}
              margin={'0.8rem 0 0.8rem 0'}
              title="Grupo"
              name="GRUPO"
              variant="standard"
              value={props.Data.topico}
              disabled
              multiline={true}
              control={control}
              color={'#000 !important'}
            />
          )}
        </ContainerMensagem>
      </SmallerModal>
    </Modal>
  );
};

export default DetailMensagem;
