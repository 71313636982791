import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ModalBody } from 'reactstrap';
import Button from '../../../components/Button/Button.style';
import DateInput from '../../../components/DateInput/DateInput';
import TextInputMoney from '../../../components/TextInputMoney/TextInputMoney';
import ModalPassword from '../DuasEtapas/PasswordConfirmation';
import * as Styled from './ModalSaldo.styles';
import operateBalanceValidation from './validation/operateBalance.validation';

/* eslint-disable */
const ModalSaldo = (props) => {
  const dispatch = useDispatch();
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
  const [operateBalanceData, setOperateBalanceData] = useState({
    TIPO: '',
    DESCRICAO: '',
  });
  const [tipo, setTipo] = useState('');
  const [description, setDescription] = useState('');
  const inputMoneyRef = useRef(null);

  const { modal, toggle, dados, option, CPFCNPJ_USUARIO, dadoCPFCNPJ } = props;

  const closeModalPassword = () => setIsModalPasswordOpen(false);

  const {
    handleSubmit: handleOperateBalance,
    control: control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(operateBalanceValidation),
  });

  const getDate = () => {
    let date = new Date().toISOString().slice(0, 10);
    return date;
  };

  const openModalPassword = (data) => {
    data.DESCRICAO = description;
    data.TIPO = tipo;
    setIsModalPasswordOpen(true);
    setOperateBalanceData(data);
  };

  return (
    <Styled.ModalSaldo isOpen={modal}>
      <ModalBody>
        <Styled.Container>
          <Styled.ContainerBox>
            <div className="titleBox">Operar Saldo Cliente</div>
            <Styled.Form onSubmit={handleOperateBalance(openModalPassword)}>
              <TextInputMoney
                label={'VALOR'}
                name={'VALOR'}
                width={'29.706vw'}
                margin={'0 0 1vw 0'}
                required={true}
                control={control}
                error={errors.VALOR && true}
                inputProps={{ ref: inputMoneyRef }}
              />
              <DateInput
                label="Data"
                name={'DATA'}
                margin={'1vw 0 1vw 0'}
                width={'29.706vw'}
                disabled={true}
                value={getDate()}
                control={control}
              />
              <TextField
                label="Descrição"
                name={'DESCRICAO'}
                margin={'0 0 1vw 0'}
                multiline
                variant="standard"
                type={'text'}
                control={control}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <div className="bottons">
                <Button
                  backgroundColor={'#0CB42A'}
                  width="9.306vw"
                  margin={'2vw 0 0 0'}
                  borderColor={'#0CB42A'}
                  color={'white'}
                  type="submit"
                  onClick={() => {
                    setTipo('Adicionar');
                  }}
                >
                  {'Adicionar'}
                </Button>
                <Button
                  backgroundColor={'#EA6852'}
                  width="9.306vw"
                  margin={'2vw 0 0 0'}
                  borderColor={'#EA6852'}
                  color={'white'}
                  type="submit"
                  onClick={() => {
                    setTipo('Debitar');
                  }}
                >
                  {'Debitar'}
                </Button>
                <Button
                  backgroundColor={'#2405F2'}
                  width="9.306vw"
                  margin={'2vw 0 0 0'}
                  borderColor={'#2405F2'}
                  color={'white'}
                  onClick={() => {
                    toggle();
                  }}
                  block
                >
                  {'Voltar'}
                </Button>
              </div>
            </Styled.Form>

            {/* <ModalSms
                modal={isModalPasswordOpen}
                toggle={() => closeModalPassword()}
                valor={operateBalanceData.VALOR}
                dadoCPFCNPJ={dadoCPFCNPJ}
                transacao={operateBalanceData.TIPO}
                descricao={operateBalanceData.DESCRICAO}
                marginTop={'27.2315vh'}
              /> */}
            <ModalPassword
              modal={isModalPasswordOpen}
              toggle={() => closeModalPassword()}
              valor={operateBalanceData.VALOR}
              dadoCPFCNPJ={dadoCPFCNPJ}
              transacao={operateBalanceData.TIPO}
              descricao={operateBalanceData.DESCRICAO}
              marginTop={'27.2315vh'}
            />
          </Styled.ContainerBox>
        </Styled.Container>
      </ModalBody>
    </Styled.ModalSaldo>
  );
};

export default ModalSaldo;
