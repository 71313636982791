import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { cadastrarProduto, vincularSegmento, listarSegmento } from '../../../../store/swap/actions';
import TextField from '@material-ui/core/TextField';
import Select from '../../../../components/Select/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './AdicionarProduto.styles';
import ModalMensagem from '../../../../views/Transaction/ModalMensagemSemReload';

const AdicionarProduto = (props) => {
  const dispatch = useDispatch();
  const initial = { SEGMENTOS: [] };
  const [segmentos, setSegmentos] = useState([{ ...initial }]);
  const [nome, setNome] = useState('');
  const [check, setCheck] = useState(true);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');

  useEffect(() => {
    dispatch(listarSegmento());
  }, []);

  const { listagemSegmentos } = useSelector((seg) => seg.swap);

  const changeSegmentos = (e, ix) => {
    const { name, value } = e.target;
    let values = [...segmentos];
    values[0][name] = value;
    setSegmentos((state) => [...values]);
  };

  const reset = () => {
    setNome('');
    setCheck(true);
    setSegmentos([{ SEGMENTOS: [] }]);
  };

  const cadastro = () => {
    const param = {
      DESCRICAO: nome,
      ATIVO: check ? 'S' : 'N',
    };

    dispatch(
      cadastrarProduto(param, (res) => {
        if (res.retorno === 'sucesso') {
          dispatch(
            vincularSegmento(
              {
                ID_SEGMENTO: segmentos[0].SEGMENTOS,
                ATIVO: check ? 'S' : 'N',
                ID_PRODUTO: res.data.ID,
              },
              (res) => {
                setModalMensagem(true);
                setMensagem(res.mensagem);
                res.retorno === 'sucesso' ? setSucesso(true) : setSucesso(false);
                reset();
              },
            ),
          );
        }
      }),
    );
  };

  return (
    <>
      <Modal
        isOpen={props.open}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          props.close();
          reset();
        }}
      >
        <ModalHeader
          toggle={() => {
            props.close();
          }}
        >
          <h2>Cadastre o produto</h2>
        </ModalHeader>
        <FormGroup>
          <ModalBody>
            <Styled.Conteiner>
              <TextField
                label="Nome"
                name="NOME"
                multiline
                fullWidth
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                error={false}
              />
              <Styled.SelectConteiner>
                <Select
                  inputName={'Segmentos'}
                  name="SEGMENTOS"
                  handleChange={(e) => changeSegmentos(e)}
                  selects={segmentos[0].SEGMENTOS}
                  options={listagemSegmentos}
                  error={false}
                  multiple={true}
                />
              </Styled.SelectConteiner>
              <Styled.CheckConteiner>
                <FormControlLabel
                  control={<Checkbox name="ATIVO" checked={check} onChange={(e) => setCheck(e.target.checked)} />}
                  label="Ativo"
                />
              </Styled.CheckConteiner>
            </Styled.Conteiner>
          </ModalBody>
        </FormGroup>
        <ModalFooter>
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              props.close();
              reset();
            }}
            block
          >
            Cancelar
          </Button>
          <Button
            color="success"
            size="sm"
            disabled={segmentos[0].SEGMENTOS.length === 0}
            onClick={(e) => {
              cadastro();
            }}
            block
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>

      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        reload={props.reload}
        setReload={props.setReload}
        toggle={(e) => {
          setModalMensagem(false);
          props.close();
          props.setReload(!props.reload);
        }}
      />
    </>
  );
};

export default AdicionarProduto;
