import React from 'react';
import { Alert } from 'reactstrap';

function AlertSolicitacao(props) {
  const { isOpen, mensagem, toggle } = props;

  return (
    <Alert isOpen={isOpen} color="danger" toggle={toggle}>
      {mensagem}
    </Alert>
  );
}

export default AlertSolicitacao;
