import React from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar.jsx';
import AdminFooter from '../components/Footers/AdminFooter.jsx';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import * as Styled from './admin.styles';
import Sidebar from '../components/Sidebar/Sidebar.jsx';
import Header from '../components/Headers/Header.jsx';
import { drawerToogle } from '../store/dashboard/actions';

import routes from '../routes.js';
import { LOGO, LOGOTAKSIM } from '../assets/EnvStyles.js';
import './admin.css';
import SweetAlert from '../components/SweetAlertRedux/SweetAlert';

const taksim = 6;

/* eslint-disable */
class Admin extends React.Component {
  componentDidMount() {
    if (!this.props.auth.is_authenticate || this.props.auth.menu.length === 0) this.props.history.push('/login');
  }
  componentDidUpdate(e) {
    if (!this.props.auth.is_authenticate || this.props.auth.menu.length === 0) this.props.history.push('/login');
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.layout === '/admin' &&
        this.props.auth.is_authenticate &&
        this.props.auth.menu &&
        this.props.auth.menu.length > 0 &&
        this.props.auth.menu.find((x) => x.ID == prop.id)
      ) {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} exact />;
      } else {
        return null;
      }
    });
  };

  renderOptions() {
    const location = this.props.location.pathname.replace('/admin', '');
    const aux = routes.find((route) => route.path == location);

    if (aux && aux.options) {
      return aux.options.map((option) => (
        <Link className={'btn btn-icon btn-3 ' + option.color} to={option.to} type="button">
          <span className="btn-inner--icon">
            <i className={option.icon} />
          </span>
          <span className="btn-inner--text">{option.text}</span>
        </Link>
      ));
    }
  }

  getBrandText() {
    const location = this.props.location.pathname.replace('/admin', '');

    const aux = routes.find((route) => route.path == location);

    if (aux) return aux.name;
    return 'Brand';
  }

  render() {
    const { drawerToogle, drawerWidth } = this.props.dashboard;
    return (
      <>
        {localStorage.getItem('nivelAcesso') != taksim ? (
          <>
            <Sidebar
              {...this.props}
              routes={routes}
              logo={{
                innerLink: '/admin/index',
                imgSrc: LOGO,
                imgAlt: 'Logo-troco-na-conta',
              }}
            />
          </>
        ) : (
          <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: '/admin/index',
              imgSrc: LOGOTAKSIM,
              imgAlt: 'Logo-troco-na-conta',
            }}
          />
        )}

        <div
          className={`main-content ${drawerToogle ? 'dashboard_open' : 'dashboard_closed'}`}
          ref="mainContent"
          id="main_content"
        >
          <Styled.ButtonToggle
            onClick={() => this.props.drawerToogle(!drawerToogle)}
            drawerToogle={drawerToogle}
            drawerWidth={drawerWidth}
          >
            {drawerToogle ? <NavigateBefore /> : <NavigateNext />}
          </Styled.ButtonToggle>
          <AdminNavbar {...this.props} brandText={this.getBrandText()} />
          <Header />
          <SweetAlert />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth, dashboard }) => {
  return { auth, dashboard };
};
export default connect(mapStateToProps, {
  drawerToogle,
})(Admin);
