/* eslint-disable */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnimatedLoading from '../../../components/Loader/AnimatedLoading';
import { solicitarCartaoSwap } from '../../../store/user/actions';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';

const ModalQRCODE = (props) => {
  const { isOpen, modalTransition, backdropTransition, toggle } = props;
  const [mensagem, setMensagem] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [visible, setVisible] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const onDismiss = () => setVisible(!visible);

  const user = props.user.user || props.userData;
  const params = {
    CPFCNPJ_USUARIO: user.CPFCNPJ,
    CARD_ID: mensagem,
    VIRTUAL: 'false',
  };
  const handle = (target) => {
    setMensagem(target);
  };

  const openModalMessage = (res) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);
    setLoaded(false);
    setMensagem(mensagem);
    setModalMensagem(true);
  };

  const click = () => {
    if (mensagem == '') {
      setVisible(!visible);
      setMensagemAlerta('Preencha os campos');
      setLoaded(true);
    } else if (mensagem != '' && user.STATUS == 7) {
      props.solicitarCartaoSwap(params, (res) => {
        openModalMessage(res);
      });
    }
  };

  const alert = () => {
    return (
      <Alert color="danger" isOpen={visible} toggle={() => onDismiss()}>
        {mensagemAlerta}
      </Alert>
    );
  };

  return (
    <Modal isOpen={isOpen} modalTransition={modalTransition} backdropTransition={backdropTransition} toggle={toggle}>
      <ModalHeader toggle={() => toggle()}>Cadastrar Cartão Não Nominal</ModalHeader>
      <FormGroup>
        <ModalBody>
          <span>Digite o QR CODE do Cartão</span>
          <Input placeholder="QR CODE" size="sm" onChange={(e) => handle(e.target.value)}></Input>
          {alert()}
        </ModalBody>
      </FormGroup>
      <AnimatedLoading isActive={loaded} />

      <ModalFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button style={{ marginTop: '8px' }} color="secondary" size="sm" onClick={() => toggle()} block>
            Cancelar
          </Button>
          <Button
            color="success"
            size="sm"
            onClick={() => {
              click();
              setLoaded(true);
            }}
            block
          >
            Confirmar
          </Button>
        </div>
      </ModalFooter>

      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={() => setModalMensagem(false)}
      />
    </Modal>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  solicitarCartaoSwap,
})(ModalQRCODE);
