import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import formatCPF from '../../../../src/utils/formatCPF';
import StatusContainer from '../../../components/StatusContainer/StatusContainer.js';
import { listarCoban } from '../../../store/coban/actions';
import { addSTATE } from '../../../store/globals';
import { consultDataTrust, createUserCard } from '../../../store/user/actions';
import formatCEP from '../../../utils/formatCEP';
import formatCNPJ from '../../../utils/formatCNPJ';
import formatMoney from '../../../utils/formatMoneyBRL';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import AccordionVerifications from '../../../views/User/components/AccordionVerifications/AccordionVerifications';
import { DocumentFuncional as ModalDocument } from '../../../views/User/components/modals/index';
import ModalAcessobio from '../../Modais/Cliente/ModalAcessobio';
import * as Styled from './ModalInfo.styles';

/* eslint-disable */

const ModalInfos = (props) => {
  const kyc = useSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [imagem, setImagem] = useState({
    openDocument: false,
    documentLabel: '',
    urlImage: '',
  });

  const {
    NOME,
    CPFCNPJ,
    DOCUMENTO,
    DOCUMENTOS,
    EMAIL,
    ENDERECO,
    NASCIMENTO,
    SALARIO,
    TELEFONE,
    CEP,
    BAIRRO,
    CIDADE,
    COMPLEMENTO,
    UF,
    idBio,
    REPRESENTANTES,
    EMPRESATRABALHA,
    MATRICULA,
    ID_TIPO_USUARIO,
    RISCO_PLD,
  } = props.userInfos || {};

  useEffect(() => {
    var newuser = props.userInfos;

    newuser.ID_COBAN = 0;
    props.addSTATE('USER_ADD_STATE', 'user', newuser);
  }, []);

  const handleClick = ({ target }) => {
    const { name, value } = target;
    let newUser = props.userInfos;
    switch (name) {
      case 'coban':
        newUser.ID_COBAN = value;
        props.addSTATE('USER_ADD_STATE', 'user', newUser);
        break;
      case 'riscoPLD':
        newUser.RISCO_PLD = value;
        props.addSTATE('USER_ADD_STATE', 'user', newUser);
        break;
    }
  };
  let PARCEIRO = ID_TIPO_USUARIO;
  if (PARCEIRO == 1) {
    PARCEIRO = <StatusContainer code={'CDC'} description={'CDC'}></StatusContainer>;
  }
  if (PARCEIRO == 2) {
    PARCEIRO = <StatusContainer code={'TAKSIM'} description={'TAKSIM'}></StatusContainer>;
  }

  return (
    <div>
      {<ModalAcessobio toggle={openModal} modal={modal} id={idBio}></ModalAcessobio>}

      <Styled.ContainerStyled>
        <Styled.Title>Informações Pessoais</Styled.Title>
        <Styled.RowContainer>
          <Styled.ParagraphName>CPF</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="CPFCNPJ">
            {CPFCNPJ.length == 11 ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Nome</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="NOME">
            {NOME}
          </Styled.ParagraphValue>
        </Styled.RowContainer>

        <Styled.RowContainer>
          <Styled.ParagraphName>E-mail</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="EMAIL">
            {EMAIL}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Data de Nascimento</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="NASCIMENTO">
            {NASCIMENTO}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Telefone</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="TELEFONE">
            {formatPhoneNumber(TELEFONE)}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>CEP</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="CEP">
            {formatCEP(CEP)}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Endereço</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="ENDERECO">
            {ENDERECO}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Bairro</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="BAIRRO">
            {BAIRRO}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Complemento</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="COMPLEMENTO">
            {COMPLEMENTO}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Cidade</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="CIDADE">
            {CIDADE}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>UF</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="UF">
            {UF}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Renda mensal</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="SALARIO">
            {'R$ ' + formatMoney(SALARIO)}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Matrícula</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="SALARIO">
            {MATRICULA ? MATRICULA : '---'}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.ParagraphName>Empresa</Styled.ParagraphName>
          <Styled.ParagraphValue className="user_modal_info" data-info="SALARIO">
            {EMPRESATRABALHA ? EMPRESATRABALHA : '---'}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
      </Styled.ContainerStyled>
      {REPRESENTANTES && REPRESENTANTES.length > 0 ? <h4>Dados do Representante Legal</h4> : null}
      {REPRESENTANTES && REPRESENTANTES.length > 0 ? (
        <div style={styles.Card}>
          <div style={{ marginLeft: 10, padding: 10 }}>
            {REPRESENTANTES &&
              REPRESENTANTES.map((represent, index) => {
                return (
                  <div>
                    <p>
                      <strong>Representante:</strong> {index + 1}
                    </p>
                    <p>
                      <strong>Nome:</strong> {represent.NOME}
                    </p>
                    <p>
                      <strong>CPF:</strong> {formatCPF(represent.CPF)}
                    </p>
                    <p>
                      <strong>Celular:</strong> {represent.CELULAR}
                    </p>
                    <p>
                      <strong>CNPJ empresa:</strong>
                      {formatCNPJ(represent.CNPJ_EMPRESA)}
                    </p>
                    <p>
                      <strong>Principal:</strong>
                      {represent.PRINCIPAL == 'S' ? 'Sim' : 'Não'}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}

      <Styled.ImagesContainer>
        <h4>Documentos Pessoais</h4>
        <div>
          {DOCUMENTOS &&
            DOCUMENTOS.map((docs) => {
              return [
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  onClick={() =>
                    setImagem({
                      openDocument: true,
                      documentLabel: docs.DESCRICAO_CATEGORIA,
                      urlImage: docs.CAMINHO_ARQUIVO,
                    })
                  }
                >
                  {docs.DESCRICAO_CATEGORIA}
                </Button>,
              ];
            })}
        </div>
      </Styled.ImagesContainer>

      {(localStorage.getItem('nivelAcesso') != '6' && ID_TIPO_USUARIO == '1') ||
      (localStorage.getItem('nivelAcesso') == '6' && ID_TIPO_USUARIO == '2') ? (
        <>
          {props.user.nivelPrioridade != 100 && <h4>Estabelecimento/Correspondente</h4>}
          {props.user.nivelPrioridade != 100 && (
            <div style={styles.Card}>
              <div style={{ marginLeft: 10, padding: 10 }}>
                <div className="form-group coban-select-container">
                  {/* <label for="coban-select">Selecione o Correspondente Bancário:</label> */}
                  <select
                    onChange={(e) => {
                      handleClick(e);
                    }}
                    name="coban"
                    style={{ width: '100%' }}
                    id="coban-select"
                    className="coban-select-2"
                  >
                    <option selected value={0}>
                      Selecione o Correspondente Bancário:
                    </option>
                    {props.coban.list
                      .filter((tipo) => tipo.PERFIL == '1')
                      .map((a, index) => {
                        return <option value={a.ID}> {a.NOME}</option>;
                      })}
                  </select>
                </div>
              </div>
            </div>
          )}
          {/* Select para selecionar o nivel de risco de PLD do usuário  */}
          {props.user.nivelPrioridade != 100 && kyc.riscoPLD && <h4>Risco PLD:</h4>}
          {props.user.nivelPrioridade != 100 && kyc.riscoPLD && (
            <div style={styles.Card}>
              <div style={{ marginLeft: 10, padding: 10, marginTop: '1rem' }}>
                <div className="form-group risco-select-container">
                  {/*  <select
                    onChange={(e) => {
                      handleClick(e);
                    }}
                    name="riscoPLD"
                    style={{ width: '100%' }}
                    id="risco-select"
                    className="risco-select-2"
                    value={RISCO_PLD}
                  >
                    <option value={null} selected>
                      Selecione o Risco PLD
                    </option>
                    {props.risco &&
                      props.risco.data.map((risco) => <option value={risco.ID}>{risco.DESCRICAO}</option>)}
                  </select> */}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Styled.ImagesContainer>
          <h4>Para aprovar esse usuário logue com uma conta {PARCEIRO}</h4>
        </Styled.ImagesContainer>
      )}

      <AccordionVerifications user={props.consultas} uniqueColumn={true} />

      <ModalDocument state={imagem} toggle={() => setImagem({ openDocument: false })} />
    </div>
  );
};

const styles = {
  Card: { border: '1px solid #f0f0f0', borderRadius: 10, marginBottom: 15 },
  foto: { height: '50%', width: '90%' },
};
const mapStateToProps = ({ user, coban }) => {
  return { user, coban };
};

export default connect(mapStateToProps, {
  consultDataTrust,
  createUserCard,
  listarCoban,
  addSTATE,
})(ModalInfos);
