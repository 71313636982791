import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { Button, Col } from 'reactstrap';
import { CustomBadge } from '../../User.style';
import InfosUser from '../InfosUser';

function AccordionInfoUser({
  informacoesPessoais,
  isConvened,
  validate,
  alterAlert,
  infoPessoaisRedux,
  renderTaxasUsuario,
  setInformacoesPessoais,
  alterValue,
  setOpenAccordionInfo,
  openAccordionInfo,
  risco,
  /*   toggleConfirmacao,
  openModalPasswordBlockDigitalCard,
  openModalSegundaVia, */
}) {
  return (
    <Accordion expanded={openAccordionInfo} id="informacoesPessoais">
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-content"
        onClick={() => informacoesPessoais.alteraCliente && setOpenAccordionInfo(!openAccordionInfo)}
      >
        <Typography variant="h5" sx={{ width: '80%', fontWeight: '600', fontSize: '18px', flexShrink: 0 }}>
          Informações pessoais {isConvened === 'S' && <CustomBadge>Conveniada</CustomBadge>}
        </Typography>

        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Col xs={1}>
            {!informacoesPessoais.alteraCliente && (
              <Button
                type="submit"
                size="sm"
                style={{ background: '#0245AE', color: '#FFF' }}
                onClick={() => {
                  !informacoesPessoais.alteraCliente ? validate() : alterAlert();
                }}
              >
                Salvar
              </Button>
            )}
          </Col>

          <Col xs={5}>
            {!informacoesPessoais.alteraCliente && (
              <Button
                size="sm"
                style={{ border: '1px solid #525F7F', color: '#525F7F' }}
                onClick={() => {
                  setInformacoesPessoais((prev) => ({
                    ...prev,
                    ...infoPessoaisRedux,
                    alteraCliente: !prev.alteraCliente,
                  }));
                  // window.location.reload();
                }}
              >
                Cancelar
              </Button>
            )}
          </Col>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <InfosUser
          renderTaxasUsuarioCallback={(el) => renderTaxasUsuario(el)}
          user={infoPessoaisRedux}
          state={informacoesPessoais}
          setState={(el) => setInformacoesPessoais(el)}
          risco={risco}
          /* toggleConfirmacaoCallback={(el) => this.toggleConfirmacao()}
          openModalPasswordBlockDigitalCardCallback={(el) => this.openModalPasswordBlockDigitalCard()}
          openModalSegundaViaCallback={(el) => this.openModalSegundaVia()} */
          alterValueCallback={(el) => alterValue(el)}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionInfoUser;
