import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Form, Input, Label, Row } from 'reactstrap';
import GenericButton from '../../components/Buttons/Button.js';
import CustomContainer from '../../components/Custom/CustomContainer';
import InputDate from '../../components/Filter/components/InputDate';
import InputSearch from '../../components/Filter/components/InputSearch';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults';
import InputStatus from '../../components/Filter/components/InputStatus.js';
import Filter from '../../components/Filter/Filter';
import * as Styled from '../../components/Filter/styles/Filter.style';
import Icon from '../../components/Icons/IconsButton.js';
import Loading from '../../components/Loader/Loading.jsx';
import ModalInformationsPayments from '../../components/Modais/Pagamento/ModalInformationsPayments';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import Table from '../../components/Table/Table.jsx';
import { getOnlyOnePayment, getPayments } from '../../store/transactions/actions';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL';
import valueEmpty from '../../utils/valueEmpty';
import { Info } from './styles/Payment.style';

export default function Payment() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [id, setId] = useState('');

  const [filter, setFilter] = useState({
    search: '',
    DATAINICIAL: '',
    DATAFINAL: moment().format('YYYY-MM-DD'),
    limit: 10,
    page: 1,
    TIPO_PAGAMENTO: '',
  });

  useEffect(() => {
    dispatch(getPayments(filter));
  }, [filter.page]);

  let { page } = filter;

  const { pagamentos, pagamento, pagination, loading_transaction, totalTrasacao } = useSelector(
    (state) => state.transactions,
  );

  const clickInfo = (ID) => {
    setModal(true);
    setId(ID);
    dispatch(getOnlyOnePayment(ID));
  };

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page });
  };

  return (
    <div>
      <CustomContainer>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <Styled.InputsContainer>
              <InputSearch
                width="30vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
              />
              <InputStatus
                setValue={(value) => setFilter({ ...filter, STATUS: value })}
                options={['Todas', 'Aguardando', 'Aprovado', 'Cancelado', 'Vencido']}
                codeValue={['', 1, 2, 3, 4]}
              />
              <div>
                <Label size="sm">{'Forma Pagamento'}</Label>
                <Input
                  onChange={(event) =>
                    setFilter({
                      ...filter,
                      TIPO_PAGAMENTO: event.target.value,
                    })
                  }
                  type="select"
                  name="TIPO_PAGAMENTO"
                  id="TIPO_PAGAMENTO"
                  size="sm"
                >
                  <option value="">Todos</option>
                  <option value="1">Cartão</option>
                  <option value="2">Conta Digital</option>
                </Input>
              </div>

              <InputDate
                label="Data Inicial"
                name="DATAINICIAL"
                value={filter.DATAINICIAL}
                setValue={(value) => setFilter({ ...filter, DATAINICIAL: value })}
              />

              <InputDate
                label="Data Final"
                name="DATAFINAL"
                value={filter.DATAFINAL}
                setValue={(value) => setFilter({ ...filter, DATAFINAL: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <GenericButton
                  marginTop={'25px !important'}
                  width={'140px'}
                  heigth={'35px'}
                  type="submit"
                  color="primary"
                  size="sm"
                  onClick={() => dispatch(getPayments(filter), (filter.page = 1))}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
              </div>
            </Styled.InputsContainer>
          </Form>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <InputSelectAmountResults
              options={['10', '25', '50', '100', '1000']}
              value={filter.limit}
              setValue={(value) => setFilter({ ...filter, limit: value })}
              width={'25rem'}
            />
            <h4>{'TOTAL PAGAMENTOS: R$ ' + formatMoney(totalTrasacao)} </h4>
          </div>
        </Filter>
        <Row>
          <div className="col">
            <div>
              {loading_transaction ? (
                <Loading color="#11cdef" />
              ) : pagamentos ? (
                <Table
                  headers={[
                    {
                      info: 'Data/Hora',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: 'CPF/CNPJ',
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'NOME',
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'FORMA PAGAMENTO',
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'DESCRIÇÃO',
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'PARCEIRO',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: 'VALOR PAGO',
                      style: { textAlign: 'right' },
                    },
                    {
                      info: 'Status',
                      style: { textAlign: 'center' },
                    },
                  ]}
                  data={
                    pagamentos &&
                    pagamentos.length > 0 &&
                    pagamentos.map((pagamento) => {
                      return {
                        data: [
                          {
                            info: valueEmpty(pagamento.DATA_TRANSACAO),
                            style: { textAlign: 'center' },
                          },
                          {
                            info: formatCPF(valueEmpty(pagamento.CPFCNPJ)),
                            style: { textAlign: 'left' },
                          },
                          {
                            info: valueEmpty(pagamento.NOME),
                            style: { textAlign: 'left' },
                          },
                          {
                            info: valueEmpty(pagamento.TIPO_PAGAMENTO),
                            style: { textAlign: 'left' },
                          },
                          {
                            info: valueEmpty(pagamento.DESCRICAO),
                            style: { textAlign: 'left' },
                          },
                          {
                            info: pagamento.TIPO_USUARIO ? (
                              <StatusContainer
                                code={pagamento.TIPO_USUARIO.toUpperCase()}
                                description={pagamento.TIPO_USUARIO}
                              ></StatusContainer>
                            ) : (
                              '-----'
                            ),
                            style: { textAlign: 'center' },
                          },
                          {
                            info: 'R$ ' + formatMoney(valueEmpty(pagamento.VALOR_PAGO)),
                            style: { textAlign: 'right' },
                          },
                          {
                            info:
                              pagamento.STATUSTRANSACAO === '1' ? (
                                <Badge color="info">AGUARDANDO</Badge>
                              ) : pagamento.STATUSTRANSACAO === '2' ? (
                                <Badge color="success">APROVADO</Badge>
                              ) : pagamento.STATUSTRANSACAO === '3' ? (
                                <Badge color="danger">CANCELADO</Badge>
                              ) : pagamento.STATUSTRANSACAO === '4' ? (
                                <Badge color="secondary">VENCIDO</Badge>
                              ) : null,
                            style: { textAlign: 'center' },
                          },
                        ],
                        onClick: () => clickInfo(pagamento.ID),
                      };
                    })
                  }
                  pagination={pagination}
                  previousPage={{ onClick: () => tablePagination('prev') }}
                  nowPage={{ page, onClick: pagination }}
                  nextPage={{ onClick: () => tablePagination('next') }}
                />
              ) : (
                <Info>Nenhum registro encontrado</Info>
              )}
              <ModalInformationsPayments
                toggle={() => setModal(!modal)}
                modal={modal}
                title={'Dados do Pagamento'}
                id={id}
                dataInfo={pagamento}
              ></ModalInformationsPayments>
            </div>
          </div>
        </Row>
      </CustomContainer>
    </div>
  );
}
