import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import { ButtonContainer, SmallerModal, ContainerMensagem } from './ConfirmationSend,styles';
import { useForm } from 'react-hook-form';
import Button from '../../../../components/Buttons/Button';
import Input from '../../../../components/TextField/index';
import { NotificationAlert } from '../../../../assets/icons/index';
import { useDispatch } from 'react-redux';
import { sendMensagemTopic, sendMensagemUnique, sendMensagemMultiple } from '../../../../store/notifications/actions';

const ConfirmationSend = (props) => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm({});

  const sendFinish = () => {
    props.Data.TOPICO && dispatch(sendMensagemTopic(props.Data, props.closeModal));
    props.Data.USUARIO && dispatch(sendMensagemUnique(props.Data, props.closeModal));
    props.Data.USUARIOS && dispatch(sendMensagemMultiple(props.Data, props.closeModal));
  };

  return (
    <Modal open={props.openModal} close={props.backClose}>
      <SmallerModal>
        <NotificationAlert size={'80'} />
        <h3>{'Confirmação de envio'}</h3>
        <form onSubmit={handleSubmit(sendFinish)}>
          <ContainerMensagem>
            <Input
              width={'24.042vw'}
              title="TITULO"
              name="TITULO"
              variant="standard"
              value={props.Data.TITULO}
              disabled
              multiline={true}
              control={control}
              color={'#000 !important'}
            />
            <Input
              width={'24.042vw'}
              margin={'0.8rem 0 0 0'}
              title="MENSAGEM"
              name="MENSAGEM"
              variant="standard"
              value={props.Data.MENSAGEM}
              disabled
              multiline={true}
              control={control}
              color={'#000 !important'}
            />
          </ContainerMensagem>
          <ButtonContainer>
            <Button
              backgroundColor={'#F8F8F8'}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverBGColor={'#F8F8F8'}
              hoverColor={'#333333'}
              color={'#333333'}
              onClick={props.backClose}
            >
              <span>Cancelar</span>
            </Button>
            <Button
              backgroundColor={'#0D488C'}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverBGColor={'#0D488C'}
              hoverColor={'#F8F8F8'}
              color={'#F8F8F8'}
              type={'submit'}
              roleName={'button-confirmar-negar'}
            >
              <span>{'Enviar'}</span>
            </Button>
          </ButtonContainer>
        </form>
      </SmallerModal>
    </Modal>
  );
};

export default ConfirmationSend;
