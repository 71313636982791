import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { deletarProdutosCartao } from '../../../../../../../store/swap/actions';

const ModalConfirmacao = (props) => {
  const dispatch = useDispatch();
  const { modal, toggle, produto } = props;

  function verifyOption() {
    dispatch(deletarProdutosCartao(produto.ID));
    window.location.reload();
    toggle();
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      verifyOption();
    }
  }

  return (
    <Modal onKeyDown={handleKeyDown} isOpen={modal} toggle={() => toggle()}>
      <ModalHeader toggle={() => toggle()}>
        <h1>Excluir Produto</h1>
      </ModalHeader>

      <ModalBody>Você deseja realmente excluir esse produto?</ModalBody>
      <ModalFooter>
        <Button id="success-button" color="success" onClick={() => verifyOption()}>
          Sim
        </Button>
        <Button onClick={() => toggle()} color="danger">
          Não
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirmacao;
