import React from 'react';
import { StepThree, StepTwo } from './index';

const StepContent = (props) => {
  switch (props.activeStep) {
    case 0:
      return (
        <StepTwo
          setProduto={(e) => props.setProdutoCallback(e)}
          erroStepValidation={props.erroStepValidationCallback}
          setErroStepValidation={(e) => props.setErroStepValidationCallback(e)}
          idSwap={props.idSwap}
        />
      );
    case 1:
      return <StepThree dataProd={props.dataProd} dataId={props.dataId} />;
    default:
      return 'Unknown step';
  }
};

export default StepContent;
