import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
/* eslint-disable */
const ButtonGroupConta = (props) => {
  const [hoverButton, setHoverButton] = useState({});

  function handleHoverButton(id, bool) {
    setHoverButton({ ...hoverButton, [id]: bool });
  }

  function handleClick(operation) {
    props.setModalSenhaUnica(true);
    props.setOperation(operation);
  }

  return (
    <ButtonGroup size="sm" style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
      <Button
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        color="primary"
        style={{ borderRadius: '5px', transform: hoverButton[1] ? 'translateY(-.1rem)' : '' }}
        id="1"
        onClick={() => {
          handleClick('maisContaBancaria');
        }}
      >
        + Conta Bancária
      </Button>
      {/* 
      {props.user.STATUS_CONTA_DIGITAL === '4' ? (
        <Button
          color="primary"
          onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
          onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
          style={{ borderRadius: '5px', transform: hoverButton[2] ? 'translateY(-.1rem)' : '' }}
          id="2"
          onClick={() => {
            props.setState({
              ...props.user,
              modalPasswordContaDigital: true,
              dados: {
                CPFCNPJ_USUARIO: props.user.CPFCNPJ,
              },
            });
          }}
        >
          Solicitar Cartão Hub Fintech
        </Button>
      ) : null} */}

      {props.user.STATUS == 2 || props.user.STATUS == 5 ? (
        <Button
          onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
          onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
          id="8"
          color="primary"
          style={{ borderRadius: '5px', transform: hoverButton[8] ? 'translateY(-.1rem)' : '' }}
          onClick={() => handleClick('criarContaDigital')}
        >
          Criar Conta Digital
        </Button>
      ) : null}

      {localStorage.getItem('nivelAcesso') == 1 && props.user.STATUS == 7 && (
        <>
          <Button
            onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
            onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
            color="primary"
            style={{ borderRadius: '5px', transform: hoverButton[13] ? 'translateY(-.1rem)' : '' }}
            id="13"
            onClick={() => {
              handleClick('operarSaldoCliente');
            }}
          >
            Operar Saldo Cliente
          </Button>
        </>
      )}
      {props.user.STATUS == 7 && (
        <Button
          color="primary"
          onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
          onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
          style={{ borderRadius: '5px', transform: hoverButton[14] ? 'translateY(-.1rem)' : '' }}
          id="14"
          onClick={() => {
            handleClick('encerrarConta');
          }}
        >
          Encerrar Conta
        </Button>
      )}
    </ButtonGroup>
  );
};

export default ButtonGroupConta;
