import React, { Component } from 'react';
import TextInputMask from 'react-masked-text';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { alteraVencimentoEmprestimo, updateFinanciamento, updateTaxes } from '../../../store/coban/actions';
import { getTaxaConfigs } from '../../../store/config/actions';
import { addSTATE } from '../../../store/globals';
import { formatMoney } from '../../../utils/formatMoneyBRL';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import './styles.css';

/* eslint-disable */

const max_percent = 10000;
const withPercentLimit = ({ floatValue }) => floatValue <= max_percent;
class ModalTaxasDigital extends Component {
  state = {
    dadosTaxas: [],
    dadosFinanciamento: [],
    alert: false,
    value: 0,
    dataVencimento: this.props.coban.dataVencimento ? this.props.coban.dataVencimento : 0,
    isQRcodeFocused: false,
    isJurosFocused: false,
    isPagamentoBoletoFocused: false,
    modalMensagem: false,
    mensagem: '',
    sucesso: false,
  };

  onDismiss = () => this.setState({ alert: !this.state.alert });

  componentDidMount() {
    this.props.getTaxaConfigs();
  }

  getTaxa = (ID_TIPO_TAXA, flag) => {
    var { config } = this.props;
    if (flag == true) {
      var value = config.TAXA.find((taxa) => taxa.ID == ID_TIPO_TAXA)
        ? config.TAXA.find((taxa) => taxa.ID == ID_TIPO_TAXA).TAXA
        : 0;
    } else {
      var value = config.FINANCIAMENTO.find((taxa) => taxa.ID == ID_TIPO_TAXA)
        ? config.FINANCIAMENTO.find((taxa) => taxa.ID == ID_TIPO_TAXA).TAXA
        : 0;
    }
    return value;
  };

  onChange = (value, name) => {
    var { coban } = this.props.coban;
    if (coban.TAXAS) {
      const dadosTaxas = coban.TAXAS.map((t) => {
        if (value) {
          if (t.ID_TIPO_TAXA === name) {
            t.VALOR = value;
          }
        }
        return t;
      });
      this.props.addSTATE('COBAN_ADD_STATE', 'coban', coban);
      this.setState({ dadosTaxas });
    }
  };

  onChangeFinanciamento = (event, parcela_min, parcela_max) => {
    const { name, value } = event.target;
    var { coban } = this.props.coban;
    var taxaOperadora = this.getTaxa(parcela_max, false);
    this.setState({ taxaOperadora: taxaOperadora });
    if (coban.FINANCIAMENTO) {
      const dadosFinanciamento = coban.FINANCIAMENTO.map((t) => {
        if (t.PARCELA >= parcela_min && t.PARCELA <= parcela_max) {
          if (value > taxaOperadora) {
            t.TAXA = value;
          } else {
            t.TAXA = taxaOperadora;
            this.setState({ alert: true, value: name });
          }
        }
        return t;
      });
      this.props.addSTATE('COBAN_ADD_STATE', 'coban', coban);
      this.setState({ dadosFinanciamento });
    }
  };

  getValue(ID_TIPO_TAXA) {
    var { coban } = this.props.coban;
    var value = '';

    if (coban.TAXAS) {
      value = coban.TAXAS.find((taxa) => taxa.ID_TIPO_TAXA == ID_TIPO_TAXA)
        ? coban.TAXAS.find((taxa) => taxa.ID_TIPO_TAXA == ID_TIPO_TAXA).VALOR
        : '';
    }

    return value;
  }

  getValueFinanciamento(ID) {
    var { coban } = this.props.coban;
    var value = '';
    if (coban.FINANCIAMENTO) {
      value = coban.FINANCIAMENTO.find((financiamento) => financiamento.PARCELA == ID)
        ? coban.FINANCIAMENTO.find((financiamento) => financiamento.PARCELA == ID).TAXA
        : 0;
    }
    return value;
  }

  renderOptions() {
    const dataVencimento = this.props.coban.dataVencimento ? parseInt(this.props.coban.dataVencimento) : '';
    const arrayDias = [];

    for (var i = -1; i < 30; i++) {
      arrayDias[i + 1] = <option value={i + 1}>{i + 1}</option>;
    }

    dataVencimento &&
      (arrayDias[dataVencimento] = (
        <option selected value={dataVencimento}>
          {' '}
          {dataVencimento}{' '}
        </option>
      ));

    return arrayDias;
  }

  clickButton() {
    const ID_COBAN = this.props.coban.coban.FINANCIAMENTO.find((i) => i.ID_COBAN);
    const PARCELAS = this.state.dadosFinanciamento.map((i) => {
      return { PARCELA: i.PARCELA, VALOR: i.TAXA };
    });

    this.props.updateTaxes(this.state.dadosTaxas);
    this.props.updateFinanciamento(this.state.dadosFinanciamento, PARCELAS, ID_COBAN);
    this.props.alteraVencimentoEmprestimo(
      {
        DIA_VENCIMENTO: this.state.dataVencimento,
        ID_COBAN: this.props.dados.ID,
      },
      (res) => {
        const { retorno } = res;

        retorno === 'sucesso' ? this.setState({ sucesso: true }) : this.setState({ sucesso: false });

        this.setState({
          mensagem: 'Alterações realizadas com sucesso!',
          modalMensagem: true,
        });
      },
    );
  }

  changeDay(event) {
    this.setState({ dataVencimento: event.target.value });
  }

  percentFormatter(value) {
    if (!Number(value)) return '0,00 %';
    const amount = new Intl.NumberFormat('pt-br', {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value / 10000);

    return `${amount}`;
  }

  render() {
    const { modal, toggle, coban } = this.props;
    const NOME = this.props.dados && this.props.dados.NOME ? this.props.dados.NOME : null;
    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
        autoFocus={false}
        onKeyDown={(e) => e.key === 'Enter' && this.clickButton()}
      >
        <ModalMensagem
          modal={this.state.modalMensagem}
          toggle={() => this.setState({ modalMensagem: !this.state.modalMensagem })}
          sucesso={this.state.sucesso}
          mensagem={this.state.mensagem}
        ></ModalMensagem>

        <ModalHeader toggle={toggle}>
          <h2>
            <b>Definir Taxas Conta Digital Para {NOME}</b>
          </h2>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <div>
              {/* <h2>
                <b>Recorrentes</b>
                <br />
              </h2> */}
              {/* <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>Manuntenção de Conta </h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('3')}
                        name="3"
                        placeholder={'R$ ' + formatMoney(this.getValue('3'))}
                        onChangeText={(event) => this.onChange(event, '3')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup> */}
            </div>
            <div>
              <h2>
                <b>Transacionais</b>
                <br />
              </h2>
              {/* <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>TED</h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('3')}
                        name="3"
                        placeholder={'R$ ' + formatMoney(this.getValue('3'))}
                        onChangeText={(event) => this.onChange(event, '3')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>PIX </h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('3')}
                        name="3"
                        placeholder={'R$ ' + formatMoney(this.getValue('3'))}
                        onChangeText={(event) => this.onChange(event, '3')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>Emissão de Boleto</h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('3')}
                        name="3"
                        placeholder={'R$ ' + formatMoney(this.getValue('3'))}
                        onChangeText={(event) => this.onChange(event, '3')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>Pagemento de Boletos</h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('3')}
                        name="3"
                        placeholder={'R$ ' + formatMoney(this.getValue('3'))}
                        onChangeText={(event) => this.onChange(event, '3')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup> */}

              <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>Saque Banco 24h </h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        autoFocus
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('24')}
                        name="24"
                        placeholder={'R$ ' + formatMoney(this.getValue('24'))}
                        onChangeText={(event) => this.onChange(event, '24')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup>
            </div>

            <div>
              {/* <h2>
                <b>Evento</b>
                <br />
              </h2> */}
              {/* <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>Abertura de Conta Digital </h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('3')}
                        name="3"
                        placeholder={'R$ ' + formatMoney(this.getValue('3'))}
                        onChangeText={(event) => this.onChange(event, '3')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>Solicitação de Cartão </h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('3')}
                        name="3"
                        placeholder={'R$ ' + formatMoney(this.getValue('3'))}
                        onChangeText={(event) => this.onChange(event, '3')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row xs="2" className="spaceDivs">
                  <InputGroupAddon className="inputLabel">
                    <Col className="container">
                      <h6 style={styles.textStyle}>Solicitação 2ª Via de Cartão </h6>
                    </Col>
                    <Col className="container">
                      <TextInputMask
                        kind={'money'}
                        className="myInput"
                        value={this.getValue('3')}
                        name="3"
                        placeholder={'R$ ' + formatMoney(this.getValue('3'))}
                        onChangeText={(event) => this.onChange(event, '3')}
                      />
                    </Col>
                  </InputGroupAddon>
                </Row>
              </FormGroup> */}
            </div>
          </Form>
        </ModalBody>
        <ModalFooter style={{ display: 'flex', alignItems: 'baseline', paddingTop: '0' }}>
          <Button active size="sm" block onClick={() => this.clickButton()}>
            Salvar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const styles = {
  inteiro: { width: '100%' },
  meio: { height: '50%' },
  inputStyle: { border: '1px solid rgb(206, 212, 218)' },
};

const mapStateToProps = ({ user, coban, config }) => {
  return { user, coban, config };
};

export default connect(mapStateToProps, {
  addSTATE,
  updateTaxes,
  updateFinanciamento,
  getTaxaConfigs,
  alteraVencimentoEmprestimo,
})(ModalTaxasDigital);
