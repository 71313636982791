/* eslint-disable */
import React from 'react';
import { Badge } from 'reactstrap';

export default function addStatusStyle(status) {
  switch (status) {
    case 'CANCELADA':
      return (
        <h4>
          <Badge color="danger">{status}</Badge>
        </h4>
      );
    case 'EFETIVADA':
      return (
        <h4>
          <Badge color="success">{status}</Badge>
        </h4>
      );
  }
}
