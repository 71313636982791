import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';
import Cards from '../Cards/Cards';
/* eslint-disable */
class Header extends React.Component {
  render() {
    return (
      <>
        <div
          className={`header ${
            localStorage.getItem('nivelAcesso') != 6 ? 'bg-gradient-warning' : 'bg-gradient-warning-taksim'
          } pb-8 pt-5 pt-md-7`}
        ></div>

        <Container fluid>
          <div className="header-body" style={{ transform: 'translateY(-67px) ' }}>
            {window.location.pathname == '/admin/dashboard' && <Cards style={{ marginTop: '10px' }}></Cards>}
          </div>
        </Container>
      </>
    );
  }
}
export default Header;
