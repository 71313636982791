/* eslint-disable */
import { Badge } from 'reactstrap';
import React from 'react';

export function setColor(status) {
  switch (status) {
    case 'VENCIDO':
      return <Badge color="danger">{status}</Badge>;
    case 'PAGO':
      return <Badge color="success">{status}</Badge>;
    case 'A VENCER':
      return <Badge color="info">{status}</Badge>;
  }
}
