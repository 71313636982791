import styled from 'styled-components';
import { Label, Input, FormText } from 'reactstrap';

export const Search = styled.div`
  width: ${(props) => props.width || '100%'};
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5vw;

  div {
    padding-right: 1vw;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #D4D4D4
`

export const InputSelectAmountResults = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const InputSelectAmountResultsLabel = styled(Label)`
  width: 12vw;
  margin-left: 10px;
`;

export const InputSelect = styled(Input)`
  max-width: 80px;
  margin-right: 0px;
  margin-top: 0px;
`;

export const FormTextInput = styled(FormText)`
  color: red !important;
`;
