import React, { useState, useEffect, useRef } from 'react';
import * as S from './SendNotification.styles';
import Accordion from '../../../components/Accordion/index';
import { useForm } from 'react-hook-form';
import Button from '../../../components/Buttons/Button';
import { ImPlus } from 'react-icons/im';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '../../../components/TextField/index';
import Modal from './modal/ConfirmationSend';
import { searchCpfSendMensagem, topicMensagem } from '../../../store/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import SelectOptions from '../../../components/SelectOptions/SelectOptions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import { Delete, Send } from '../../../assets/icons/index';
import { debounce } from 'lodash';

const SendNotification = () => {
  const [expanded, setExpanded] = useState('panel1');
  const [errorSearch, setErrorSearch] = useState(false);
  const [errorSearchDuplicate, setErrorSearchDuplicate] = useState(false);
  const [errorMinText, setErrorMinText] = useState(false);
  const [clickBuscaCpf, setClickBuscaCpf] = useState(false);
  const [clickBuscaPerfil, setClickBuscaPerfil] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dadosEnvio, setDadosEnvio] = useState();
  const [idTopic, setIdTopic] = useState('');
  const [multipleClient, setMultipleClient] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  const previousController = useRef();
  const autoC = useRef(null);
  const dispatch = useDispatch();

  const { handleSubmit, control, reset } = useForm({});
  const { handleSubmit: submitConfirm, control: controlConfirm, reset: resetConfirm } = useForm({});

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setClickBuscaCpf(false);
    setClickBuscaPerfil(false);
    reset({
      CPF: '',
      PERFIS: 0,
    });
    resetConfirm({
      TITULO: '',
      MENSAGEM: '',
    });
    setMultipleClient([]);
    const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
  };

  const resetInputs = () => {
    setClickBuscaCpf(false);
    setClickBuscaPerfil(false);
    resetConfirm({
      TITULO: '',
      MENSAGEM: '',
    });
    reset({
      CPF: '',
      PERFIS: 0,
    });
    setMultipleClient([]);
    const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
  };

  useEffect(() => {
    dispatch(topicMensagem());
  }, [modalOpen]);

  const { loadingSendMensagem, loadingSendMensagemPerfil, topicosMensagem } = useSelector(
    (state) => state.notifications,
  );

  const filterNotification = (data) => {
    const duplicateClient = multipleClient.filter((e) => e.title === selectedValue.title).length > 0;

    if (selectedValue === undefined) {
      setErrorSearch(true);
    } else if (duplicateClient) {
      setErrorSearchDuplicate(true);
    } else {
      setMultipleClient([...multipleClient, selectedValue]);
      setClickBuscaCpf(true);
      setErrorSearch(false);
      setErrorSearchDuplicate(false);
    }
  };

  const filterMultipleNotification = (data) => {
    setIdTopic(data);
    setClickBuscaPerfil(true);
  };

  const sendMensagem = (data) => {
    const array = [];
    multipleClient.map((val) => {
      array.push(val.CPFCNPJ);
      return array;
    });

    setDadosEnvio({
      ...data,
      USUARIOS: array,
    });
    setModalOpen(true);
  };

  const sendMensagemTopic = (data) => {
    setDadosEnvio({ ...data, TOPICO: idTopic });
    setModalOpen(true);
  };

  const delayedGetData = debounce(
    (eventData) =>
      dispatch(
        searchCpfSendMensagem(eventData, (res) => {
          const updatedOptions = res.map((p) => {
            return { title: p.NOME + ' - ' + p.CPFCNPJ, CPFCNPJ: p.CPFCNPJ, NOME: p.NOME, PERFIL: p.NOME_COBAN };
          });

          setOptions(updatedOptions);
        }),
      ),
    2500,
  );

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const search = { SEARCH: searchTerm, LIMIT: 50, PAGE: 1 };

    searchTerm.length >= 3 && delayedGetData(search);
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
    } else {
      setOptions([]);
    }
  };

  const deleteClient = (val) => {
    const newMultipleClient = multipleClient.filter((e) => e.CPFCNPJ !== val);
    setMultipleClient(newMultipleClient);
  };

  return (
    <S.Container>
      <Accordion expanded={expanded} handleChange={handleChange} open={'panel1'} title={'Envio'}>
        <S.Form onSubmit={handleSubmit(filterNotification)}>
          <div style={{ width: '80%' }}>
            <Autocomplete
              ref={autoC}
              options={options}
              onInputChange={onInputChange}
              getOptionLabel={(option) => option.title}
              noOptionsText={'Sem opções'}
              style={{ width: '90%' }}
              onChange={(event, newValue) => {
                setSelectedValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Nome / CPF-CNPJ" variant="outlined" />}
            />
            {errorSearch && (
              <FormHelperText sx={{ color: 'red' }}>Digite o nome do cliente ou CPF/CNPJ acima</FormHelperText>
            )}
            {errorSearchDuplicate && (
              <FormHelperText sx={{ color: 'red' }}>Cliente adicionado na listagem abaixo</FormHelperText>
            )}
          </div>
          <Button
            backgroundColor={'#0D488C'}
            width="12.153vw"
            height="auto"
            hoverBGColor={'#0D488C'}
            hoverColor={'#F8F8F8'}
            color={'#F8F8F8'}
            type="submit"
          >
            <ImPlus className="default-svg" size={'1.181vw'} />
            <span>Adicionar</span>
          </Button>
        </S.Form>
        {clickBuscaCpf &&
          (loadingSendMensagem === true ? (
            <S.Detail>
              <Stack spacing={1} sx={{ width: '100%' }}>
                <Skeleton variant="rectangular" width={'100%'} height={20} />
                <Skeleton variant="rectangular" width={'100%'} height={20} />
              </Stack>
              <Stack spacing={1} sx={{ width: '80%', pt: 2, pl: '10%' }}>
                <Skeleton variant="rectangular" width={'100%'} height={200} />
                <Skeleton variant="rectangular" width={'100%'} height={20} />
              </Stack>
            </S.Detail>
          ) : multipleClient.length > 0 ? (
            <>
              <S.Detail>
                {multipleClient.map((val) => (
                  <Tooltip
                    arrow={true}
                    title={
                      <div>
                        <div>
                          <S.Infos>{'Nome: ' + val.NOME}</S.Infos>
                          <S.Infos>{'CPF/CNPJ: ' + val.CPFCNPJ}</S.Infos>
                          <S.Infos>{'Perfil: ' + val.PERFIL}</S.Infos>
                        </div>
                      </div>
                    }
                    disableInteractive
                  >
                    <S.ButtonMui>
                      <span>{val.NOME}</span>
                      <Delete onClick={(e) => deleteClient(val.CPFCNPJ)} />
                    </S.ButtonMui>
                  </Tooltip>
                ))}
              </S.Detail>
              <S.MensagemContainer onSubmit={submitConfirm(sendMensagem)}>
                <S.TitleContainerMensagem>Notificação</S.TitleContainerMensagem>
                <S.InputMensagem>
                  <Input
                    fullWidth
                    name="TITULO"
                    title="Título"
                    multiline
                    variant="filled"
                    control={controlConfirm}
                    required={true}
                    inputProps={{ maxLength: 100 }}
                  />
                </S.InputMensagem>
                <S.InputMensagem>
                  <Input
                    fullWidth
                    name="MENSAGEM"
                    title="Texto"
                    multiline
                    variant="filled"
                    control={controlConfirm}
                    required={true}
                    inputProps={{ minLength: 5, maxLength: 255 }}
                    onInput={(e) => {
                      const val = e.target.value;
                      val.length >= 5 ? setErrorMinText(false) : setErrorMinText(true);
                    }}
                  />
                  {errorMinText && (
                    <FormHelperText sx={{ color: 'red' }}>O texto deve ter no minimo 5 caracters</FormHelperText>
                  )}
                </S.InputMensagem>
                <Button
                  backgroundColor={'#219326'}
                  width="12.153vw"
                  height="3.053vw"
                  hoverBGColor={'#219326'}
                  hoverColor={'#F8F8F8'}
                  color={'#F8F8F8'}
                  type="submit"
                >
                  <Send />
                  <span>Enviar</span>
                </Button>
              </S.MensagemContainer>
            </>
          ) : (
            <>
              <S.Detail>{'Nenhum usuario encontrado'}</S.Detail>
            </>
          ))}
      </Accordion>
      <Accordion expanded={expanded} handleChange={handleChange} open={'panel2'} title={'Envio Grupo'}>
        <div style={{ display: 'flex', justifyContent: 'space-around', padding: '0.6rem 0' }}>
          <SelectOptions
            control={control}
            options={topicosMensagem}
            id={'topico'}
            name={'TOPICO'}
            width={'22.042vw'}
            label={'Grupo'}
            initialValue={0}
            initialName={'Todos'}
            required={true}
            onClick={(e) => filterMultipleNotification(e)}
          />
        </div>
        {clickBuscaPerfil &&
          (loadingSendMensagemPerfil === true ? (
            <S.Detail>
              <Stack spacing={1} sx={{ width: '100%' }}>
                <Skeleton variant="rectangular" width={'100%'} height={20} />
                <Skeleton variant="rectangular" width={'100%'} height={20} />
              </Stack>
              <Stack spacing={1} sx={{ width: '80%', pt: 2, pl: '10%' }}>
                <Skeleton variant="rectangular" width={'100%'} height={200} />
                <Skeleton variant="rectangular" width={'100%'} height={20} />
              </Stack>
            </S.Detail>
          ) : (
            <>
              <S.MensagemContainer onSubmit={submitConfirm(sendMensagemTopic)}>
                <S.TitleContainerMensagem>Notificação</S.TitleContainerMensagem>
                <S.InputMensagem>
                  <Input
                    fullWidth
                    name="TITULO"
                    title="Título"
                    multiline
                    variant="filled"
                    control={controlConfirm}
                    required={true}
                    inputProps={{ maxLength: 100 }}
                  />
                </S.InputMensagem>
                <S.InputMensagem>
                  <Input
                    fullWidth
                    name="MENSAGEM"
                    title="Texto"
                    multiline
                    variant="filled"
                    control={controlConfirm}
                    required={true}
                    inputProps={{ minLength: 5, maxLength: 255 }}
                    onInput={(e) => {
                      const val = e.target.value;
                      val.length >= 5 ? setErrorMinText(false) : setErrorMinText(true);
                    }}
                  />
                  {errorMinText && (
                    <FormHelperText sx={{ color: 'red' }}>O texto deve ter no minimo 5 caracters</FormHelperText>
                  )}
                </S.InputMensagem>
                <Button
                  backgroundColor={'#219326'}
                  width="12.153vw"
                  height="3.053vw"
                  hoverBGColor={'#219326'}
                  hoverColor={'#F8F8F8'}
                  color={'#F8F8F8'}
                  type="submit"
                >
                  <Send />
                  <span>Enviar</span>
                </Button>
              </S.MensagemContainer>
            </>
          ))}
      </Accordion>
      {modalOpen && (
        <Modal
          openModal={modalOpen}
          closeModal={(e) => {
            setModalOpen(false);
            resetInputs();
          }}
          backClose={(e) => {
            setModalOpen(false);
          }}
          Data={dadosEnvio}
        />
      )}
    </S.Container>
  );
};

export default SendNotification;
