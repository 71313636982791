import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { consultaBoleto } from '../../store/transactions/actions.js';
import formatCNPJ from '../../utils/formatCNPJ';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL';

/* eslint-disable */
const ModalInformationsBillet = (props) => {
  const { modal, toggle, title, data } = props;
  const {
    ID_TRANSACAO,
    NOME,
    DATA,
    HORA,
    STATUS,
    VALOR,
    VENDA_DIGITADA,
    NOME_BENEFICIARIO,
    TAXA,
    CPFCNPJ_BENEFICIARIO,
    VENCIMENTO,
    CPFCNPJ,
    LINHADIGITAVEL,
    CODSTATUS,
    IDPAGAMENTO,
    DATA_LIQUIDACAO,
  } = data;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>{title}</h3>
      </ModalHeader>
      <ModalBody
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', wordBreak: 'break-word' }}
      >
        <Row style={styles.metade}>
          <Col>
            <h5>Código da Transação</h5>
            <p>{ID_TRANSACAO}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Status Transação</h5>
            {CODSTATUS == 1 && <Badge color="info">AGUARDANDO</Badge>}
            {CODSTATUS == 2 && <Badge color="success">APROVADO</Badge>}
            {CODSTATUS == 3 && <Badge color="danger">CANCELADO</Badge>}
            {CODSTATUS == 4 && <Badge color="secondary">VENCIDO</Badge>}
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Data/Hora</h5>
            <p>
              {moment(DATA).isValid() ? moment(DATA).format('DD/MM/YYYY') : DATA} {HORA}
            </p>
          </Col>
        </Row>
        {CODSTATUS == 2 && (
          <Row style={styles.metade}>
            <Col>
              <h5>Data/Hora Liquidação:</h5>
              <p>
                {moment(DATA_LIQUIDACAO).isValid()
                  ? moment(DATA_LIQUIDACAO).format('DD/MM/YYYY HH:MM:SS')
                  : DATA_LIQUIDACAO}
              </p>
            </Col>
          </Row>
        )}

        <Row style={styles.metade}>
          <Col>
            <h5>Vencimento</h5>
            <p>{moment(VENCIMENTO).isValid() ? moment(VENCIMENTO).format('DD/MM/YYYY') : VENCIMENTO}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Valor</h5>
            <p>R$ {formatMoney(VALOR)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Taxa</h5>
            <p>R$ {formatMoney(TAXA)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Venda Digitada</h5>
            <p>R$ {formatMoney(VENDA_DIGITADA)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Nome</h5>
            <p>{NOME}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>CPF/CNPJ</h5>
            <p>{formatCPF(CPFCNPJ)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Nome Beneficiário</h5>
            <p>{NOME_BENEFICIARIO}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>CPF/CNPJ Beneficiário</h5>
            <p>{formatCNPJ(CPFCNPJ_BENEFICIARIO)}</p>
          </Col>
        </Row>
        <Row style={styles.cheio}>
          <Col>
            <h5>ID do Boleto</h5>
            <p>{IDPAGAMENTO}</p>
          </Col>
        </Row>
        <Row style={styles.cheio}>
          <Col>
            <h5>Código de barras</h5>
            <p>{LINHADIGITAVEL}</p>
          </Col>
        </Row>
        {/* <Row style={styles.cheio}>
                    <Col>
                        {CODSTATUS == 2 && 
                            <Button block size="sm" onClick={()=>{props.consultaBoleto(IDPAGAMENTO)}} color={"info"}>Conciliar</Button>
                        || CODSTATUS == 1 && 
                            <Button block size="sm" onClick={()=>{props.consultaBoleto(IDPAGAMENTO)}} color={"info"}>Conciliar</Button>}
                    </Col>
                </Row> */}
      </ModalBody>
    </Modal>
  );
};

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { consultaBoleto })(ModalInformationsBillet);
