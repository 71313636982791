import { showAlert } from './types';

export const showAlertAction = (params, callback) => {
  return async (dispatch) => {
    dispatch({
      type: showAlert,
      payload: params,
    });
  };
};
