import React from 'react';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import StatusContainerTed from '../../components/StatusContainer/StatusContainerTed.js';
import formatCNPJ from '../../utils/formatCNPJ';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL';
import valueEmpty from '../../utils/valueEmpty.js';

/* eslint-disable */

const ModalInfoLoan = (props) => {
  const { modal, toggle, title, dadosModal } = props;
  const {
    CPFCNPJ,
    DATA,
    DESCRICAO,
    DESTINATARIO,
    CPFCNPJ_DESTINATARIO,
    ID,
    REMETENTE,
    STATUSTRANSACAO,
    VALOR,
    VALORDESCONTADO,
    VALOR_DESTINATARIO,
  } = dadosModal;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>{title}</h3>
      </ModalHeader>
      <ModalBody
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', wordBreak: 'break-word' }}
      >
        <Row style={styles.metade}>
          <Col>
            <h5>Código da transação:</h5>
            <p>{ID}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Descrição:</h5>
            <p>{DESCRICAO}</p>
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Data / Hora</h5>
            <p>{DATA}</p>
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Status</h5>
            {STATUSTRANSACAO == 1 && <StatusContainerTed code={STATUSTRANSACAO} description={'Aguardando'} />}
            {STATUSTRANSACAO == 2 && <StatusContainerTed code={STATUSTRANSACAO} description={'Aprovado'} />}
            {STATUSTRANSACAO == 3 && <StatusContainerTed code={STATUSTRANSACAO} description={'Cancelado'} />}
            {STATUSTRANSACAO == 4 && <StatusContainerTed code={STATUSTRANSACAO} description={'Vencido'} />}
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Valor:</h5>
            <p>R$ {formatMoney(VALOR_DESTINATARIO)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Valor Total:</h5>
            <p>R$ {formatMoney(VALOR)}</p>
          </Col>
        </Row>

        <Row style={styles.metade}>
          <Col>
            <h5>Taxa:</h5>
            <p>R$ {formatMoney(VALORDESCONTADO)}</p>
          </Col>
        </Row>
        <Row style={styles.cheio}>
          <Col>
            <h4>Origem</h4>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Nome:</h5>
            <p>{REMETENTE}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>CPF/CNPJ:</h5>
            <p>{CPFCNPJ && CPFCNPJ.length === 11 ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)}</p>
          </Col>
        </Row>
        <Row style={styles.cheio}>
          <Col>
            <h4>Destino</h4>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Nome:</h5>
            <p>{DESTINATARIO}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>CPF/CNPJ:</h5>
            <p>
              {valueEmpty(
                CPFCNPJ_DESTINATARIO && CPFCNPJ_DESTINATARIO.length === 11
                  ? formatCPF(CPFCNPJ_DESTINATARIO)
                  : formatCNPJ(CPFCNPJ_DESTINATARIO),
              )}
            </p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(ModalInfoLoan);
