import React from 'react';
import { connect } from 'react-redux';
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import formatCPF from '../../../utils/formatCPF';
import { formatMoney } from '../../../utils/formatMoneyBRL';

/* eslint-disable */
const ModalInformationsPayments = (props) => {
  const { modal, toggle, title, dataInfo } = props;

  const {
    CPFCNPJ,
    DATA,
    DESCRICAO,
    ID,
    NOME,
    SPREAD,
    STATUSTRANSACAO,
    TAXA,
    VALOR,
    CODIGO_BOLETO,
    MODPAGAMENTODESCRICAO,
    VALOR_PAGO,
  } = dataInfo;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>{title}</h3>
      </ModalHeader>
      <ModalBody
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          wordBreak: 'break-word',
        }}
      >
        <Row style={styles.metade}>
          <Col>
            <h5>Código da Transação</h5>
            <p>{ID}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Status Transação</h5>
            {STATUSTRANSACAO == 1 && <Badge color="info">AGUARDANDO</Badge>}
            {STATUSTRANSACAO == 2 && <Badge color="success">APROVADO</Badge>}
            {STATUSTRANSACAO == 3 && <Badge color="danger">CANCELADO</Badge>}
            {STATUSTRANSACAO == 4 && <Badge color="secondary">VENCIDO</Badge>}
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Nome</h5>
            <p>{NOME}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>CPF/CNPJ</h5>
            <p>{formatCPF(CPFCNPJ)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Data / Hora:</h5>
            <p>{DATA}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Valor do Boleto</h5>
            <p>R$ {formatMoney(VALOR)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Taxa</h5>
            <p>R$ {formatMoney(SPREAD)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Tipo do Pagamento</h5>
            <p>{MODPAGAMENTODESCRICAO}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Valor Pago</h5>
            <p>R$ {formatMoney(VALOR_PAGO)}</p>
          </Col>
        </Row>
        <Row style={styles.cheio}>
          <Col>
            <h5>Descrição do Pagamento</h5>
            <p>{DESCRICAO}</p>
          </Col>
        </Row>

        {STATUSTRANSACAO === '2' ? (
          <Row style={styles.cheio}>
            <Col>
              <h5>Código do Boleto</h5>
              <p>{CODIGO_BOLETO}</p>
            </Col>
          </Row>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(ModalInformationsPayments);
