import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { addSTATE } from '../../store/globals.js';

import { getWithdrawalTransactions } from '../../store/transactions/actions.js';
import { removeSpecialCharacters } from '../../utils/functions.js';
import GenericButton from '../Buttons/Button.js';
import Icon from '../Icons/IconsButton.js';
// Transferências
class FilterWithdrawals extends React.Component {
  state = {
    limit: 10,
    page: 1,
    search: '',
    status: 0,
    dataFinal: moment().format('YYYY-MM-DD'),
    tipoTransacao: '',
  };

  componentDidMount() {
    this.props.getWithdrawalTransactions(this.state);
  }

  onChange = ({ target }) => {
    target.name === 'search'
      ? this.setState({ [target.name]: removeSpecialCharacters(target.value) })
      : this.setState({ [target.name]: target.value });
  };

  filtrar = async () => {
    this.setState({ page: 1 });
    this.props.addSTATE('TRANSACTION_RESPONSE', '', {
      filtro: this.state,
    });
    this.props.getWithdrawalTransactions({ ...this.state });
    this.props.reset();
  };

  render() {
    return (
      <Form className="form" onSubmit={(e) => e.preventDefault()}>
        <FormGroup className="mt-3">
          <Row xs="12" style={{ padding: '15px' }}>
            <Col xs="4" style={{ minWidth: '380px', marginLeft: '-15px' }}>
              <Label size="sm">Pesquisar</Label>
              <Input
                onChange={this.onChange}
                autoFocus
                type="text"
                name="search"
                id="search"
                size="sm"
                placeholder="Nome ou CPF/CNPJ"
              />
            </Col>

            <Col
              xs="1"
              style={{
                minWidth: '130px',
                paddingLeft: '0',
                paddingRight: '10px',
              }}
            >
              <Label size="sm">Tipo Pagamento</Label>
              <Input onChange={this.onChange} type="select" name="tipoTransacao" id="tipoTransacao" size="sm">
                <option value="">Todas</option>
                <option value={1}>Débito</option>
                <option value={2}>Crédito</option>
              </Input>
            </Col>

            <Col
              xs="1"
              style={{
                minWidth: '175px',
                paddingLeft: '0',
                paddingRight: '10px',
              }}
            >
              <Label size="sm">Status</Label>
              <Input onChange={this.onChange} type="select" name="status" id="status" size="sm">
                <option value="">Todas</option>
                <option value={1}>Em Processamento</option>
                <option value={2}>Aprovado</option>
                <option value={3}>Cancelado</option>
                <option value={5}>Em Analise</option>
                <option value={6}>Pendente</option>
                <option value={7}>Enviado Para Estorno</option>
                <option value={8}>Estorno Realizado</option>
              </Input>
            </Col>
            <Col
              xs="2"
              style={{
                minWidth: '163px',
                paddingLeft: '0',
                paddingRight: '10px',
              }}
            >
              <Label size="sm">Data Inicial</Label>
              <Input name="dataInicial" type="date" size="sm" max="9999-12-31" onChange={this.onChange} />
            </Col>
            <Col
              xs="2"
              style={{
                minWidth: '163px',
                paddingLeft: '0',
                paddingRight: '10px',
              }}
            >
              <Label size="sm">Data Final</Label>
              <Input name="dataFinal" type="date" size="sm" max="9999-12-31" onChange={this.onChange} />
            </Col>
            <Col
              xs="1"
              style={{
                paddingRight: '10px',
                alignSelf: 'flex-end',
                paddingLeft: '0',
              }}
            >
              <GenericButton
                marginTop={'25px !important'}
                width={'120px'}
                heigth={'35px'}
                onClick={this.filtrar}
                color="primary"
                size="sm"
                type="submit"
              >
                <Icon className="fas fa-search"></Icon>Buscar
              </GenericButton>
            </Col>
          </Row>
          <div className="mt-2" style={{ ...styles.form, justifyContent: 'flex-start' }}>
            <Input
              onChange={this.onChange}
              type="select"
              name="limit"
              id="limit"
              style={{ maxWidth: 80, marginRight: 10 }}
              size="sm"
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </Input>
            <Label style={{ width: 'inherent' }}>Resultados por página</Label>
          </div>
        </FormGroup>
      </Form>
    );
  }
}

const styles = {
  form: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
};

const mapStateToProps = ({ auth, transactions }) => {
  return { auth, transactions };
};

export default connect(mapStateToProps, {
  getWithdrawalTransactions,
  addSTATE,
})(FilterWithdrawals);
