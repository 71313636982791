import React, { useEffect, useState } from 'react';
import TextInputMask from 'react-masked-text';
import { connect } from 'react-redux';
import { Button, Col, Input, InputGroupAddon, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { approval, buscaBancos, getUsers, newAccount, updateAccountUser } from '../../../store/user/actions';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';

/* eslint-disable */

const ModalAlteraConta = (props) => {
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const { modal, toggle, data, option } = props;

  const { AGENCIA, BANCO, CODBANCO, CONTA, CPFCNPJ, CPFCNPJ_USUARIO, TIPOCONTA, ID, DIGITO } = data ? data : {};
  const [conta, setConta] = useState('');
  const [agencia, setAgencia] = useState('');
  const [banco, setBanco] = useState('');
  const [tipoConta, setTipoConta] = useState('');
  const [digito, setDigito] = useState('');

  const [contaError, setContaError] = useState(false);
  const [agenciaError, setAgenciaError] = useState(false);
  const [bancoError, setBancoError] = useState(false);
  const [tipoContaError, setTipoContaError] = useState(false);
  const [digitoError, setDigitoError] = useState(false);

  const [mensagem, setMensagem] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  const [todosEstados, setTodosEstados] = useState({});

  useEffect(() => {
    props.buscaBancos();
  }, []);

  useEffect(() => {
    async function loadStates() {
      setContaError(false);
      setAgenciaError(false);
      setBancoError(false);
      setTipoContaError(false);
      setDigitoError(false);

      setDigito(DIGITO);
      setBanco(CODBANCO);
      setTipoConta(TIPOCONTA);
      setAgencia(AGENCIA);
      setConta(CONTA);
    }

    if (modal) {
      loadStates();
    }
  }, [modal]);

  function alteraToggleConfirm() {
    setToggleConfirm(!toggleConfirm);
  }

  function openModalMensagem(res) {
    const { mensagem, retorno } = res;

    retorno == 'error' ? setSucesso(false) : setSucesso(true);

    setMensagem(mensagem);
    setModalMensagem(true);
  }

  function validate() {
    let error = false;

    if (conta == null || conta == '') {
      error = true;
      setContaError(true);
    }

    if (agencia == null || agencia == '') {
      error = true;
      setAgenciaError(true);
    }

    if (banco == null || banco == '') {
      error = true;
      setBancoError(true);
    }

    if (tipoConta == null || tipoConta == '') {
      error = true;
      setTipoContaError(true);
    }

    if (digito == null || digito == '') {
      error = true;
      setDigitoError(true);
    }

    if (error) return;

    const obj = {
      CONTA: conta != null && conta != '' ? conta : CONTA,
      AGENCIA: agencia != null && agencia != '' ? agencia : AGENCIA,
      BANCO: banco != null && banco != '' ? banco : BANCO,
      TIPOCONTA: tipoConta != null && tipoConta != '' ? tipoConta : TIPOCONTA,
      CPFCNPJ_USUARIO: CPFCNPJ ? CPFCNPJ : props.user.user.CPFCNPJ,
      ID: ID != null && ID != '' ? ID : null,
      DIGITO: digito != null && digito != '' ? digito : DIGITO,
    };

    if (option != 2) {
      props.newAccount(obj, (res) => {
        openModalMensagem(res);
      });
    }

    if (option == 2) {
      props.updateAccountUser(obj, (res) => {
        openModalMensagem(res);
      });
    }
  }

  function changeState(e, name) {
    let valor = typeof e === 'string' ? (valor = e) : (valor = e.target.value);

    switch (name) {
      case 'conta':
        setContaError(false);
        setConta(valor);
        break;
      case 'agencia':
        setAgenciaError(false);
        setAgencia(valor);
        break;
      case 'banco':
        setBancoError(false);
        setBanco(valor);
        break;
      case 'tipoConta':
        setTipoContaError(false);
        setTipoConta(valor);
        break;
      case 'digito':
        setDigitoError(false);
        setDigito(valor);
        break;
      default:
        break;
    }
  }

  //Será acionada quando fechar o modal para colocar os estados com o valor vazio
  function clearStates() {
    setDigito('');
    setBanco('');
    setTipoConta('');
    setAgencia('');
    setConta('');
  }

  const { banks } = props.user;

  return (
    <>
      <Modal size="sm" isOpen={modal}>
        <ModalHeader
          toggle={() => {
            toggle();
            clearStates();
          }}
        >
          <h2>{option == 2 ? 'Alteração de Conta' : 'Adição de Conta Bancária'}</h2>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="" style={{ marginBottom: '2%' }}>
              <h5>Banco: </h5>
              <InputGroupAddon addonType="prepend">
                <Input
                  type="select"
                  name="banco"
                  id="banco"
                  onChange={(event) => changeState(event, 'banco')}
                  value={banco}
                  style={bancoError ? { borderColor: 'red' } : {}}
                >
                  <option defaultValue selected value="">
                    Selecione...
                  </option>
                  {banks &&
                    banks.map((bank, index) => {
                      return (
                        <option key={bank.CODBANCO} value={bank.CODBANCO}>
                          {bank.CODBANCO} - {bank.DESCRICAO}
                        </option>
                      );
                    })}
                </Input>
                {bancoError && (
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      textAlign: 'center',
                    }}
                  >
                    Selecione o banco
                  </span>
                )}
              </InputGroupAddon>
            </Col>
          </Row>
          <Row xs="" style={{ marginBottom: '2%' }}>
            <Col>
              <h5>Agência e Dígito: </h5>
            </Col>
            <InputGroupAddon addonType="prepend">
              <Col>
                <Input
                  type="text"
                  name="agencia"
                  id="agencia"
                  onChange={(event) => changeState(event, 'agencia')}
                  value={agencia}
                  size="150"
                  maxLength="6"
                  style={agenciaError ? { borderColor: 'red' } : {}}
                />
                {agenciaError && (
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      textAlign: 'center',
                    }}
                  >
                    Digite a agência
                  </span>
                )}
              </Col>
            </InputGroupAddon>
          </Row>
          <Row xs="" style={{ marginBottom: '2%' }}>
            <Col>
              <h5>Conta: </h5>
            </Col>
            <InputGroupAddon addonType="prepend">
              <Col>
                <Input
                  type="text"
                  name="conta"
                  id="conta"
                  onChange={(event) => changeState(event, 'conta')}
                  value={conta}
                  size="150"
                  maxLength="9"
                  style={contaError ? { borderColor: 'red' } : {}}
                />
                {contaError && (
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    Digite a Conta
                  </span>
                )}
              </Col>
            </InputGroupAddon>
          </Row>
          <Row xs="" style={{ marginBottom: '2%' }}>
            <Col>
              <h5>Dígito: </h5>
            </Col>
            <InputGroupAddon addonType="prepend">
              <Col>
                <TextInputMask
                  kind={'custom'}
                  options={{ mask: '99' }}
                  name="digito"
                  id="digito"
                  onChangeText={(event) => changeState(event, 'digito')}
                  value={digito}
                  size="150"
                  style={styles.myInput}
                />
                {digitoError && (
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      textAlign: 'center',
                    }}
                  >
                    Digite a Dígito
                  </span>
                )}
              </Col>
            </InputGroupAddon>
          </Row>
          <Row>
            <Col xs="" style={{ marginBottom: '2%' }}>
              <h5>Tipo Conta: </h5>
              <InputGroupAddon addonType="prepend">
                <Input
                  type="select"
                  name="tipoConta"
                  id="tipoConta"
                  onChange={(event) => changeState(event, 'tipoConta')}
                  value={tipoConta}
                  style={tipoContaError ? { borderColor: 'red' } : {}}
                >
                  <option value="">Selecione...</option>
                  <option defaultValue value="CC">
                    Conta Corrente
                  </option>
                  <option value="PP">Conta Poupança</option>
                </Input>
                {tipoContaError && (
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      textAlign: 'center',
                    }}
                  >
                    Digite a agência
                  </span>
                )}
              </InputGroupAddon>
            </Col>
          </Row>
          <Row xs="">
            <Col>
              <Button
                color="success"
                size="sm"
                block
                style={{
                  width: '100%',
                  marginTop: '10%',
                  alignContent: 'flex-end',
                }}
                onClick={() => {
                  validate();
                }}
              >
                {option == 2 ? 'Alterar' : 'Adicionar'}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={() => {
          setModalMensagem(false);
        }}
      />
    </>
  );
};

const mapStateToProps = ({ user, accouting }) => {
  return { user, accouting };
};
const styles = {
  myInput: {
    marginBottom: '10px',
    border: '1px solid #dadada',
    borderRadius: '0.25rem',
    color: '#8898aa',
    width: '100%',
    padding: '0.625rem 0.75rem',
  },
};

export default connect(mapStateToProps, {
  approval,
  getUsers,
  buscaBancos,
  newAccount,
  updateAccountUser,
})(ModalAlteraConta);
