import styled from 'styled-components';
import Select from '@material-ui/core/Select';

export const ContainerStyled = styled.div`
  width: ${(props) => props.width || 'auto'};
  margin: ${(props) => props.margin || '0'};

  .juIcTV {
    height: auto;
  }

  .MuiInput-root {
    /* height: 3.333vw; */
  }

  .MuiFormControl-root {
    background-color: rgba(51, 51, 51, 0.05);
    width: ${(props) => props.width === 'full' && '100%'};
    /* height: 3.333vw; */
    border-radius: ${(props) => props.borderRadius};
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    padding-left: 1vw;
  }

  .MuiSelect-root.MuiSelect-select {
    padding-left: 0.7vw;
    padding-right: 0;
    min-width: 3vw;
  }
  .MuiSvgIcon-root.MuiSelect-icon {
    top: auto;
  }
`;

export const SelectStyled = styled(Select)`
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height};

  .MuiSelect-select.MuiSelect-select {
    padding: ${(props) => props.padding};
  }
`;
