import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const InputStatus = ({ handleChange, options, selects, inputName, name, key, error, multiple }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div>
      {multiple ? (
        <FormControl style={{ minWidth: 120, maxWidth: 180 }}>
          <InputLabel>{inputName}</InputLabel>
          <Select
            key={key}
            multiple
            value={selects}
            onChange={handleChange}
            name={name}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            error={error}
          >
            {options.map((name) => (
              <MenuItem key={key} value={name.ID}>
                <Checkbox checked={selects.indexOf(name.ID) > -1} />
                <ListItemText primary={name.DESCRICAO} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl style={{ minWidth: 120, maxWidth: 180 }}>
          <InputLabel>{inputName}</InputLabel>
          <Select
            key={key}
            value={selects}
            onChange={handleChange}
            name={name}
            input={<Input />}
            renderValue={(selected) => options.find((op) => op.ID === selected).DESCRICAO}
            MenuProps={MenuProps}
            error={error}
          >
            {options.map((name) => (
              <MenuItem key={key} value={name.ID}>
                <ListItemText primary={name.DESCRICAO} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default InputStatus;
