import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Card, CardBody, CardHeader, Col, Collapse } from 'reactstrap';
import { COLORS } from '../../../assets/EnvStyles';
import Table from '../../Table/Table';

const ModalScore = (props) => {
  const { avaliacoes, informacoes, score } = props.user;

  const [scoreInfos, setScoreInfos] = useState(false);
  const [avInfos, setCountAvInfos] = useState(false);
  //merge aq
  const { formattedScore, txtValue, txtDesc, colorScore } = formatScore(score);
  return (
    <div>
      <div>
        <div style={{ width: '100%', display: 'flex' }}>
          <Col>
            <div>
              <Doughnut
                width={'100%'}
                height={90}
                data={{
                  datasets: [
                    {
                      data: [formattedScore, 100 - formattedScore],
                      backgroundColor: [colorScore, '#525f7f'],
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  tooltips: {
                    callbacks: {
                      label: function (tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var currentValue = dataset.data[tooltipItem.index];
                        return currentValue.toFixed(2);
                      },
                    },
                  },
                }}
              />
            </div>

            <p name="scoreText" style={{ textAlign: 'center' }}>
              {txtValue}
            </p>
            <p name="scoreText" style={{ textAlign: 'center', color: colorScore }}>
              {txtDesc}
            </p>
          </Col>
          <Col md={4}>
            <h3>Nível de risco:</h3>
            <div style={styles.divCircle}>
              {' '}
              <div style={{ ...styles.circle, backgroundColor: COLORS.red }}></div>
              Muito Alto (90-100)
            </div>
            <div style={styles.divCircle}>
              {' '}
              <div style={{ ...styles.circle, backgroundColor: COLORS.orange }}></div>
              Alto (80-89)
            </div>
            <div style={styles.divCircle}>
              {' '}
              <div style={{ ...styles.circle, backgroundColor: COLORS.yellow }}></div>
              Médio (46-79)
            </div>
            <div style={styles.divCircle}>
              {' '}
              <div style={{ ...styles.circle, backgroundColor: COLORS.blue }}></div>
              Baixo (10-45)
            </div>
            <div style={styles.divCircle}>
              {' '}
              <div style={{ ...styles.circle, backgroundColor: COLORS.green }}></div>
              Muito baixo (0-9)
            </div>
          </Col>
        </div>
      </div>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => setScoreInfos(!scoreInfos)}>
          {' '}
          Informações{' '}
        </CardHeader>
        <Collapse isOpen={scoreInfos}>
          <CardBody>
            {informacoes && (
              <Table
                headers={[
                  {
                    info: 'Código',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Descrição',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Categoria',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Resultado',
                    style: { textAlign: 'left' },
                  },
                ]}
                data={informacoes.map((infos) => {
                  return {
                    data: [
                      {
                        info: infos.codigo,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: infos.descricao,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: infos.categoria,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: infos.resultado,
                        style: { textAlign: 'left' },
                      },
                    ],
                  };
                })}
              />
            )}
          </CardBody>
        </Collapse>
      </Card>

      <Card>
        <CardHeader style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => setCountAvInfos(!avInfos)}>
          {' '}
          Avaliações{' '}
        </CardHeader>
        <Collapse isOpen={avInfos}>
          <CardBody>
            {avaliacoes && (
              <Table
                headers={[
                  {
                    info: 'Score',
                    style: { textAlign: 'left' },
                  },
                  {
                    info: 'Relação',
                    style: { textAlign: 'left' },
                  },
                ]}
                data={avaliacoes.map((av) => {
                  return {
                    data: [
                      {
                        info: av.score,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: av.relacoes,
                        style: { textAlign: 'left' },
                      },
                    ],
                  };
                })}
              />
            )}
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

const formatScore = (score) => {
  var colorScore = 'white';
  var txtDesc,
    txtValue = '';
  var formattedScore = score ? parseFloat(score.toFixed(2)) : 0;
  txtValue = `${formattedScore}/100`;
  if (score) {
    if (score >= 90) {
      txtDesc = 'Muito Alto';
      colorScore = '#dc3545';
    } else if (score >= 80 && score <= 89) {
      txtDesc = 'Alto';
      colorScore = COLORS.orange;
    } else if (score >= 46 && score <= 79) {
      txtDesc = 'Médio';
      colorScore = COLORS.yellow;
    } else if (score >= 10 && score <= 45) {
      txtDesc = 'Baixo';
      colorScore = COLORS.blue;
    } else {
      txtDesc = 'Muito Baixo';
      colorScore = COLORS.green;
    }
  } else {
    txtDesc = 'Não informado';
    colorScore = 'gray';
  }

  return { formattedScore, txtDesc, txtValue, colorScore };
};

const styles = {
  circle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    color: 'black',
    marginRight: 5,
  },
  divCircle: { display: 'flex', alignItems: 'center' },
};
export default ModalScore;
