import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

/* eslint-disable */
const ButtonGroupCliente = (props) => {
  const [hoverButton, setHoverButton] = useState({});

  function handleHoverButton(id, bool) {
    setHoverButton({ ...hoverButton, [id]: bool });
  }

  function handleClick(operation) {
    props.setModalSenhaUnica(true);
    props.setOperation(operation);
  }

  return (
    <ButtonGroup size="sm" style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginLeft: '-1rem' }}>
      <Button
        color="primary"
        id="3"
        style={{ borderRadius: '5px', transform: hoverButton[3] ? 'translateY(-.1rem)' : '' }}
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        onClick={() => handleClick('sendDocument')}
      >
        Enviar Documento
      </Button>
      {(localStorage.getItem('nivelAcesso') != '6' && props.user.ID_TIPO_USUARIO == '1') ||
      (localStorage.getItem('nivelAcesso') == '6' && props.user.ID_TIPO_USUARIO == '2') ? (
        <Button
          color="primary"
          onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
          onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
          id="4"
          style={{ borderRadius: '5px', transform: hoverButton[4] ? 'translateY(-.1rem)' : '' }}
          onClick={() => {
            handleClick('changeProfile');
            props.setOpenAccordionTaxes(true);
          }}
        >
          Alterar Perfil
        </Button>
      ) : null}

      <Button
        color="primary"
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        id="5"
        style={{ borderRadius: '5px', transform: hoverButton[5] ? 'translateY(-.1rem)' : '' }}
        onClick={() => {
          props.setState({
            ...props.state,
            ...props.infoPessoaisRedux,
            alteraCliente: !props.alteraCliente,
          });
          {
            props.alteraCliente && props.setOpenAccordionInfo(true);
          }
          window.scrollTo({
            top: 200,
            behavior: 'smooth',
          });
        }}
      >
        {props.alteraCliente ? 'Alterar Cadastro' : 'Desativar Alteração'}
      </Button>

      <Button
        color="primary"
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        id="6"
        style={{ borderRadius: '5px', transform: hoverButton[6] ? 'translateY(-.1rem)' : '' }}
        onClick={() => handleClick('recuperarSenha')}
      >
        Recuperar Senha
      </Button>

      <Button
        color="primary"
        onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
        onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
        id="7"
        style={{ borderRadius: '5px', transform: hoverButton[7] ? 'translateY(-.1rem)' : '' }}
        onClick={() => handleClick('liberarAcessoDispositivo')}
      >
        Liberar Acesso Dispositivo
      </Button>

      {props.user.STATUS == 2 ||
      props.user.STATUS == 4 ||
      props.user.STATUS == 5 ||
      props.user.STATUS == 6 ||
      props.user.STATUS == 7 ? (
        <Button
          color="primary"
          onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
          onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
          style={{ borderRadius: '5px', transform: hoverButton[9] ? 'translateY(-.1rem)' : '' }}
          id="9"
          onClick={() => handleClick('block')}
        >
          {props.user.STATUS == 2 || props.user.STATUS == 5 || props.user.STATUS == 6 || props.user.STATUS == 7
            ? 'Bloquear Usuário'
            : props.user.STATUS == 4
            ? 'Desbloquear Usuário'
            : null}
        </Button>
      ) : null}
    </ButtonGroup>
  );
};

export default ButtonGroupCliente;
