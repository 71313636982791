import api from '../../service/api';

import {
  sendMensagemError,
  sendMensagemLoading,
  sendMensagemSuccess,
  sendMensagemPerfilError,
  sendMensagemPerfilLoading,
  sendMensagemPerfilSuccess,
  sendMensagemTopicError,
  sendMensagemTopicLoading,
  sendMensagemTopicSuccess,
  sendMensagemUniqueError,
  sendMensagemUniqueLoading,
  sendMensagemUniqueSuccess,
  sendMensagemMultipleError,
  sendMensagemMultipleLoading,
  sendMensagemMultipleSuccess,
  listMensagemlLoading,
  listMensagemSuccess,
  listMensagemError,
} from './types';
import { showAlertAction } from '../sweetAlert/actions';

export const searchCpfSendMensagem = (filtro = { limit: 10, page: 1, status: ['2', '5', '6', '7'] }, callback) => {
  return async (dispatch) => {
    dispatch({ type: sendMensagemLoading });
    try {
      const response = await api.post(`usuario/listar/todos`, filtro);
      dispatch({
        type: sendMensagemSuccess,
        payload: response.data.USUARIOS,
      });
      callback(response.data.USUARIOS);
    } catch (error) {
      dispatch({
        type: sendMensagemError,
        payload: error,
      });
      callback(error);
    }
  };
};

export const topicMensagem = (filtro, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: sendMensagemPerfilLoading });
    try {
      const response = await api.get(`/topicos`, filtro);
      dispatch({
        type: sendMensagemPerfilSuccess,
        payload: response.data,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: sendMensagemPerfilError,
        payload: error.response.data,
      });
      callback(error.response.data);
    }
  };
};

export const listMensagens = (filtro, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: listMensagemlLoading });
    try {
      const response = await api.get(
        `/push-notification/report?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro.SEARCH}&DATA_INICIAL=${filtro.DATA_INICIAL}&DATA_FINAL=${filtro.DATA_FINAL}`,
      );

      dispatch({
        type: listMensagemSuccess,
        payload: response.data,
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: listMensagemError,
        payload: error.response.data,
      });
      callback(error.response.data);
    }
  };
};

export const sendMensagemTopic = (params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };
    dispatch({ type: sendMensagemTopicLoading });
    dispatch(showAlertAction({}));

    try {
      const response = await api.post(`/push-notification/topic`, params);
      dispatch({
        type: sendMensagemTopicSuccess,
        payload: response.data,
      });

      dispatch(
        showAlertAction({
          title: 'Sucesso',
          text: 'Notificação enviada',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch({
        type: sendMensagemTopicError,
        payload: 'Notificação não enviada',
      });
      dispatch(
        showAlertAction({
          title: 'Ops...',
          text: 'Notificação não enviada',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const sendMensagemUnique = (params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };
    dispatch(sendMensagemUniqueLoading());
    try {
      const response = await api.post(`/push-notification/user`, params);
      dispatch(sendMensagemUniqueSuccess(response.data));
      dispatch(
        showAlertAction({
          title: 'Sucesso',
          text: 'Menssagen enviada com sucesso',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(sendMensagemUniqueError(error.response.data));
      dispatch(
        showAlertAction({
          title: 'Ops...',
          text: error.response.data.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const sendMensagemMultiple = (params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };
    dispatch({ type: sendMensagemMultipleLoading });
    dispatch(showAlertAction({}));

    try {
      const response = await api.post(`/push-notification/users`, params);
      dispatch({
        type: sendMensagemMultipleSuccess,
        payload: response.data,
      });

      dispatch(
        showAlertAction({
          title: 'Sucesso',
          text: 'Notificação enviada',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch({
        type: sendMensagemMultipleError,
        payload: 'Notificação não enviada',
      });
      dispatch(
        showAlertAction({
          title: 'Ops...',
          text: 'Menssagen não enviada ',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
