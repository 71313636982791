import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Input, InputGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { listarSaldoGeral, salvarSaldoProduto } from '../../../../../../store/coban/actions';
import { listarProdutosCartao } from '../../../../../../store/swap/actions';
import formatMoney from '../../../../../../utils/formatMoneyBRL';
import ModalMensagemSemReload from '../../../../../Transaction/ModalMensagemSemReload';
import {
  ButtonGroup,
  InfoCard,
  ProductCardsType,
  ProductContent,
  ProductTitleAndBalance,
  SaldoColor,
  SaldoContainer,
  SaldoDisponivelColor,
  SaldoStyle,
  SaldoUtilizadoColor,
  Table,
  TableContent,
} from './ModalCardUser.styles';

/* eslint-disable */
export default function ModalCardUser(props) {
  const { CARTAO_PRODUTOS } = useSelector((state) => state.swap);
  const dispatch = useDispatch();
  const { open, cartao } = props.info;
  const { SALDO } = useSelector((state) => state.coban);

  const [operateBalance, setOperateBalance] = useState(false);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [produtos, setProdutos] = useState([]);

  const [stateCard, setStateCard] = useState({});
  const [saldo, setSaldo] = useState({});

  useEffect(() => {
    setSaldo({
      ...SALDO,
    });
  }, [cartao, saldo]);

  useEffect(() => {
    dispatch(listarProdutosCartao(cartao.CPFCNPJ, cartao.CODCARTAO));
  }, [open]);

  useEffect(() => {
    setProdutos(CARTAO_PRODUTOS);
    setStateCard({ ...cartao, PRODUTOS: CARTAO_PRODUTOS });
  }, [CARTAO_PRODUTOS]);

  useMemo(() => {
    dispatch(listarProdutosCartao(cartao.CPFCNPJ, cartao.CODCARTAO));
    setStateCard((prev) => ({ ...prev, PRODUTOS: CARTAO_PRODUTOS }));
    dispatch(listarSaldoGeral(cartao.CPFCNPJ));
  }, [props.reloadProdutos]);

  function alterValueCallback({ target: { value, name } }) {
    const valueNumber = +value;
    const getState = { ...stateCard };
    const alterValue = getState.PRODUTOS.map((product) => {
      if (product.ID == name) {
        if (value != '') {
          product.NOVO_SALDO = +product.SALDO + valueNumber;
          if (product.NOVO_SALDO < 0) {
            delete product.NOVO_SALDO;
          }
        } else {
          delete product.NOVO_SALDO;
        }
        return product;
      } else {
        return product;
      }
    });

    setStateCard((prev) => ({ ...prev, PRODUTOS: alterValue }));
  }

  function salvarSaldo() {
    const filter = stateCard.PRODUTOS.filter((produto) => produto.hasOwnProperty('NOVO_SALDO'));
    const produtos = filter.map((produtos) => {
      return {
        ID_VINCULO: produtos.ID,
        SALDO: produtos.NOVO_SALDO,
      };
    });
    dispatch(
      salvarSaldoProduto(produtos, (res) => {
        setModalMensagem(true);
        setMensagem(res.mensagem);
        if (res.retorno === 'sucesso') {
          setSucesso(true);
          props.setReloadProdutos(!props.reloadProdutos);
          setOperateBalance(false);
        } else {
          setSucesso(false);
        }
      }),
    );
  }

  function buttonCancelAlterValue() {
    const getState = { ...stateCard };
    const alterValue = getState.PRODUTOS.map((product) => {
      if (product.NOVO_SALDO) {
        delete product.NOVO_SALDO;
      }
      return product;
    });

    setStateCard((prev) => ({ ...prev, PRODUTOS: alterValue }));
  }

  function reduceNegativo() {
    const negativo =
      saldo.SALDO_DISPONIVEL +
      +stateCard.PRODUTOS.reduce(
        (acc, curr) => (acc += curr.hasOwnProperty('NOVO_SALDO') ? curr.SALDO - curr.NOVO_SALDO : 0),
        0,
      );
    return negativo;
  }

  return (
    <>
      <Modal
        isOpen={open}
        toggle={!operateBalance && props.toggle}
        size="lg"
        style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader toggle={!operateBalance && props.toggle}>
          <h2>Cartão</h2>
        </ModalHeader>
        <ModalBody>
          {props.isConvened && (
            <>
              {stateCard && stateCard.PRODUTOS && stateCard.PRODUTOS.length ? (
                <Alert color="danger" isOpen={reduceNegativo() < 0}>
                  <i className="fa fa-exclamation-circle" aria-hidden="true" style={{ marginRight: '1rem' }}></i>
                  Não é possível salvar negativado
                </Alert>
              ) : null}
            </>
          )}

          <InfoCard>
            <Table>
              <TableContent>
                <label>TIPO</label>
                <label>{stateCard.TIPO}</label>
              </TableContent>
              <TableContent>
                <label>NÚMERO</label>
                <label>{stateCard.NUMERO}</label>
              </TableContent>
            </Table>

            {!operateBalance ? (
              <ButtonGroup>
                {props.isConvened && (
                  <>
                    {stateCard.PRODUTOS && stateCard.PRODUTOS.length ? (
                      <Button
                        size="sm"
                        color="primary"
                        style={{
                          alignContent: 'flex-end',
                        }}
                        onClick={() => {
                          setOperateBalance(!operateBalance);
                        }}
                        name="operar"
                        /* onClick={() => {
                setModalVincularCartaoSwap(true);
                setIdSwap(cartao.CODCARTAO);
              }} */
                      >
                        Operar Saldo
                      </Button>
                    ) : null}
                    <Button
                      size="sm"
                      color="success"
                      style={{
                        alignContent: 'flex-end',
                      }}
                      name="cancelar"
                      onClick={(e) => {
                        props.setModalVincularProduto({
                          open: true,
                          PRODUTOS: cartao.PRODUTOS,
                        });
                        props.setIdSwap(stateCard.CODCARTAO);
                        props.setIdCartao(stateCard.CODCARTAO);
                        /* setModalVincularCartaoSwap(true);
                setIdSwap(cartao.CODCARTAO);*/
                      }}
                    >
                      Vincular
                    </Button>
                  </>
                )}

                {localStorage.getItem('nivelAcesso') == 1 && (
                  <Button
                    size="sm"
                    color="danger"
                    style={{
                      alignContent: 'flex-end',
                    }}
                    name="cancelar"
                    onClick={(e) => {
                      props.setModalSenha(true);
                      props.setIdSwap(stateCard.CODCARTAO);
                      /*setModalSenha(true);
                setIdSwap(cartao.CODCARTAO);*/
                    }}
                  >
                    Trocar Senha
                  </Button>
                )}

                {stateCard.BLOQUEADO == 1 ? (
                  <Button
                    size="sm"
                    name="0"
                    color="primary"
                    style={{
                      alignContent: 'flex-end',
                    }}
                    onClick={() => {
                      props.setCartao({
                        BLOQUEADO: 0,
                        ID: stateCard.CODCARTAO,
                        PARCEIRO: stateCard.PARCEIRO,
                        DATA_VENCIMENTO: stateCard.DATA_VENCIMENTO,
                      });
                      props.setModalConfirmacao(!props.modalConfirmacao);
                      /* props.setCartao({
                    BLOQUEADO: 0,
                    ID: cartao.CODCARTAO,
                    PARCEIRO: cartao.PARCEIRO,
                    DATA_VENCIMENTO: cartao.DATA_VENCIMENTO,
                  });
                  props.setModalConfirmacao(!props.modalConfirmacao); */
                    }}
                  >
                    Desbloquear
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    name="1"
                    color="warning"
                    style={{
                      alignContent: 'flex-end',
                    }}
                    onClick={() => {
                      props.setCartao({
                        BLOQUEADO: 1,
                        ID: stateCard.CODCARTAO,
                        PARCEIRO: stateCard.PARCEIRO,
                        DATA_VENCIMENTO: stateCard.DATA_VENCIMENTO,
                      });
                      props.setModalConfirmacao(!props.modalConfirmacao);
                      /*setCartao({
                    BLOQUEADO: 1,
                    ID: cartao.CODCARTAO,
                    PARCEIRO: cartao.PARCEIRO,
                    DATA_VENCIMENTO: cartao.DATA_VENCIMENTO,
                  });
                  setModalConfirmacao(!modalConfirmacao);*/
                    }}
                  >
                    Bloquear
                  </Button>
                )}
              </ButtonGroup>
            ) : (
              <div style={{ display: 'flex', gap: '1rem' }}>
                <SaldoStyle>
                  Saldo disponível:{' '}
                  <SaldoDisponivelColor color={reduceNegativo() >= 0 ? 'positivo' : 'negativo'}>
                    {formatMoney(reduceNegativo())}
                  </SaldoDisponivelColor>
                </SaldoStyle>

                <ButtonGroup>
                  <Button
                    color="primary"
                    size="sm"
                    disabled={
                      !stateCard.PRODUTOS.some((product) => product.hasOwnProperty('NOVO_SALDO')) ||
                      reduceNegativo() < 0
                    }
                    onClick={() => salvarSaldo()}
                  >
                    Salvar
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => {
                      setOperateBalance(!operateBalance);
                      buttonCancelAlterValue();
                    }}
                  >
                    Cancelar
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </InfoCard>
          <ProductContent>
            {props.isConvened ? (
              <div>
                <ProductTitleAndBalance>
                  <p>Produtos</p>
                  <SaldoContainer>
                    <SaldoStyle>
                      Saldo Conta Digital: R$ <SaldoColor> {formatMoney(saldo.SALDO_ATUAL)}</SaldoColor>
                    </SaldoStyle>
                    <SaldoStyle>
                      Saldo utilizado: R$
                      <SaldoUtilizadoColor> {formatMoney(saldo.SALDO_UTILIZADO)}</SaldoUtilizadoColor>
                    </SaldoStyle>
                    {!operateBalance && (
                      <SaldoStyle>
                        Saldo disponível: R$
                        <SaldoDisponivelColor color={saldo.SALDO_DISPONIVEL != '0' ? 'positivo' : ''}>
                          {formatMoney(saldo.SALDO_DISPONIVEL)}
                        </SaldoDisponivelColor>
                      </SaldoStyle>
                    )}
                  </SaldoContainer>
                </ProductTitleAndBalance>
                <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                  {stateCard.PRODUTOS && stateCard.PRODUTOS.length ? (
                    stateCard.PRODUTOS.map((product) => (
                      <ProductCardsType>
                        <div id="cardType__title">{product.DESCRICAO_PRODUTO}</div>
                        <div id="cardType__balance">
                          Saldo atual:
                          <label id="cardType__balanceTitle">
                            <b>R$</b>
                            {formatMoney(product.SALDO)}
                          </label>
                        </div>
                        {operateBalance && (
                          <div id="cardType__novoSaldo">
                            {/* <label>Novo saldo:</label> */}
                            <label>Adicionar/Remover:</label>

                            <label style={{ display: 'flex', gap: '0.5rem' }}>
                              <b>R$</b>
                              <InputGroup size="sm">
                                <Input
                                  type="number"
                                  name={product.ID}
                                  /* placeholder="Digite o novo saldo" */

                                  id="cardType__balanceTitle__input"
                                  onChange={(e) => alterValueCallback(e)}
                                  disabled={!operateBalance}
                                />
                              </InputGroup>
                            </label>
                          </div>
                        )}
                        {product.hasOwnProperty('NOVO_SALDO') && product.NOVO_SALDO >= 0 && (
                          <label>
                            Total:{' '}
                            <label
                            /* style={{
                              color: +product.NOVO_SALDO < +product.SALDO ? 'red' : 'green',
                            }} */
                            >
                              R$ {formatMoney(product.NOVO_SALDO)}
                            </label>
                          </label>
                        )}
                      </ProductCardsType>
                    ))
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        color: '#1C1C1CB5',
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      Cliente não possui produtos vinculados a esse cartão
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <ProductTitleAndBalance>
                  <p>Produtos</p>
                </ProductTitleAndBalance>
                <div style={{ textAlign: 'center', color: '#1C1C1CB5', fontWeight: 600, fontSize: 14 }}>
                  Cliente não conveniado
                </div>
              </>
            )}
          </ProductContent>
        </ModalBody>
      </Modal>
      <ModalMensagemSemReload
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          setModalMensagem(false);
        }}
      />
    </>
  );
}
