import Styled from 'styled-components';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = Styled(MuiAccordion)`

`;

export const AccordionSummary = Styled(MuiAccordionSummary)`
  background-color: #0092d4  !important;
  display: flex !important;
  padding: 0px 16px !important;

  .MuiAccordionSummary-content{
    color: white;
  }

`;

export const AccordionDetails = Styled(MuiAccordionDetails)`

`;
