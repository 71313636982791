import {
  DOWNLOADNF_RESPONSE,
  GET_ANTICIPATION,
  GET_ANTICIPATION_FAIL,
  GET_ANTICIPATION_SUCCESS,
  LANCAMENTO,
  LISTANF_RESPONSE,
  TRANSACTION_AVULSE,
  TRANSACTION_REMESSA,
  TRANSACTION_RESPONSE,
  TRANSACTION_RESPONSE_TED,
} from './types';

const INITIAL_STATE = {
  dadosCardID: [],
  loading_transaction: false,
  loading_transaction_ted: false,
  loading_log: false,
  erro_transaction: false,
  erro_message_transaction: null,
  cronograma: [],
  listCardSales: [],
  listCardSalesInn: [],
  DADOS_VENDAS_CARTAO: { VALOR_TOTAL: 0 },
  listBilletSales: [],
  detailBilletSales: [],
  DADOS_VENDAS_BOLETO: { VALOR_TOTAL: 0 },
  totalAcesso: {},
  tiposTransacoes: [],
  modoPagamento: [],
  listSales: [],
  DADOS_VENDA: {},
  remessaMessage: '',
  errorRemessa: false,
  successRemessa: false,
  anticipation: [],
  analysis: [],
  loading_anticipation: false,
  erro_anticipation: false,
  error_anticipation_message: null,
  anticipation_type: 'pendente',
  pagamentos: [],
  pagamento: {},
  saques: [],
  getItens: [],
  emprestimos: [],
  sucesso: false,
  historico: [],
  listWithdrawals: [],
  listRemessa: [],
  DADOS_SAQUES: { VALOR_TOTAL: 0 },
  retorno: {},
  totalTransactions: { PAGINATION: {}, TRANSACAO: [] },
  totalTransfer: {},
  totalTrasacao: '',
  filtro: {
    limit: 10,
    search: '',
    page: 1,
    status: '',
    dataInicial: '',
    dataFinal: '',
    servicos_digitais: '',
  },
  filtroAvulse: { limit: 10, page: 1, IDTIPOTRANSACAO: 1 },
  pagination: {}, // dados da paginação
  mensagem: '',
  status_emprestimo: [],
  status_transaction: [],
  digitalServices: [],
  historyTed: [],
  tedAprovedRes: [],
  idCodes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRANSACTION_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };
    case TRANSACTION_AVULSE:
      return { ...state, retorno: action.payload };
    case GET_ANTICIPATION:
      return {
        ...state,
        anticipation: [],
        anticipation_type: action.anticipationType,
        loading_anticipation: true,
        erro_anticipation: false,
        error_anticipation_message: null,
      };
    case LISTANF_RESPONSE:
      return { ...state, ...action.payload };
    case DOWNLOADNF_RESPONSE:
      return { ...state, ...action.payload };
    case GET_ANTICIPATION_SUCCESS:
      return {
        ...state,
        anticipation: action.payload,
        anticipation_type: action.anticipationType,
        loading_anticipation: false,
        erro_anticipation: false,
        error_anticipation_message: null,
      };
    case GET_ANTICIPATION_FAIL:
      return {
        ...state,
        anticipation_: action.payload,
        anticipation_type: action.anticipationType,
        loading_anticipation: false,
        erro_anticipation: true,
        error_anticipation_message: action.error,
      };
    case TRANSACTION_REMESSA:
      var { listRemessa } = state;
      listRemessa = [...listRemessa, ...action.payload.listRemessa];
      return {
        ...state,
        ...action.payload,
        listRemessa,
      };
    case LANCAMENTO:
      return {
        ...state,
        ...action.payload,
      };
    case TRANSACTION_RESPONSE_TED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
