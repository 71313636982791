import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const Text = styled(TextField)`
  width: ${(props) => props.width} !important;
  height: ${(props) => (props.height ? props.height : 'auto')} !important;
  background-color: rgba(51, 51, 51, 0.05);

  .Mui-focused {
    margin-left: 0;
  }
  .MuiInputLabel-root,
  .MuiInputBase-root {
    background-color: transparent;
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiInputBase-input.MuiInput-input {
    margin-left: 1.111vw;
  }

  .PrivateNotchedOutline-root-19 {
    padding: 0;
  }
  .PrivateNotchedOutline-root-20 {
    padding: 0 !important;
  }
`;
