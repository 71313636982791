/* eslint-disable */
/* import ModalPartnerLawSuits from './Modals/ModalPartnerLawSuits'; */
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { chooseColor, chooseColorString, getPercentage } from '../../../../utils/functions';
import * as Styled from './AccordionVerifications.styles';
import './style.css';
import { getAddress, getHighestMatch, thereIsSanctions } from '../../../../utils/kycGetHighest';
import formatCNPJ from '../../../../utils/formatCNPJ';
import formatPhoneNumber from '../../../../utils/formatPhoneNumber';
import formatCEP from '../../../../utils/formatCEP';
import { unformatDateKyc } from '../../../../utils/dateKyc';

export default function KycPj({
  consultas,
  setModalProcesses,
  setOwnersModal,
  owners_lawsuits,
  uniqueColumn,
  setKycModal,
  setKycOwnersModal,
}) {
  const [client, setClient] = useState(consultas);

  useEffect(() => {
    setClient(consultas);
  }, [consultas]);

  function verifyFields() {
    if (!client) return false;
    if (!client.basic_data) return false;
    /*     if (!client.basic_data) return false;
    if (!client.basic_data[0]) return false;
    if (!client.addresses) return false;
    if (!client.addresses[0]) return false;
    if (!client.addresses[0][0]) return false;
    if (!client.emails) return false;
    if (!client.emails[0]) return false;
    if (!client.phones[0]) return false;
    if (!client.basic_data[0].cnpj.informacao_cnpj) return false; */
    /*   if (!client.basic_data[0]) return false;
    if (client.adresses[0]) return false; */
    return true;
  }

  function render() {
    const data_fundacao = client.basic_data[0].data_fundacao;
    const { PEP } = client.kyc[0];
    const { mensagem } = client.emails[0];
    const { processes } = client;

    const address = getAddress(client.addresses[0]);

    return (
      <>
        <h2>
          <span className="blue-badge">
            Média Final:
            <span className={chooseColor(parseFloat(client.media_global))}>{getPercentage(client.media_global)}</span>
          </span>
        </h2>
        <Styled.container uniqueColumn={uniqueColumn}>
          <Styled.block uniqueColumn={uniqueColumn}>
            <Styled.column>
              {client.basic_data[0].nome.dado_encontrado && (
                <Styled.row>
                  <span className="bold">Nome:</span> {client.basic_data[0].nome.dado_encontrado}
                </Styled.row>
              )}
              {client.basic_data[0].cnpj && client.basic_data[0].cnpj.informacao_cnpj && (
                <Styled.row>
                  <span className="bold">CNPJ: </span>
                  {formatCNPJ(client.basic_data[0].cnpj.informacao_cnpj.dado_encontrado)}
                </Styled.row>
              )}
              {client.basic_data[0].data_fundacao && (
                <Styled.row>
                  <span className="bold">Data Fundação: </span>
                  {unformatDateKyc(client.basic_data[0].data_fundacao.dado_encontrado)}
                </Styled.row>
              )}
              <Styled.row>
                <span className="bold">Telefone:</span>
                <span className="green"> Telefone válido</span>
              </Styled.row>
            </Styled.column>
            <Styled.column>
              {client.basic_data[0].nome && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(client.basic_data[0].nome.match_rate))}>
                    {getPercentage(client.basic_data[0].nome.match_rate)}
                  </span>
                </Styled.row>
              )}
              {client.basic_data[0].cnpj && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(client.basic_data[0].cnpj.informacao_cnpj.match_rate))}>
                    {getPercentage(client.basic_data[0].cnpj.informacao_cnpj.match_rate)}
                  </span>
                </Styled.row>
              )}

              {data_fundacao && (
                <Styled.row>
                  Match:
                  <span className={chooseColor(parseFloat(data_fundacao.match_rate))}>
                    {getPercentage(data_fundacao.match_rate)}
                  </span>
                </Styled.row>
              )}
              {<Styled.invisibleRow>-</Styled.invisibleRow>}
            </Styled.column>
          </Styled.block>

          {address ? (
            <Styled.block uniqueColumn={uniqueColumn}>
              <Styled.column>
                {address.CEP && (
                  <Styled.row>
                    <span className="bold">CEP:</span> {formatCEP(address.CEP.dado_encontrado)}
                  </Styled.row>
                )}
                {address.numero && (
                  <Styled.row>
                    <span className="bold">Número:</span>
                    {address.numero.dado_encontrado}
                  </Styled.row>
                )}
                {address.bairro && (
                  <Styled.row>
                    <span className="bold">Bairro: </span>
                    {address.bairro.dado_encontrado}
                  </Styled.row>
                )}
                {address.cidade && (
                  <Styled.row>
                    <span className="bold">Cidade: </span>
                    {address.cidade.dado_encontrado}
                  </Styled.row>
                )}
                {address.endereco && (
                  <Styled.row>
                    <span className="bold">Endereço: </span>
                    {address.endereco.dado_encontrado}
                  </Styled.row>
                )}
              </Styled.column>
              <Styled.column>
                {address.CEP && (
                  <Styled.row>
                    Match{' '}
                    {address.CEP.match_rate === '100,00' ? (
                      <span className="green">100%</span>
                    ) : (
                      <span className="red">0%</span>
                    )}
                  </Styled.row>
                )}
                {address.numero && (
                  <Styled.row>
                    Match:
                    {address.numero.match_rate === '100,00' ? (
                      <span className="green">100%</span>
                    ) : (
                      <span className="red">0%</span>
                    )}
                  </Styled.row>
                )}
                {address.bairro && (
                  <Styled.invisibleRow>
                    {/* Match:
                    {getPercentage(address.bairro.match_rate)} */}
                    -
                  </Styled.invisibleRow>
                )}
                {address.cidade && (
                  <Styled.invisibleRow>
                    {/*  Match:
                    {getPercentage(address.cidade.match_rate)} */}
                    -
                  </Styled.invisibleRow>
                )}
                {address.endereco && (
                  <Styled.invisibleRow>
                    {/*  Match:
                    {getPercentage(address.endereco.match_rate)} */}
                    -
                  </Styled.invisibleRow>
                )}
              </Styled.column>
            </Styled.block>
          ) : (
            <Styled.block>
              <Styled.column>
                <Styled.row>
                  <span className="red"> Endereço não encontrado </span>
                </Styled.row>
              </Styled.column>
            </Styled.block>
          )}
        </Styled.container>
        <h2>Informações complementares</h2>
        <Styled.container uniqueColumn={uniqueColumn}>
          <Styled.block uniqueColumn={uniqueColumn}>
            <Styled.column>
              <Styled.row>
                <span className="bold">Email: </span>
                <span className={chooseColorString(mensagem, 'Email nao encontrado')}>{mensagem}</span>
              </Styled.row>
              <Styled.row>
                <span className="bold">KYC:</span>
                {thereIsSanctions(client.kyc[0].SANCOES) ? (
                  <span className="green">
                    Sanções encontradas
                    <Button
                      size="sm"
                      color="primary"
                      style={{
                        alignContent: 'flex-end',
                        marginLeft: '1rem',
                      }}
                      onClick={() => setKycModal(true)}
                    >
                      Ver Sanções
                    </Button>
                  </span>
                ) : (
                  <span className="red"> Não foram encontradas sanções</span>
                )}
              </Styled.row>
              <Styled.row>
                <span className="bold">KYC Sócios:</span>
                {thereIsSanctions(client.owners_kyc[0][0].SANCOES) ? (
                  <span className="green">
                    Sanções encontradas
                    <Button
                      size="sm"
                      color="primary"
                      style={{
                        alignContent: 'flex-end',
                        marginLeft: '1rem',
                      }}
                      onClick={() => setKycOwnersModal(true)}
                    >
                      Ver Sanções
                    </Button>
                  </span>
                ) : (
                  <span className="red"> Não foram encontradas sanções</span>
                )}
              </Styled.row>

              <Styled.row>
                <span className="bold">PEP:</span>
                <span className={PEP ? 'green' : 'red'}>
                  {PEP ? 'Informações encontradas' : 'Não foram encontradas informações'}
                </span>
              </Styled.row>
            </Styled.column>
          </Styled.block>
          <Styled.block uniqueColumn={uniqueColumn}>
            <Styled.column>
              <Styled.row>
                <span className="bold">Processos:</span>
                {processes[0].subject ? (
                  <span className="green">Processos encontrados </span>
                ) : (
                  <span className="red"> Não foram encontrados processos</span>
                )}
              </Styled.row>
              <Styled.row>
                <span className="bold">Processos Sócios:</span>
                {owners_lawsuits && owners_lawsuits.length > 0 ? (
                  <span className="green"> Foram encontrados processos </span>
                ) : (
                  <span className="red">Não foram encontados processos</span>
                )}
              </Styled.row>
            </Styled.column>
            <Styled.column>
              {processes[0].subject ? (
                <Styled.row>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => setModalProcesses(true)}
                    style={{
                      marginTop: '-.5rem',
                    }}
                  >
                    Ver Processos
                  </Button>
                </Styled.row>
              ) : (
                <Styled.row> - </Styled.row>
              )}
              {owners_lawsuits && owners_lawsuits.length > 0 ? (
                <Styled.row>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => setOwnersModal(true)}
                    style={{
                      marginTop: '-.5rem',
                    }}
                  >
                    Ver Processos
                  </Button>
                </Styled.row>
              ) : (
                <Styled.row> - </Styled.row>
              )}
            </Styled.column>
          </Styled.block>
        </Styled.container>
      </>
    );
  }

  return <>{verifyFields() && render()}</>;
}
