import React from 'react';
import Recaptcha from 'react-recaptcha';
import { connect } from 'react-redux';

// reactstrap components
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';

// core components

import Loading from '../../components/Loader/Loading.jsx';
// Actions
import { FUNDO_LOGIN, LOGO } from '../../assets/EnvStyles.js';
import { login, validateLogin } from '../../store/auth/actions';

/* eslint-disable */
class Login extends React.Component {
  state = {
    CPFCNPJ: '',
    FONECEL: '',
    SENHA: '',
    TOKEN: '1234',
    QRCODE: '',
    step: 1,
    errorSenha: 0,
    lockField: false,
    isVerified: false,
    modal: false,
  };

  componentDidMount() {
    this.recaptchaSucces = this.recaptchaSucces;
    this.verifyCallback = this.verifyCallback.bind(this);
    document.body.classList.add('bg-default');
    if (this.props.auth.is_authenticate) this.props.history.push('/admin/dashboard');
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }

  login() {
    const { CPFCNPJ, FONECEL, SENHA } = this.state;

    if (CPFCNPJ == '') {
      return this.setState({ message: 'É necessário inserir o CPF ou CNPJ' });
    }
    if (FONECEL == '') {
      return this.setState({ message: 'É necessário inserir o telefone' });
    }
    if (SENHA == '') {
      return this.setState({ message: 'É necessário inserir a senha' });
    }

    this.props.login({ CPFCNPJ, FONECEL, SENHA }, (e, res) => {
      if (e) this.setState({ QRCODE: res.data.QRCODE, step: 2 });
      else {
        if (res.response) this.setState({ message: res.response.data.mensagem });
        else this.setState({ message: 'Sem conexão com a internet' });
      }
    });
  }

  validate() {
    const { CPFCNPJ, FONECEL, SENHA, TOKEN, QRCODE } = this.state;

    if (TOKEN == '') return this.setState({ message: 'É necessário inserir o Token' });
    this.props.validateLogin({ CPFCNPJ, FONECEL, SENHA, TOKEN, QRCODE }, (e, res) => {
      if (e) {
        this.props.history.push('/admin/dashboard');
        localStorage.setItem('CPFCNPJ', CPFCNPJ);
      } else {
        if (res.response) this.setState({ message: ' Usuário ou senha inválido' });
        else this.setState({ message: 'Sem conexão com a internet' });
      }
    });
  }

  disabledByError() {
    this.setState({ errorSenha: this.state.errorSenha + 1 });
    this.login();
    // const { SENHA } = this.state;
    if (this.state.errorSenha >= 3) {
      this.setState({ lockField: true });
    }
  }
  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true,
        lockField: false,
      });
    }
  }

  render() {
    return (
      <>
        <div className="main-content" style={{ background: FUNDO_LOGIN }}>
          {/* <ConfirmarSenha
            reset={true}
            modal={this.state.modal}
            toggle={() => this.setState({ modal: false })}
          /> */}

          <div className="header py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  {this.props.auth.loading ? (
                    <Loading />
                  ) : (
                    <Col lg="4" md="4">
                      <Card>
                        {this.state.step == 1 ? (
                          <CardBody>
                            <img
                              style={{ width: 'auto', maxHeight: '60px' }}
                              alt="logo-troco-na-conta"
                              className="login-img"
                              src={LOGO}
                            />
                            <div className="mt-4 text-left">
                              <label className="form-control-label" htmlFor="input-cpf-cnpj">
                                CPF/CNPJ
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-cpf-cnpj"
                                type="text"
                                value={this.state.CPFCNPJ}
                                onChange={(e) => this.setState({ CPFCNPJ: e.target.value })}
                              />
                              <label className="form-control-label" htmlFor="input-phone">
                                Telefone
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-phone"
                                type="text"
                                value={this.state.FONECEL}
                                onChange={(e) => this.setState({ FONECEL: e.target.value })}
                              />
                              <label className="form-control-label" htmlFor="input-password">
                                Senha
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-password"
                                type="password"
                                value={this.state.SENHA}
                                onKeyPress={(e) => (e.key == 'Enter' ? this.login() : null)}
                                onChange={(e) => this.setState({ SENHA: e.target.value })}
                              />
                            </div>
                            <Button
                              color="success"
                              disabled={this.state.lockField}
                              onClick={() => {
                                this.disabledByError();
                              }}
                              style={{
                                width: '100%',
                                marginTop: '1.5em',
                                marginBottom: '10px',
                              }}
                            >
                              Entrar
                            </Button>

                            {this.state.errorSenha > 3 ? (
                              // <small style={{color: 'red', }}> {this.state.errorSenha} tentativas</small>
                              <Recaptcha
                                sitekey="6Lej2sUaAAAAAA2uoBvC88dzOhI1QJI_SUUqqInH"
                                render="explicit"
                                verifyCallback={this.verifyCallback}
                              />
                            ) : (
                              <small style={{ color: 'red' }}>{this.state.message}</small>
                            )}
                            {/* <br />

                            <a
                              style={{ cursor: 'pointer', color: '#2FB6FD' }}
                              onClick={() => this.setState({ modal: true })}
                            >
                              Esqueci minha senha
                            </a> */}
                          </CardBody>
                        ) : (
                          <CardBody>
                            <img
                              style={{ width: 'auto', maxHeight: '60px' }}
                              alt="logo-troco-na-conta"
                              className="login-img"
                              src={LOGO}
                            />
                            <div className="mt-4 text-left">
                              <label className="form-control-label" htmlFor="input-token">
                                Token
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-token"
                                type="text"
                                value={this.state.TOKEN}
                                onChange={(e) => this.setState({ TOKEN: e.target.value })}
                              />
                            </div>
                            <Button
                              color="success"
                              onClick={() => this.validate()}
                              style={{ width: '100%', marginTop: '1.5em' }}
                            >
                              Confirmar
                            </Button>
                          </CardBody>
                        )}
                      </Card>
                    </Col>
                  )}
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

const mapStatToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStatToProps, {
  login,
  validateLogin,
})(Login);
