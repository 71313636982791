import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table.jsx';
// reactstrap components
import moment from 'moment';
import { Form, Row } from 'reactstrap';
import GenericButton from '../../components/Buttons/Button';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import InputDate from '../../components/Filter/components/InputDate.js';
import InputSearch from '../../components/Filter/components/InputSearch.js';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults.js';
import InputStatus from '../../components/Filter/components/InputStatus.js';
import Filter from '../../components/Filter/Filter.js';
import * as Styled from '../../components/Filter/styles/Filter.style';
import Icon from '../../components/Icons/IconsButton';
import Loading from '../../components/Loader/Loading.jsx';
import StatusContainer from '../../components/StatusContainer/StatusContainer.js';
import StatusContainerValue from '../../components/StatusContainer/StatusContainerValue.js';
import { getCardInnTransactions, getCardInnTransactionsID } from '../../store/transactions/actions.js';
import formatCNPJ from '../../utils/formatCNPJ.js';
import formatCPF from '../../utils/formatCPF';
import valueEmpty from '../../utils/valueEmpty.js';
import ModalInformations from './ModalInformations.jsx';

/* eslint-disable */

export default function CardSalesInn() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [id, setId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [filter, setFilter] = useState({
    limit: 10,
    search: '',
    page: 1,
    status: 0,
    dataInicial: '',
    dataFinal: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    dispatch(getCardInnTransactions(filter));
  }, [filter.page]);

  const {
    listCardSalesInn: { PAGINATION, RETORNO },
    dadosCardID,
    loading_transaction,
  } = useSelector((state) => state.transactions);

  let { page } = filter;

  const clickInfo = (ID) => {
    dispatch(getCardInnTransactionsID(ID));
    setId(ID);
    setModal(true);
  };

  const close = () => setModal(!modal);

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = PAGINATION.prevPage;
        break;
      case 'next':
        page = PAGINATION.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page });
  };

  return (
    <div>
      <CustomContainer>
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <Styled.InputsContainer>
              <InputSearch
                width="30vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
              />

              <InputStatus
                setValue={(value) => setFilter({ ...filter, status: value })}
                options={['Todas', 'Aguardando', 'Aprovado', 'Cancelado', 'Vencido']}
                codeValue={['', 1, 2, 3, 4]}
              />

              <InputDate
                label="Data Inicial"
                name="dataInicial"
                value={filter.dataInicial}
                setValue={(value) => setFilter({ ...filter, dataInicial: value })}
              />

              <InputDate
                label="Data Final"
                name="dataFinal"
                value={filter.dataFinal}
                setValue={(value) => setFilter({ ...filter, dataFinal: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <GenericButton
                  className="mt-3"
                  color="primary"
                  size="sm"
                  type="submit"
                  onClick={() => {
                    dispatch(getCardInnTransactions(filter));
                    filter.page = '1';
                  }}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
              </div>
            </Styled.InputsContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <InputSelectAmountResults
                options={['10', '25', '50', '100', '1000']}
                value={filter.limit}
                setValue={(value) => setFilter({ ...filter, limit: value })}
                width={'25rem'}
              />
            </div>
          </Form>
        </Filter>

        <Row>
          <div className="col">
            {!PAGINATION ? (
              <h4 style={{ textAlign: 'center' }}>Carregando Transações</h4>
            ) : (
              <div>
                {loading_transaction ? (
                  <Loading color="#11cdef" />
                ) : (
                  <Table
                    headers={[
                      {
                        info: 'Data/Hora',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'CPF/CNPJ',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Nome',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Estabelecimento',
                        style: { textAlign: 'left' },
                      },
                      // {
                      //   info: 'Parceiro',
                      //   style: { textAlign: 'center' },
                      // },

                      {
                        info: 'STATUS',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'Valor',
                        style: { textAlign: 'center' },
                      },
                    ]}
                    data={
                      RETORNO &&
                      RETORNO.map((sale, index) => {
                        return {
                          data: [
                            {
                              info: sale.DATA_HORA,
                              style: { textAlign: 'center' },
                            },
                            {
                              info:
                                sale.CPFCNPJ && sale.CPFCNPJ.length === 11
                                  ? formatCPF(sale.CPFCNPJ)
                                  : formatCNPJ(sale.CPFCNPJ),
                              style: { textAlign: 'left' },
                            },
                            {
                              info: sale.NOME ? sale.NOME : '----',
                              style: { textAlign: 'left' },
                            },
                            {
                              info: sale.ESTABELECIMENTO ? sale.ESTABELECIMENTO : '----',
                              style: { textAlign: 'left' },
                            },
                            // {
                            //   info: sale.PARCEIRO ? (
                            //     <StatusContainerTed
                            //       code={sale.PARCEIRO.toUpperCase()}
                            //       description={sale.PARCEIRO}
                            //     />
                            //   ) : (
                            //     '----'
                            //   ),
                            //   style: { textAlign: 'center' },
                            // },
                            {
                              info: valueEmpty(
                                sale.STATUS ? (
                                  <StatusContainer code={sale.STATUSTRANSACAO} description={sale.STATUS} />
                                ) : null,
                              ),
                              style: { textAlign: 'center' },
                            },
                            {
                              info: valueEmpty(
                                sale.VALOR ? (
                                  <StatusContainerValue code={sale.TIPO_TRANSACAO} description={sale.VALOR} />
                                ) : null,
                              ),
                              style: {
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                              },
                            },
                          ],
                          onClick: () => clickInfo(sale.ID),
                        };
                      })
                    }
                    pagination={PAGINATION}
                    previousPage={{ onClick: () => tablePagination('prev') }}
                    nowPage={{ page, onClick: tablePagination }}
                    nextPage={{ onClick: () => tablePagination('next') }}
                  />
                )}
              </div>
            )}
          </div>
        </Row>
      </CustomContainer>
      <ModalInformations toggle={close} modal={modal} title={'Dados'} data={dadosCardID} ID={id} />
    </div>
  );
}
