import styled from 'styled-components';
import { Button } from 'reactstrap';

const otherStyles = (props) => `
  --color: #2405f2;
  background-color: ${props.backgroundColor ? props.backgroundColor : 'var(--color)'} !important;
  color: ${props.color ? props.color : 'white'} !important;
  font-weight: ${props.fontWeight ? props.fontWeight : 'bold'} !important;
  `;

const GenericButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? props.height : '35px')};
  width: ${(props) => (props.width ? props.width : '100px')};
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
  ${(props) => otherStyles(props)}

  &:hover, &:active {
    ${(props) => otherStyles(props)}
  }
`;

export default GenericButton;
