import { showAlert, hideAlert } from './types';

const INITIAL_STATE = {
  alert: false,
  message: '',
  func: null,
  loadingSpinner: false,
  close: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case showAlert:
      return {
        ...state,
        alert: true,
        message: action.payload,
        func: action.payload.func,
        close: false,
        loadingSpinner: action.payload.loadingSpinner,
      };
    case hideAlert:
      return {
        ...state,
        alert: false,
        message: '',
        func: null,
        loadingSpinner: false,
        close: true,
      };

    default:
      return state;
  }
};
