import { Badge } from 'reactstrap';
import React from 'react';
import formatMoney from '../../utils/formatMoneyBRL';
import { CustomBadge } from './renterTaxasStyled';
/* eslint-disable */
export const renderTaxas = (taxa, financiamento) => {
  var comparation = financiamento.length === 0;
  if (taxa && financiamento) {
    const taxas = [
      // { label: 'CC', value: comparation ? "0" :`${taxa[3].VALOR}%` },
      // { label: 'CD', value: comparation ? "0" :`${taxa[4].VALOR}%` },
      // { label: 'ANT', value: comparation ? "0" :`${taxa[1].VALOR}%` },

      { label: 'SAQUE', value: `R$${taxa[2].VALOR}` },
      { label: 'BOLETO', value: `R$${formatMoney(taxa[0].VALOR)}` },
      { label: 'QR CODE', value: `${taxa[5].VALOR} %` },
      { label: 'JUROS EMPRÉSTIMO', value: `${formatMoney(taxa[6].VALOR)} %` },
      //{ label: 'PRAZO EMPRÉSTIMO', value: `${taxa[7].VALOR} dias` },
      // { label: '1 a 3', value: comparation ? "0" :`${financiamento[0].TAXA}%` },
      // { label: '4 a 7', value: comparation ? "0": `${financiamento[3].TAXA}%` },
      // { label: '8 a 12', value: comparation ? "0" :`${financiamento[7].TAXA}%` }
    ];
    var x = 0;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {taxas.map((x, indice) => (
          // indice % 3 != 0 ? (
          <Badge style={{ padding: '0.2em', margin: '0.2em' }} color="success">
            {x.label}: {x.value}
          </Badge>
        ))}
      </div>
    );
  }
};
export const renderTaxasUsuario = (taxa, financiamento) => {
  const taxas = [
    { label: `TED`, value: `R$${formatMoney(taxa[2].VALOR)}` },
    { label: `${taxa[0].DESCRICAO}`, value: `R$${formatMoney(taxa[0].VALOR)}` },
    { label: `${taxa[6].DESCRICAO}`, value: `${formatMoney(taxa[6].VALOR)} %` },
    { label: `${taxa[7].DESCRICAO}`, value: `${formatMoney(taxa[7].VALOR)} %` },
  ];

  return taxas.map((x, indice) => (
    <CustomBadge>
      <span style={{ fontWeight: 'bold' }}>{x.label}:</span> {x.value}
    </CustomBadge>
  ));
};
