import Axios from 'axios';

export const baseIMG = process.env.REACT_APP_BASE_IMG;
const api = Axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    common: {
      jwt: localStorage.getItem('is_authenticate'),
    },
  },
});

export const apiKYC = Axios.create({
  baseURL: process.env.REACT_APP_KYC_API,
});

export const baseURL = process.env.REACT_APP_API;
export default api;
