import { COBAN_ALL, COBAN_TAXA_REQUEST_SUCCESS, COBAN_TAXA_REQUEST_FAIL } from './types';
import api from '../../service/api';
/* eslint-disable */
export const listarCoban = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const { data } = await api.get(`coban/listar`);
      dispatch({
        type: COBAN_ALL,
        payload: { loading_coban: false, list: data },
      });
      callback(true);
    } catch (error) {
      //const { payload } = error.response.data
      dispatch({ type: COBAN_ALL, payload: { loading_user: false, list: [] } });
      // callback(false)
    }
  };
};
export const pegarPerfisCoban = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const { data } = await api.get(`coban/listar/perfilNotificacao`);
      dispatch({
        type: COBAN_ALL,
        payload: { loading_coban: false, list: data },
      });
      callback(true);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_user: false, list: [] } });
    }
  };
};

export const listarCobanID = (id, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const { status, data } = await api.get(`coban/listar?ID=${id}`);
      dispatch({
        type: COBAN_ALL,
        payload: { loading_coban: false, coban: data },
      });
      callback(true);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_user: false } });
      callback(false);
    }
  };
};

export const listarTaxaID = (id, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const { status, data } = await api.get(`coban/taxa/listar?ID_COBAN=${id}`);
      dispatch({
        type: COBAN_ALL,
        payload: {
          loading_coban: false,
          coban: data,
          dataVencimento: data.DIA_VENCIMENTO,
        },
      });
      callback(true);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_user: false } });
      callback(false);
    }
  };
};

export const updateTaxes = (dadosTaxa, callback) => {
  return (dispatch) => {
    dadosTaxa.map(async (taxa, index) => {
      const parametro = {
        ID_COBAN: taxa.ID_COBAN,
        ID_TIPO_TAXA: taxa.ID_TIPO_TAXA,
        VALOR: taxa.VALOR,
      };

      const { status, data } = await api.post('coban/taxa/certificar', parametro);
      if (status == 200) {
        dispatch({
          type: COBAN_TAXA_REQUEST_SUCCESS,
          payload: { ...data, taxa },
        });
      } else {
        dispatch({ type: COBAN_TAXA_REQUEST_FAIL, payload: { ...data, taxa } });
        // alert("Erro!")
      }
    });
  };
};

export const updateFinanciamento = (dadosFinanciamento, PARCELAS, ID_COBAN, callback) => {
  return (dispatch) => {
    const { status, data } = api.post('coban/taxa/financiamento/certificar', {
      ID_COBAN: ID_COBAN.ID_COBAN,
      PARCELAS,
    });
    if (status == 200) {
      dispatch({
        type: COBAN_TAXA_REQUEST_SUCCESS,
        payload: { ...data, data },
      });
    } else {
      dispatch({ type: COBAN_TAXA_REQUEST_FAIL, payload: { ...data, data } });
      //alert("Erro!")
    }
  };
};

export const insertCoban = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: true } });
    try {
      params.CPFCNPJCOBAN = params.CPFCNPJ;
      delete params.CPFCNPJ;
      const { status, data } = await api.post(`coban/inserir`, params);
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      //alert(data.message || data.menssagem || 'Coban inserido com sucesso')
      callback(data, true);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      //alert(error.message || 'Erro ao inserir coban')
      const res = error.response ? error.response.data : { menssage: 'Erro ao inserir' };
      callback(res, false);
    }
  };
};
export const insertPerfil = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: true } });
    try {
      // delete params.NOME
      const { status, data } = await api.post(`coban/perfil/inserir`, params);
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      //alert(data.message || data.menssagem || 'Coban inserido com sucesso')
      callback(data);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      //alert(error.message || 'Erro ao inserir coban')
      const res = error.response ? error.response.data : { menssage: 'Erro ao inserir' };
      callback(res);
    }
  };
};

export const updateCoban = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: true } });
    try {
      params.CPFCNPJCOBAN = params.CPFCNPJ;
      params.CPFCNPJ = undefined;
      const { status, data } = await api.post(`coban/atualizar`, params);
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      callback(data, true);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      const res = error.response ? error.response.data : { menssage: 'Erro ao atualizar.' };
      callback(res, false);
    }
  };
};
export const updatePerfil = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: true } });
    try {
      const { status, data } = await api.post(`/coban/perfil/atualiza`, params);
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      callback(data);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      const res = error.response ? error.response.data : { menssage: 'Erro ao atualizar.' };
      callback(res);
    }
  };
};

export const addDocCoban = (params, callback = () => null) => {
  return async (dispatch) => {
    try {
      params.CPFCNPJCOBAN = params.CPFCNPJ;
      params.CPFCNPJ = undefined;
      const { status, data } = await api.post(`coban/documentos`, params);
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      callback(data, true);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban_crud: false } });
      const res = error.response ? error.response.data : { menssage: 'Erro ao atualizar.' };
      callback(res, false);
    }
  };
};

export const listLevels = (callback = () => null) => {
  return async (dispatch) => {
    try {
      const { status, data } = await api.get(`coban/listar/perfil`);
      dispatch({ type: COBAN_ALL, payload: { listLevels: data.perfis } });
      callback(data, true);
    } catch (error) {
      dispatch({ type: COBAN_ALL });
      const res = error.response ? error.response.data : { menssage: 'Erro ao atualizar.' };
      callback(res, false);
    }
  };
};

export const verifyPassword = (senha, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { status, data } = await api.post(`coban/senha/verificar`, senha);
      dispatch({
        type: COBAN_ALL,
        payload: { mensagem: data.mensagem, sucesso: true },
      });
      callback(data, true);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({
          type: COBAN_ALL,
          payload: { mensagem: error.response.data.mensagem, sucesso: false },
        });
      }
      const res = error.response ? error.response.data : { mensagem: error.response.data.mensagem };
      callback(res, false);
    }
  };

  // if (status == 200) {
  //   dispatch({ type: COBAN_TAXA_REQUEST_SUCCESS, payload: { ...data, data } })
  // }

  // else {
  //   dispatch({ type: COBAN_TAXA_REQUEST_FAIL, payload: { ...data, data } })
  //   //alert("Erro!")
  // }
};

export const smsConfirmacao = (dados, callback = () => null) => {
  return async (dispatch) => {
    try {
      const { status, data } = await api.post(`coban/senha/verificar`, dados);
      dispatch({
        type: COBAN_ALL,
        payload: { mensagem: data.mensagem, sucesso: true },
      });
      callback(data, true);
    } catch (error) {
      dispatch({
        type: COBAN_ALL,
        payload: { mensagem: error.mensagem, sucesso: false },
      });
      const res = error.response ? error.response.data : { menssage: 'Erro ao atualizar.' };
      callback(res, false);
    }
  };
};

export const validationPassword = (senha, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_password: true } });
    try {
      const { status, data } = await api.post(`coban/senha/validar`, senha);
      dispatch({
        type: COBAN_ALL,
        payload: {
          mensagem: data.mensagem,
          sucesso: !data.erro,
          loading_password: false,
        },
      });
      callback(data);
    } catch (error) {
      //dispatch({ type: VERIFY_PASSWORD, payload: { mensagem: error.response.data.mensagem, sucesso: !error.response.data.erro, loading_password: false } })
      const res = error && error.response ? error.response.data : null;
      callback(res);
    }
  };
};

export const alteraVencimentoEmprestimo = (obj, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_password: true } });
    try {
      const { status, data } = await api.post(`/coban/atualizarDataVencimento`, obj);
      dispatch({
        type: COBAN_ALL,
        payload: {
          mensagem: data.mensagem,
          sucesso: !data.erro,
          loading_password: false,
        },
      });
      callback(data);
    } catch (error) {
      //dispatch({ type: VERIFY_PASSWORD, payload: { mensagem: error.response.data.mensagem, sucesso: !error.response.data.erro, loading_password: false } })
      const res = error && error.response ? error.response.data : null;
      callback(res);
    }
  };
};

export const listarCartao = (CPFCNPJ, params = {limit:5, page:1}, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const { limit, page} = params;
      const { status, data } = await api.get(`usuario/listar/cartao?CPFCNPJ_CLIENTE=${CPFCNPJ}&page=${page}&limit=${limit}`);
      dispatch({
        type: COBAN_ALL,
        payload: { loading_coban: false, CARTOES: data },
      });
      callback(true);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban: false, loading_user: false } });
      callback(false);
    }
  };
};

export const listarSaldoGeral = (CPFCNPJ, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const { status, data } = await api.get(`conveniada/saldo-geral?CPFCNPJ_CLIENTE=${CPFCNPJ}`);
      dispatch({
        type: COBAN_ALL,
        payload: { loading_coban: false, SALDO: data },
      });
      callback(true);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_user: false } });
      callback(false);
    }
  };
};

export const salvarSaldoProduto = (PRODUTOS, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const { data } = await api.post(`conveniada/saldo-produto`, {
        PRODUTOS,
      });
      dispatch({
        type: COBAN_ALL,
        payload: { loading_coban: false },
      });
      callback(data);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban: false } });
      callback(false);
    }
  };
};

export const listarCartaoPorProduto = (CPFCNPJ, CODPRODUTO, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const { status, data } = await api.get(
        `conveniada/listar/cartao_produto?CPFCNPJ_CLIENTE=${CPFCNPJ}&CODPRODUTO=${CODPRODUTO}`,
      );
      dispatch({
        type: COBAN_ALL,
        payload: { loading_coban: false, CARTOES_PRODUTOS: data.CARTOES },
      });

      callback(data);
    } catch (error) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban: false, loading_user: false } });
      callback(error);
    }
  };
};

export const adicionandoNovoSaldoCartaoEmLote = (CARTOES_PRODUTOS, fileExcel, callback = () => null) => {
  return async (dispatch) => {
    /* dispatch({ type: COBAN_ALL, payload: { loading_coban: true } }); */
    try {
      const mapCartoes_Produtos =
        CARTOES_PRODUTOS &&
        CARTOES_PRODUTOS.map((cartao) => {
          const { CARGA } = fileExcel.find(({ CODCARTAO }) => +CODCARTAO === +cartao.CODCARTAO);
          if (CARGA) {
            return { ...cartao, NOVO_SALDO: +cartao.SALDO + +CARGA, CARGA };
          } else return cartao;
        });
      dispatch({ type: COBAN_ALL, payload: { CARTOES_PRODUTOS: mapCartoes_Produtos, loading_coban: false } });
    } catch (err) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban: false } });
      callback(err.response.data);
    }
  };
};

export const operarSaldoLote = (CPFCNPJ, CARTOES_PRODUTOS, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: COBAN_ALL, payload: { loading_coban: true } });
    try {
      const mapCartoes = CARTOES_PRODUTOS.filter((cartao) => cartao.hasOwnProperty('NOVO_SALDO')).map((cartao) => ({
        ID_VINCULO: cartao.ID,
        CODCARTAO: cartao.CODCARTAO,
        CODPRODUTO: cartao.CODPRODUTO,
        SALDO_ANTERIOR: cartao.SALDO,
        NOVO_SALDO: cartao.NOVO_SALDO,
      }));
      const { data } = await api.post(`conveniada/saldo-produto/lote`, {
        CPFCNPJ_CLIENTE: CPFCNPJ,
        PRODUTOS: mapCartoes,
      });
      dispatch({ type: COBAN_ALL, payload: { loading_coban: false } });
      callback(data);
    } catch (err) {
      dispatch({ type: COBAN_ALL, payload: { loading_coban: false } });
      callback(err /* .response.data */);
    }
  };
};
