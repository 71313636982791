import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../components/Loader/Loading';
import { getAcessobio } from '../../../store/user/actions';
import ScoreBio from './ScoreBio';
//import ModalScore from './ModalScore'

const ModalAcessobio = (props) => {
  const { modal, toggle, id } = props;

  useEffect(() => {
    props.getAcessobio({ id: id });
  }, []);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Acessobio</ModalHeader>
        {props.user.loading_acessobio ? (
          <Loading color="#11cdef" />
        ) : (
          <ModalBody>
            <ScoreBio data={props.user.ACESSOBIO}></ScoreBio>
          </ModalBody>
        )}
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { getAcessobio })(ModalAcessobio);
