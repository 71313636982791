import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

/* eslint-disable */
const ModalMensagem = (props) => {
  const { modal, toggle, mensagem, sucesso, continuar } = props;
  const mensagemUser = props.user.mensagem;

  function verifyOption() {
    window.location.reload();
    toggle();
  }

  function getTitle() {
    if (sucesso) {
      if (continuar == 'code') return '';

      return 'Sucesso';
    }

    return 'Algo inesperado aconteceu';
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      verifyOption();
    }
  }

  return (
    <Modal onKeyDown={handleKeyDown} isOpen={modal} toggle={() => toggle()}>
      <ModalHeader toggle={() => toggle()}>
        <h1>{getTitle()}</h1>
      </ModalHeader>

      <ModalBody>{mensagem != '' && mensagem != null ? mensagem : mensagemUser}</ModalBody>
      <ModalFooter>
        <Button
          id="success-button"
          color={sucesso == true ? 'success' : 'danger'}
          onClick={() => verifyOption()}
          style={styles.button}
          size="sm"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const styles = {
  button: {
    width: '100%',
  },
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(ModalMensagem);
