import { Card } from 'reactstrap';
import styled from 'styled-components';
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;

  @media (max-width: 1440px) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: 50% 50%;
    grid-gap: 1vw;
    justify-items: stretch;
  }
`;

// export const CardContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 85%;
//   justify-content: flex-end;
// `;

export const CardWrapper = styled(Card)`
  margin: 1vw;
  width: 20vw;

  @media (max-width: 1440px) {
    margin: inherit;
    width: auto;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CardContentTotal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CardTitleContent = styled.div`
  font-size: 14px;
  color: ${(props) => (props.isTotal ? '#000' : '')};
  font-weight: ${(props) => (props.isTotal ? 800 : 500)};
`;

export const BreakLine = styled.hr`
  width: 98%;
  background-color: #f1f1f1;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Value = styled.div`
  color: #a1a1a1;
  font-size: ${(props) => (props.isTotal ? '16px' : '14px')};
  font-weight: ${(props) => (props.isTotal ? 600 : 300)};
`;

export const CardContentSaldo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.isTotal ? '#000' : '')};
  font-weight: ${(props) => (props.isTotal ? 800 : 500)};
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CardElement = styled.div`
  box-shadow: 4px 9px 20px rgb(56 54 54 / 8%), 0px 1px 10px rgb(56 54 54 / 10%);
  border-radius: 10px;
`;
