import React, { useState } from 'react';
import Table from '../../components/Table/Table';
import ModalSegmentos from '../../views/Products/Modal/ModalSegmentos';
import GenericButton from '../Button/Button.style';
import Icon from '../Icon/Icon.styles';
import { CustomBadge, CustomBadgeLink } from './ProductTableStyled';

export default function ProductTable({ products, setModalEditProduto, setEdit, pagination, page, setPage }) {
  const [isOpen, setIsOpen] = useState(false);
  const [segmentos, setSegmentos] = useState([]);

  function handleEdit(id) {
    setEdit(id);
    setModalEditProduto(true);
  }

  function handleOpen() {
    setIsOpen(!isOpen);
  }

  function openSegmentsModal(segments) {
    setSegmentos(segments);
    handleOpen();
  }

  function showSegments(segments) {
    if (segments.length === 0) {
      return <CustomBadge style={{ margin: '.2rem' }}>{'SEM SEGMENTOS'}</CustomBadge>;
    }
    if (segments.length > 3) {
      const slicedArray = segments.slice(0, 3);
      const result = slicedArray.map((segment) => (
        <CustomBadge style={{ margin: '.2rem' }}>{segment.DESCRICAO}</CustomBadge>
      ));
      result.push(
        <CustomBadgeLink size="sm" onClick={() => openSegmentsModal(segments)}>
          VER MAIS...
        </CustomBadgeLink>,
      );
      return result;
    }

    return segments.map((segment) => (
      <CustomBadge style={{ margin: '.2rem' }} color="primary">
        {segment.DESCRICAO}
      </CustomBadge>
    ));
  }

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        setPage(pagination.prevPage);
        break;
      case 'next':
        setPage(pagination.nextPage);
        break;
      default:
        if (pageClick) setPage(pageClick);
        break;
    }
  };

  return (
    <>
      <Table
        style={{ marginTop: '3rem' }}
        size="sm"
        headers={[
          {
            info: 'Nome',
            style: { textAlign: 'left' },
          },
          {
            info: 'Segmentos',
            style: { textAlign: 'center' },
          },
          {
            info: 'Cartões Vinculados',
            style: { textAlign: 'center' },
          },
          /*           {
            info: 'Ativo',
            style: { textAlign: 'center' },
          }, */
          {
            info: 'Ações',
            style: { textAlign: 'right', paddingRight: '3.4rem' },
          },
        ]}
        data={
          products &&
          products.map((product) => {
            return {
              data: [
                {
                  info: product.DESCRICAO && product.DESCRICAO,
                  style: { textAlign: 'left' },
                },
                {
                  info: product.SEGMENTO && showSegments(product.SEGMENTO),
                  style: { display: 'flex', justifyContent: 'flex-start' },
                },
                {
                  info: product.CARTOES && product.CARTOES,
                  style: { textAlign: 'center' },
                },
                /*                 {
                  info: product.ATIVO == 'S' ? <FcCheckmark size="16px" /> : <AiOutlineClose color="red" size="16px" />,
                  style: { textAlign: 'center' },
                }, */
                {
                  info: (
                    <GenericButton onClick={() => handleEdit(product.ID)} color="info " size="sm">
                      <Icon className="fas fa-edit"></Icon>
                      Editar
                    </GenericButton>
                  ),
                  style: { display: 'flex', justifyContent: 'flex-end' },
                },
              ],
            };
          })
        }
        pagination={pagination}
        previousPage={{ onClick: () => tablePagination('prev') }}
        nowPage={{ page, onClick: pagination }}
        nextPage={{ onClick: () => tablePagination('next') }}
      />
      <ModalSegmentos isOpen={isOpen} handleOpen={handleOpen} segmentos={segmentos} />
    </>
  );
}
