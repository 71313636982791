import React from 'react';
import { connect } from 'react-redux';
import Table from '../../components/Table/Table.jsx';
import formatCNPJ from '../../utils/formatCNPJ.js';
import formatCPF from '../../utils/formatCPF';
import valueEmpty from '../../utils/valueEmpty.js';

// reactstrap components
import { Row } from 'reactstrap';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import FilterWithdrawals from '../../components/Filter/FilterWithdrawals';
import Loading from '../../components/Loader/Loading.jsx';
import StatusContainerTed from '../../components/StatusContainer/StatusContainerTed.js';
import StatusContainerValue from '../../components/StatusContainer/StatusContainerValue.js';
import { getWithdrawalTransactions, getWithdrawalTransactionsID } from '../../store/transactions/actions.js';
import ModalInfoWithdrawals from './ModalInfoTransfer.jsx';
// import { centerItem } from '../../utils/constants.js';

/* eslint-disable */
class Withdrawals extends React.Component {
  state = { modal: false, saleInfos: {}, page: 1 };

  clickInfo = (ID, IDSTATUS) => {
    this.props.getWithdrawalTransactionsID(ID, (res) => {
      if (res) this.setState({ saleInfos: { ...res, IDSTATUS }, modal: true });
    });
  };

  close = () => this.setState({ modal: !this.state.modal, saleInfos: {} });

  resetPage = () => {
    this.setState({ page: 1 });
  };

  pagination = async (type = 'atual', pageClick) => {
    var { page } = this.state;
    var { pagination, filtro } = this.props.transactions;

    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await this.setState({ page });
    this.props.getWithdrawalTransactions({ ...filtro, page });
  };

  renderTableSaque() {
    const { listWithdrawals, DADOS_SAQUES, loading_transaction, pagination } = this.props.transactions;
    const { page } = this.state;
    return (
      <div>
        <Table
          headers={[
            {
              info: 'Data/Hora',
              style: { textAlign: 'center' },
            },
            {
              info: 'CPF/CNPJ',
              style: { textAlign: 'left' /* paddingRight: '4rem' */ },
            },
            {
              info: 'Nome',
              style: { textAlign: 'center', paddingLeft: '3rem', paddingRight: '3rem' },
            },
            {
              info: 'Banco',
              style: { textAlign: 'left' },
            },
            {
              info: 'Ag',
              style: { textAlign: 'left' },
            },
            {
              info: 'Conta',
              style: { textAlign: 'left' },
            },
            {
              info: 'Tipo Pagamento',
              style: { textAlign: 'left' },
            },
            {
              info: 'Parceiro',
              style: { textAlign: 'left' },
            },

            {
              info: 'Valor',
              style: { textAlign: 'center' },
            },
            {
              info: 'STATUS',
              style: { textAlign: 'center' },
            },
          ]}
          data={listWithdrawals.map((saque, index) => {
            return {
              data: [
                {
                  info: valueEmpty(saque.DATA),
                  style: { textAlign: 'center' },
                },
                {
                  info:
                    valueEmpty(saque.CPFCNPJ.length) == 11
                      ? formatCPF(valueEmpty(saque.CPFCNPJ))
                      : formatCNPJ(valueEmpty(saque.CPFCNPJ)),
                  style: { textAlign: 'left' },
                },
                {
                  info: valueEmpty(saque.NOME),
                  style: { textAlign: 'center' },
                },
                {
                  info: valueEmpty(saque.BANCO),
                  style: { textAlign: 'left' },
                },
                {
                  info: valueEmpty(saque.AGENCIA),
                  style: { textAlign: 'left' },
                },
                {
                  info: valueEmpty(saque.CONTA),
                  style: { textAlign: 'left' },
                },
                {
                  info: valueEmpty(saque.DESCRICAO),
                  style: { textAlign: 'left' },
                },
                {
                  info: saque.TIPO_USUARIO ? (
                    <StatusContainerTed
                      code={saque.TIPO_USUARIO.toUpperCase()}
                      description={saque.TIPO_USUARIO}
                    ></StatusContainerTed>
                  ) : (
                    '-----'
                  ),
                  style: { textAlign: 'center' },
                },

                {
                  info: valueEmpty(
                    saque.VALOR ? <StatusContainerValue code={saque.TIPO_TRANSACAO} description={saque.VALOR} /> : null,
                  ),
                  style: { textAlign: 'center', whiteSpace: 'nowrap' },
                },
                {
                  info: valueEmpty(
                    saque.DESCRICAOSTATUS ? (
                      <StatusContainerTed code={saque.STATUSTRANSACAO} description={saque.DESCRICAOSTATUS} />
                    ) : null,
                  ),
                  style: { textAlign: 'center' },
                },
              ],

              onClick: () => this.clickInfo(saque.ID, saque.STATUSTRANSACAO),
            };
          })}
          pagination={pagination}
          previousPage={{ onClick: () => this.pagination('prev') }}
          nowPage={{ page, onClick: this.pagination }}
          nextPage={{ onClick: () => this.pagination('next') }}
        />
      </div>
    );
  }

  render() {
    const { listWithdrawals, DADOS_SAQUES, loading_transaction } = this.props.transactions;
    const { page } = this.state;
    return (
      <div>
        <CustomContainer>
          <Row>
            <div className="col">
              <FilterWithdrawals identificador={'saque'} reset={(el) => this.resetPage(el)}></FilterWithdrawals>

              {loading_transaction ? <Loading color="#11cdef" /> : this.renderTableSaque()}
            </div>
          </Row>
          <ModalInfoWithdrawals
            toggle={this.close}
            modal={this.state.modal}
            title={'Dados da Transferência'}
            data={this.state.saleInfos}
          />
        </CustomContainer>
      </div>
    );
  }
}

const styles = {
  form: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
  },
};
const mapStateToProps = ({ auth, transactions }) => {
  return { auth, transactions };
};

export default connect(mapStateToProps, {
  getWithdrawalTransactions,
  getWithdrawalTransactionsID,
})(Withdrawals);
