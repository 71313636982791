import React from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { blockUserCard } from '../../../../store/user/actions';
import { useDispatch } from 'react-redux';

/* eslint-disable */

const Confirmacao = (props) => {
  const dispatch = useDispatch();
  return (
    <Modal
      isOpen={props.modalConfirmacao}
      modalTransition={{ timeout: 700 }}
      backdropTransition={{ timeout: 1300 }}
      toggle={() => {
        props.setCartao((prev) => ({ ...prev, ID: 0 }));
        props.toggleConfirmacaoCallback();
      }}
    >
      <ModalHeader
        toggle={() => {
          props.setCartao((prev) => ({ ...prev, ID: 0 }));
          props.toggleConfirmacaoCallback();
        }}
      ></ModalHeader>
      <FormGroup>
        <ModalBody>
          <span>Deseja mesmo {props.state.BLOQUEADO == 1 ? 'bloquear' : 'desbloquear'} este cartão?</span>
        </ModalBody>
      </FormGroup>
      <ModalFooter>
        <Button
          color="secondary"
          size="sm"
          onClick={() => {
            props.toggleConfirmacaoCallback();
          }}
          block
        >
          Cancelar
        </Button>
        <Button
          color="success"
          size="sm"
          onClick={() => {
            props.setLoaded(true);
            dispatch(
              blockUserCard(
                {
                  CPFCNPJ_CLIENTE: props.user.CPFCNPJ,
                  BLOQUEADO: props.state.BLOQUEADO,
                  ID: props.state.ID,
                  DATA_VENCIMENTO: props.state.DATA_VENCIMENTO,
                  PARCEIRO: props.state.PARCEIRO,
                },
                (res) => {
                  props.setLoaded(false);
                  props.openModalMessageActionCallback(res, 'Bloqueado');
                },
              ),
            );
            props.toggleConfirmacaoCallback();
          }}
          block
        >
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Confirmacao;
