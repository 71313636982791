import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { EditCard } from '../../../../assets/icons';
import Loading from '../../../../components/Loader/Loading';
import Table from '../../../../components/Table/Table';
import { listarCartao, listarCartaoPorProduto } from '../../../../store/coban/actions';
import { adicionarConveniadaProdutoEmLote } from '../../../../store/user/actions';
import DigitalAccountBalaceContainer from '../DigitalAccountBalaceContainer/DigitalAccountBalaceContainer';
import { ButonContainer } from '../DigitalAccountBalaceContainer/style';
import { AdicionarProduto } from '../modals';
import ModalLote from '../modals/components/ModalLote/ModalLote';
import ProductCardsCard from '../ProductsCardsCard/ProductCardsCard';
import { useEffect } from 'react';
import {
  listarProdutosCartaoVerificarMCCEmLote,
  selectAdicionarProduto,
  selectOperarSaldo,
} from '../../../../store/swap/actions';

/* eslint-disable */
import InputSelectAmountResults from '../../../../components/Filter/components/InputSelectAmountResults';

function AccordionCardsUser({
  allCards: { CARTOES, pagination },
  saldoGeral,
  setCard,
  setInfoCard,
  user,
  searchWithFilter,
}) {
  const { loading_coban } = useSelector((state) => state.coban);
  const [modalAddProduto, setModalAddProduto] = useState(false);
  const [modalOperate, setModalOperate] = useState(false);
  const [modalAddProductBatch, setModalAddProductBatch] = useState(false);
  const [modalOperateBalanceProductBatch, setModalOperateBalanceProductBatch] = useState(false);
  const { listagemProduto, selectAddProduct, selectOperate } = useSelector((seg) => seg.swap);
  const [alertError, setAlertError] = useState('');
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = useState('');
  const [filter, setFilter] = useState({
    limit: 5,
    search: '',
    page: 1,
    status: 0,
    dataInicial: '',
    dataFinal: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    dispatch(selectOperarSaldo(user.CPFCNPJ));
    dispatch(selectAdicionarProduto(user.CPFCNPJ));
  }, []);

  function checkMcc(codProduto) {
    /* dispatch(adicionarConveniadaProdutoEmLote(user.CPFCNPJ, selectValue)); */
    dispatch(
      listarProdutosCartaoVerificarMCCEmLote(user.CPFCNPJ, codProduto, (res) => {
        if (res.retorno === 'erro') {
          setAlertError(res.mensagem);
        } else {
          setAlertError('');
        }
      }),
    );
  }

  function handleOpenModalBalance() {
    setModalOperateBalanceProductBatch(true);
    dispatch(listarCartaoPorProduto(user.CPFCNPJ, selectValue));
  }

  function handleAddProductInBatch() {
    dispatch(adicionarConveniadaProdutoEmLote(user.CPFCNPJ, selectValue));
  }

  function closeModalAdd() {
    setModalAddProductBatch(false);
    setSelectValue('');
  }

  function closeModalOperate() {
    setModalOperate(false);
    setSelectValue('');
  }

  let { page } = filter;

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page });
  };

  useEffect(() => {
    dispatch(listarCartao(user.CPFCNPJ, filter));
  }, [filter.page, filter.limit]);

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-content">
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
            Cartões
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <ButonContainer>
              <Button
                variant="contained"
                sx={{ marginBottom: '1rem', backgroundColor: '#0245AE' }}
                onClick={() => setModalAddProductBatch(true)}
              >
                Adicionar Produto
              </Button>
              <Button
                variant="contained"
                sx={{ marginBottom: '1rem', backgroundColor: '#0092D4' }}
                onClick={() => setModalOperate(true)}
                style={{ marginLeft: '1%' }}
              >
                Operar Saldo
              </Button>
            </ButonContainer>

            {CARTOES && CARTOES.length != 0 ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <InputSelectAmountResults
                    options={['5', '10', '25', '50', '100']}
                    value={filter.limit}
                    setValue={(value) => setFilter({ ...filter, limit: value })}
                  />
                  <DigitalAccountBalaceContainer
                    user={user}
                    searchWithFilter={searchWithFilter}
                    saldoGeral={saldoGeral}
                  />
                </div>
                {loading_coban ? (
                  <Loading color="#2405f2" />
                ) : (
                  <div id="toggler" className="mt-3">
                    <Table
                      size="sm"
                      headers={[
                        {
                          info: 'Tipo',
                          style: { textAlign: 'left' },
                        },
                        {
                          info: 'Número',
                          style: { textAlign: 'left' },
                        },

                        {
                          info: 'PRODUTOS',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: 'SALDO PRODUTOS',
                          style: { textAlign: 'center' },
                        },

                        {
                          info: 'VER MAIS',
                          style: { textAlign: 'center' },
                        },
                      ]}
                      data={
                        CARTOES &&
                        CARTOES.map((cartao, index) => {
                          return {
                            data: [
                              {
                                info: cartao.ROTULO ? cartao.ROTULO : cartao.TIPO,
                                style: { textAlign: 'left' },
                              },

                              {
                                info: cartao.NUMERO,
                                style: { textAlign: 'left' },
                              },

                              {
                                info: (
                                  <ProductCardsCard
                                    allCards={cartao.PRODUTOS}
                                    setInfoCard={setInfoCard}
                                    cartao={cartao}
                                    c
                                  />
                                ),
                                style: { textAlign: 'center' },
                              },

                              {
                                info: cartao.SALDO_GERAL ? 'R$ ' + cartao.SALDO_GERAL : 'R$ 0,00',
                                style: { textAlign: 'center' },
                              },

                              {
                                info: (
                                  <div onClick={() => setInfoCard({ open: true, cartao: cartao, SALDO: saldoGeral })}>
                                    <EditCard />
                                  </div>
                                ),
                                style: { textAlign: 'center', cursor: 'pointer' },
                              },
                            ],
                          };
                        })
                      }
                      pagination={pagination}
                      previousPage={{ onClick: () => tablePagination('prev') }}
                      nowPage={{ page, onClick: pagination }}
                      nextPage={{ onClick: () => tablePagination('next') }}
                    />
                  </div>
                )}
              </>
            ) : (
              <div id="toggler" className="mt-3" style={{ background: '#F2F2F2', padding: '0 0 0.8rem 0' }}>
                <Table
                  size="sm"
                  headers={[
                    {
                      info: 'Tipo',
                      style: { textAlign: 'left' },
                    },
                    {
                      info: 'Número',
                      style: { textAlign: 'left' },
                    },

                    {
                      info: 'PRODUTOS',
                      style: { textAlign: 'center' },
                    },
                    {
                      info: 'SALDO PRODUTOS',
                      style: { textAlign: 'center' },
                    },

                    {
                      info: 'VER MAIS',
                      style: { textAlign: 'center' },
                    },
                  ]}
                />
                <Row
                  className="mb-2"
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ color: '#525F7F', fontWeight: '600' }}>O CLIENTE NÃO POSSUI CARTÕES</div>
                </Row>
              </div>
            )}
          </div>
        </AccordionDetails>
        <AdicionarProduto open={modalAddProduto} close={() => setModalAddProduto(false)} />
        <ModalLote
          allCards={CARTOES}
          saldoGeral={saldoGeral}
          openPL={modalOperate}
          closePL={() => closeModalOperate()}
          title={'Operar saldo em lote'}
          buttonName={'Continuar'}
          setSelectValue={setSelectValue}
          modalOperateBalanceProductBatch={modalOperateBalanceProductBatch}
          handleClick={handleOpenModalBalance}
          setModalOperateBalanceProductBatch={setModalOperateBalanceProductBatch}
          listagemProduto={selectOperate}
          selectValue={selectValue}
        />
        <ModalLote
          selectValue={selectValue}
          alertError={alertError}
          checkMcc={checkMcc}
          allCards={CARTOES}
          saldoGeral={saldoGeral}
          openPL={modalAddProductBatch}
          closePL={() => closeModalAdd()}
          title={'Adicionar produto em lote'}
          buttonName={'Adicionar'}
          setSelectValue={setSelectValue}
          handleClick={handleAddProductInBatch}
          setModalOperateBalanceProductBatch={setModalOperateBalanceProductBatch}
          listagemProduto={selectAddProduct}
        />
      </Accordion>
    </>
  );
}

export default AccordionCardsUser;
