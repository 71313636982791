import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AlertModal from '../../../components/AlertModal/AlertModal';
import { approval, getUsers } from '../../../store/user/actions';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import Loading from '../../Loader/Loading';
import ModalInfos from './ModalInfos.js';
import ModalScore from './ModalScore';

/* eslint-disable */

const ModalCliente = (props) => {
  const dispatch = useDispatch();
  const { modal, toggle } = props;

  const { loading_user, user } = props.user;
  const [modalIsScore, setModalIsScore] = useState(false);
  const [denyClient, setDenyClient] = useState(false);
  const [renderButton, setRenderButton] = useState(true);
  const [ERRO_STATUS, setERRO_STATUS] = useState('');
  const [alertModal, setModalAlert] = useState(false);
  const [messageModal, setModalMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [sucesso, setSucesso] = useState(false);

  const { risco } = useSelector((state) => state.user);

  const getTypeModal = (isScore, dados) => {
    if (isScore) return <ModalScore user={dados} />;
    else
      return (
        <ModalInfos
          consultas={props.user.user}
          userInfos={dados}
          risco={risco}
          toggle={() => setModalIsScore(!modalIsScore)}
        />
      );
  };
  let aShow;
  if (localStorage.getItem('nivelAcesso') != '6' && user && user.ID_TIPO_USUARIO == '1') {
    aShow = true;
  }
  if (localStorage.getItem('nivelAcesso') == '6' && user && user.ID_TIPO_USUARIO == '2') {
    aShow = true;
  }

  const action = (type, params) => {
    setRenderButton(!renderButton);
    if (!denyClient && type == 'recusar') setDenyClient(true);
    else
      props.approval(type, params, (res) => {
        const { mensagem, retorno } = res;
        retorno && retorno === 'sucesso' ? setSucesso(true) : setSucesso(false);

        setMessage(mensagem);
        setModalMessage(true);
      });
  };

  const close = async (header = false) => {
    if (header) {
      if (modalIsScore) setModalIsScore(!modalIsScore);
      else if (denyClient) setDenyClient(!denyClient);
      else toggle();
    } else {
      setModalIsScore(false);
      setDenyClient(false);
      toggle();
    }
  };

  const back = () => {
    setDenyClient(!denyClient);
    setRenderButton(!renderButton);
  };

  const toggleAlert = () => {
    setModalAlert(!alertModal);
  };

  const validateUser = () => {
    if (user.BIN != user.confirmarBIN) {
      setMessage('Os dados do cartão não correspondem.');
      toggleAlert();
    }

    if (user.ID_COBAN == 0) {
      setMessage('Selecione o correspondente bancário.');
      toggleAlert();
    } else
      return action('aprovar', {
        CPFUSUARIO: user.CPFCNPJ,
        ID_COBAN: user.ID_COBAN,
        BIN: user.BIN,
        CVV: user.CVV,
        VALIDADE: user.VALIDADE,
        PDV: user.PDV,
        /*    RISCO_PLD: user.RISCO_PLD, */
      });
  };

  const validateUserDigitalAccount = () => {
    if (user.BIN != user.confirmarBIN) {
      setMessage('Os dados do cartão não correspondem.');
      toggleAlert();
    }

    if (user.ID_COBAN == 0) {
      setMessage('Selecione o correspondente bancário.');
      toggleAlert();
    } else if (user.RISCO_PLD == null) {
      setMessage('Selecione o Risco PLD.');
      toggleAlert();
    } else
      return action('aprovar-conta-digital', {
        CPFUSUARIO: user.CPFCNPJ,
        ID_COBAN: user.ID_COBAN,
        BIN: user.BIN,
        CVV: user.CVV,
        VALIDADE: user.VALIDADE,
        PDV: user.PDV,
        RISCO_PLD: user.RISCO_PLD,
      });
  };
  return (
    <Modal
      size="lg"
      isOpen={modal}
      toggle={() => close(false)}
      onOpened={() => {
        setERRO_STATUS('');
      }}
    >
      <ModalHeader toggle={() => close(true)}>
        <h2>Dados Cliente</h2>
      </ModalHeader>

      <ModalBody>
        {loading_user && <Loading color="#11cdef" />}
        {props.userData
          ? getTypeModal(false, props.userData)
          : user && !loading_user && !denyClient && getTypeModal(modalIsScore, user)}
        {denyClient && (
          <FormGroup>
            <Label>Observações</Label>
            <Input
              onChange={({ target }) => setERRO_STATUS(target.value)}
              type="textarea"
              name="text"
              id="exampleText"
              autoFocus={denyClient}
              required
            />
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
        {!modalIsScore && renderButton && (
          <>
            {aShow ? (
              <Button
                color="danger"
                size="sm"
                block
                disabled={loading_user}
                onClick={() => action('recusar', { CPFUSUARIO: user.CPFCNPJ, ERRO_STATUS })}
              >
                Recusar
              </Button>
            ) : null}
            {user && user.STATUS == 6 && aShow ? (
              <Button
                color="success"
                size="sm"
                block
                disabled={loading_user}
                onClick={() => {
                  validateUserDigitalAccount();
                }}
              >
                Aceitar
              </Button>
            ) : null}
            {user && user.STATUS == 1 && aShow ? (
              <Button
                color="success"
                size="sm"
                block
                disabled={loading_user}
                onClick={() => {
                  validateUser();
                }}
              >
                Aceitar
              </Button>
            ) : null}
          </>
        )}
        {!renderButton && (
          <>
            <Button color="secondary" size="sm" block disabled={loading_user} onClick={() => back()}>
              Voltar
            </Button>
            <Button
              color="success"
              size="sm"
              block
              disabled={loading_user}
              onClick={() => action('recusar', { CPFUSUARIO: user.CPFCNPJ, ERRO_STATUS })}
            >
              Confirmar
            </Button>
          </>
        )}
        <AlertModal mensagem={message} toggle={() => toggleAlert()} alertModal={alertModal} />
        <ModalMensagem
          modal={messageModal}
          toggle={() => setModalMessage(false)}
          sucesso={sucesso}
          mensagem={message}
        />
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { approval, getUsers })(ModalCliente);
