import React from 'react';
import { Controller } from 'react-hook-form';
import * as Styled from './TextField.styles';

export default function HybridField({
  title,
  disabled,
  value,
  width,
  height,
  margin,
  required,
  name,
  control,
  error,
  type,
  inputProps,
  multiline,
  fullWidth,
  variant,
  color,
  onInput,
}) {
  return (
    <Styled.Container width={width} height={height} margin={margin}>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field }) => (
          <Styled.Input
            {...field}
            width={width}
            height={height}
            error={error}
            defaultValue={value}
            disabled={disabled}
            required={required}
            inputProps={inputProps}
            type={type}
            multiline={multiline}
            fullWidth={fullWidth}
            label={title}
            variant={variant}
            color={color}
            onInput={onInput}
          />
        )}
      ></Controller>
    </Styled.Container>
  );
}
