import React from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from '@mui/material/Typography';
import * as S from './Accordion.styles';

const Accordion = (props) => {
  return (
    <div style={{ padding: '0.3rem', width: '100%' }}>
      <S.Accordion expanded={props.expanded === props.open} onChange={props.handleChange(props.open)}>
        <S.AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', transform: 'rotate(90deg)', color: '#fff' }} />
          }
        >
          <Typography>{props.title}</Typography>
        </S.AccordionSummary>
        <S.AccordionDetails>{props.children}</S.AccordionDetails>
      </S.Accordion>
    </div>
  );
};

export default Accordion;
