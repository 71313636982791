import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { blockUser, desblockUserCard, unlock } from '../../../store/user/actions';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';

/* eslint-disable */

const ModalBloquear = (props) => {
  const { isOpen, modalTransition, backdropTransition, toggle } = props;

  const [mensagem, setMensagem] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [visible, setVisible] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  const onDismiss = () => setVisible(!visible);

  const user = props.user.user || props.userData;
  const params = { CPFUSUARIO: user.CPFCNPJ, MOTIVO: mensagem };
  const handle = (target) => {
    setMensagem(target);
  };

  const openModalMessage = (res) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);

    setMensagem(mensagem);
    setModalMensagem(true);
  };

  const click = () => {
    if (mensagem == '') {
      setVisible(!visible);
      setMensagemAlerta('Preencha os campos');
    } else if (mensagem != '' && (user.STATUS == 2 || user.STATUS == 5 || user.STATUS == 6 || user.STATUS == 7)) {
      props.blockUser(params, (res) => {
        openModalMessage(res);
      });
    } else {
      props.unlock(params, (res) => {
        openModalMessage(res);
      });
    }
  };

  const alert = () => {
    return (
      <Alert color="danger" isOpen={visible} toggle={() => onDismiss()}>
        {mensagemAlerta}
      </Alert>
    );
  };

  return (
    <Modal isOpen={isOpen} modalTransition={modalTransition} backdropTransition={backdropTransition} toggle={toggle}>
      <ModalHeader toggle={() => toggle()}>
        {' '}
        {user.STATUS == 2 || user.STATUS == 5 || user.STATUS == 6 || user.STATUS == 7
          ? ' Bloquear'
          : user.STATUS == 4
          ? ' Desbloquear'
          : null}{' '}
        Usuário
      </ModalHeader>
      <FormGroup>
        <ModalBody>
          <span>
            Motivo do
            {user.STATUS == 2 || user.STATUS == 5 || user.STATUS == 6 || user.STATUS == 7
              ? ' bloqueio'
              : user.STATUS == 4
              ? ' desbloqueio'
              : null}{' '}
          </span>
          <Input placeholder="Descreva..." size="sm" onChange={(e) => handle(e.target.value)}></Input>
          {alert()}
        </ModalBody>
      </FormGroup>
      <ModalFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button style={{ marginTop: '8px' }} color="secondary" size="sm" onClick={() => toggle()} block>
            Cancelar
          </Button>
          <Button color="success" size="sm" onClick={() => click()} block>
            Confirmar
          </Button>
        </div>
      </ModalFooter>

      <ModalMensagem modal={modalMensagem} mensagem={mensagem} sucesso={sucesso} toggle={() => setModalMensagem} />
    </Modal>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  blockUser,
  unlock,
  desblockUserCard,
})(ModalBloquear);
