import { Card as CardMaterial } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

export const CardComponent = styled(CardMaterial)`
  min-width: ${(props) => props.width || '18vw'} !important;
  height: ${(props) => props.height || 'auto'} !important;
  margin-top: ${(props) => props.marginTop || '0'} !important;
  display: flex;
  transition: 1s !important;
  box-shadow: none;
  border-radius: 1rem;

  @media screen and (max-width: 1280px) {
    width: ${(props) => props.width || '16vw'} !important;
  }
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1vw;
`;

export const LineDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1vw;
`;

export const LineValue = styled(Line)`
  flex-direction: column;
`;

export const IconContainer = styled.div`
  border-radius: 50%;
  width: 2.778vw;
  height: 2.778vw;
  background-color: ${(props) => props.backgroundColor} !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${(props) => props.iconColor} !important;
  justify-content: center;
`;
export const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.944vw 0 0 0;
  gap: 1vw;

  & span {
  }
`;
export const Name = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2.014vw;
  letter-spacing: 0.01vw;
`;

export const NameValue = styled(Name)`
  font-weight: 400;
  font-size: 0.77vw;
  margin: 0;
`;

export const Button = styled(IconButton)``;

export const Text = styled.h5`
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25vw;
  letter-spacing: 0.028vw;
  text-align: center;
  color: #909194;
`;

export const Value = styled.h3`
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  line-height: 2.5vw;
  margin-bottom: 1vw;
`;

export const TextInCard = styled.h3`
  font-size: 0.77vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.458vw;
  color: black;
  letter-spacing: 0.007vw;
  text-align: left;
  width: 8rem;
  white-space: nowrap;
`;

export const LineInCard = styled.div`
  display: ${(props) => props.display};
  justify-content: space-between;
  margin: 0.147vw 1vw;
`;

export const ValueInCard = styled.h3`
  font-size: 1vw;
  font-style: normal;
  font-weight: 900;
  line-height: 1.458vw;
  letter-spacing: 0.007vw;
  text-align: right;
`;

export const Rectangle = styled.div`
  background-color: ${(props) => props.color};
  width: 0.694vw;
  height: 100%;
`;

export const Container = styled.div`
  flex-grow: 1;
`;

export const LineSeparator = styled.hr`
  border: 0.1px solid rgba(51, 51, 51, 0.2);
  width: 93%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0rem;
  margin-bottom: 0rem;
`;
