import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';

import { Alert } from '@mui/material';
import { Trash } from '../../../../../../assets/icons';
import Select from '../../../../../../components/Select/Select';
import {
  deletarProdutosCartao,
  listarProduto,
  listarProdutosCartao,
  listarProdutosCartaoVerificarMCC,
  produtosVinculados,
} from '../../../../../../store/swap/actions';
import { adicionarConveniadaProduto } from '../../../../../../store/user/actions';
import formatMoney from '../../../../../../utils/formatMoneyBRL';
import ModalMensagemSemReload from '../../../../../Transaction/ModalMensagemSemReload';
import { SaldoDisponivelColor, SaldoStyle } from '../ModalCardUser/ModalCardUser.styles';
import ModalConfirmacao from './components/ModalConfirmacao';
import * as Styled from './ModalVincularProduto.styles';

/* eslint-disable */

export default function ModalVincularProduto(props) {
  const dispatch = useDispatch();
  const [novoProduto, setNovoProduto] = useState([]);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [modalConfirmacao, setModalConfimarcao] = useState({ open: false, produto: {} });
  const [alertaErro, setalertaErro] = useState([]);
  const [alertaErroMensagem, setalertaErroMensagem] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [saldo, setSaldo] = useState({});

  const { CARTAO_PRODUTOS } = useSelector((state) => state.swap);
  const { SALDO } = useSelector((state) => state.coban);

  novoProduto.map((val, ind) => {
    val.SALDO_ERRO = val.SALDO.length > 0 ? false : true;

    return val;
  });

  useEffect(() => {
    dispatch(produtosVinculados(props.idSwap, (res) => {}));
    dispatch(listarProduto());
  }, []);

  useEffect(() => {
    dispatch(produtosVinculados(props.idSwap, (res) => {}));
    dispatch(listarProduto());
    setSaldo(SALDO);
  }, [props.modal]);

  const handleClick = (e) => {
    e.preventDefault();
    const param = novoProduto.map((e) => {
      let array = {
        CODCARTAO: props.idCartao,
        CODPRODUTO: e.SELECT,
        SALDO: e.SALDO,
      };
      return array;
    });
    dispatch(
      adicionarConveniadaProduto(param, (res) => {
        setModalMensagem(true);
        setMensagem(res.mensagem);
        if (res.retorno === 'sucesso') {
          setNovoProduto([]);
          dispatch(listarProdutosCartao(props.cpfcnpj, props.idCartao));
          setSucesso(true);
          props.setReloadProdutos(!props.reloadProdutos);
        } else {
          setSucesso(false);
        }
      }),
    );
  };

  const addProduto = () => {
    const initial = { SELECT: '', SALDO: '', SALDO_ERRO: true, SELECT_ERRO: true };
    const newProduto = [...novoProduto];
    newProduto.push(initial);
    setNovoProduto(newProduto);
  };

  const { listagemProduto, loadingProduct } = useSelector((seg) => seg.swap);

  let listagemAtiva = [];
  if (loadingProduct === false && CARTAO_PRODUTOS) {
    listagemAtiva =
      listagemProduto &&
      listagemProduto.filter((e) => e.ATIVO === 'S' && !CARTAO_PRODUTOS.some((produto) => e.ID == produto.CODPRODUTO));
  }

  const changeProduto = async ({ target: { name, value } }, indice) => {
    let values = [...novoProduto];
    if (name === 'SELECT') {
      await dispatch(
        listarProdutosCartaoVerificarMCC(props.idCartao, value, (res) => {
          if (res.retorno === 'erro') {
            let newAlertaErro = [...alertaErro];
            if (!newAlertaErro.length || newAlertaErro.some((erro) => erro !== indice)) {
              newAlertaErro.push(indice);
              values[indice]['SELECT_ERRO'] = true;
            }
            setalertaErro(newAlertaErro);
            setalertaErroMensagem(res.mensagem);
          } else {
            setalertaErro((prev) => prev.filter((i) => i !== indice));
            values[indice]['SELECT_ERRO'] = false;
          }
        }),
      );
    } else {
      setSaldo((prev) => ({ ...prev, SALDO_DISPONIVEL: SALDO.SALDO_DISPONIVEL - +value }));
    }

    values[indice][name] = value;

    setNovoProduto(values);
  };

  const deleteProduto = (indiceArray, produto = null) => {
    if (produto != null) {
      dispatch(
        deletarProdutosCartao(produto.ID, (res) => {
          if (res.retorno === 'sucesso') {
            dispatch(listarProdutosCartao(props.cpfcnpj, props.idCartao));
            props.setReloadProdutos(!props.reloadProdutos);
            setSaldo((prev) => ({ ...prev, SALDO_DISPONIVEL: prev.SALDO_DISPONIVEL + +produto.SALDO }));
          }
        }),
      );
    }

    let values = novoProduto.filter((produto, indice) => {
      if (indice !== indiceArray) {
        return produto;
      }
    });

    setNovoProduto(values);
    setSaldo(SALDO);
  };

  return (
    <>
      <Modal
        isOpen={props.modal}
        toggle={() => {
          props.toggle();
          setNovoProduto([]);
        }}
      >
        <ModalHeader
          toggle={() => {
            props.toggle();
            setNovoProduto([]);
          }}
        >
          <h2>Vincular Produto</h2>
        </ModalHeader>
        <Form>
          <FormGroup>
            <ModalBody>
              {alertaErro.length ? <Alert severity="error">{alertaErroMensagem}</Alert> : null}
              {saldo && saldo.SALDO_DISPONIVEL < 0 ? (
                <Alert severity="error">Não é possível salvar negativado</Alert>
              ) : null}
              {saldo && (
                <SaldoStyle>
                  Saldo disponível: R$
                  <SaldoDisponivelColor color={saldo.SALDO_DISPONIVEL >= '0' ? 'positivo' : 'negativo'}>
                    {formatMoney(saldo.SALDO_DISPONIVEL)}
                  </SaldoDisponivelColor>
                </SaldoStyle>
              )}
              {CARTAO_PRODUTOS &&
                CARTAO_PRODUTOS.length > 0 &&
                CARTAO_PRODUTOS.map((val, ind) => (
                  <div
                    style={{
                      marginTop: '1rem',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div
                      style={{
                        margin: '0px 0px 0px 1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '60%',
                      }}
                    >
                      <Label>{val.DESCRICAO_PRODUTO}</Label>

                      <Label>R$ {formatMoney(val.SALDO)}</Label>
                    </div>

                    <Button width={'10px'} onClick={() => deleteProduto(ind, val)}>
                      <Trash />
                    </Button>
                  </div>
                ))}

              {novoProduto &&
                novoProduto.map((val, ind) => (
                  <Styled.Produts key={ind}>
                    {loadingProduct === false && (
                      <Select
                        key={ind}
                        inputName={'Produtos *'}
                        name="SELECT"
                        handleChange={(e) => changeProduto(e, ind, true)}
                        selects={novoProduto[ind].SELECT}
                        options={listagemAtiva}
                        error={val.SELECT_ERRO}
                      />
                    )}
                    <InputGroup
                      size="sm"
                      style={{ margin: '1.1rem 1rem 0px 1rem', display: 'flex', alignItems: 'baseline', gap: '.5rem' }}
                    >
                      <Label>R$</Label>
                      <Styled.InputSaldo
                        type="number"
                        name="SALDO"
                        placeholder="Saldo"
                        value={novoProduto[ind].SALDO}
                        onChange={(e) => changeProduto(e, ind)}
                      />
                    </InputGroup>
                    <Button width={'10px'} onClick={() => deleteProduto(ind)}>
                      <Trash />
                    </Button>
                  </Styled.Produts>
                ))}

              <ModalFooter>
                <Button onClick={addProduto} disabled={novoProduto.length > 0}>
                  Vincular novo produto
                </Button>
                <Button
                  color="primary"
                  onClick={handleClick}
                  type="submit"
                  disabled={
                    novoProduto.length == 0 ||
                    novoProduto.some((el) => el.SALDO_ERRO || el.SELECT_ERRO) ||
                    saldo.SALDO_DISPONIVEL < 0
                  }
                >
                  Finalizar
                </Button>
              </ModalFooter>
            </ModalBody>
          </FormGroup>
        </Form>
      </Modal>
      <ModalMensagemSemReload
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          setModalMensagem(false);
        }}
      />
      {/* <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          setModalMensagem(false);
        }}
      /> */}
      <ModalConfirmacao
        modal={modalConfirmacao.open}
        toggle={() => {
          setModalConfimarcao(false);
        }}
        produto={modalConfirmacao.produto}
      />
    </>
  );
}
