import { Alert, FormControl, NativeSelect } from '@mui/material';
import React from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import OperarSaldoProdutoLote from '../OperarSaldoProdutoLote/OperarSaldoProdutoLote';

const ModalLote = (props) => {
  async function handleChange(e) {
    if (props.checkMcc) {
      props.checkMcc(e.target.value);
    }
    props.setSelectValue(e.target.value);
  }
  return (
    <>
      <Modal
        isOpen={props.openPL}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          props.closePL();
        }}
      >
        <ModalHeader
          toggle={() => {
            props.closePL();
          }}
        >
          <h2>{props.title}</h2>
        </ModalHeader>
        <FormGroup>
          <ModalBody>
            {props.alertError && props.alertError.length > 1 && <Alert severity="error">{props.alertError}</Alert>}
            <FormControl fullWidth>
              <NativeSelect
                onChange={(e) => handleChange(e)}
                name="lista"
                id="lista-conteudos"
                defaultValue={null}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native',
                }}
              >
                <option value={props.selectValue}>Escolha produto</option>
                {props.listagemProduto &&
                  props.listagemProduto.map((produto) => <option value={produto.ID}>{produto.DESCRICAO}</option>)}
              </NativeSelect>
            </FormControl>
          </ModalBody>
        </FormGroup>
        <ModalFooter>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginBottom: '1rem' }}
            onClick={() => props.handleClick()}
            style={{ marginLeft: '1%' }}
            disabled={(props.alertError && props.alertError.length) || !props.selectValue.length}
          >
            {props.buttonName}
          </Button>
        </ModalFooter>
      </Modal>
      <OperarSaldoProdutoLote
        allCards={props.allCards}
        saldoGeral={props.saldoGeral}
        selectValue={props.selectValue}
        open={props.modalOperateBalanceProductBatch}
        close={() => props.setModalOperateBalanceProductBatch(false)}
        setReloadProdutos={props.setReloadProdutos}
        reloadProdutos={props.reloadProdutos}
      />
    </>
  );
};

export default ModalLote;
