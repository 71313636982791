import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

function AccordionAppliedFees({
  user,
  user: { NOMECOBAN, TAXAS, FINANCIAMENTO },
  renderTaxasUsuario,
  openAccordionTaxes,
  setOpenAccordionTaxes,
}) {
  return (
    <Accordion expanded={openAccordionTaxes}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-content"
        onClick={() => setOpenAccordionTaxes(!openAccordionTaxes)}
      >
        <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
          Taxas Aplicadas
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <p
          style={{
            display: 'block',
            marginBottom: '1rem',
            fontSize: '0.9em',
            color: '#8898aa',
            fontWeight: 'bold',
          }}
        >
          Perfil: <span style={{ fontWeight: '100', color: 'black' }}>{NOMECOBAN} </span>
        </p>

        {TAXAS && renderTaxasUsuario(TAXAS, FINANCIAMENTO, user)}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionAppliedFees;
