import api from '../../service/api';
import {
  GET_QRCODE_LOGIN,
  GET_QRCODE_LOGIN_FAIL,
  GET_QRCODE_LOGIN_SUCCESSS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
} from './types';

export const login = (props, callback) => {
  return (dispatch) => {
    dispatch({ type: GET_QRCODE_LOGIN });

    api
      .post('coban/autenticacao/enviar', props)
      .then((res) => {
        dispatch({ type: GET_QRCODE_LOGIN_SUCCESSS });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: GET_QRCODE_LOGIN_FAIL, payload: err });
        if (callback) callback(false, err);
      });
  };
};

export const validateLogin = (props, callback) => {
  return (dispatch) => {
    dispatch({ type: LOGIN });

    api
      .post('coban/novaAutenticacao/validar', props)
      .then((res) => {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({ type: LOGIN_FAIL, payload: err });
        if (callback) callback(false, err);
      });
  };
};

export const logout = (callback) => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
    callback();
  };
};
