import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { formatMoney } from '../../utils/formatMoneyBRL';
import verifyArrayExists from '../../utils/verifyArrayExists';
import * as Styled from './Card.styles';

export default function Card(props) {
  const {
    isExpanded,
    setIsExpanded,
    expandedHeight,
    height,
    width,
    color,
    iconColor,
    backgroundColorContainer,
    customIcon,
    icon2,
    text,
    text2,
    value,
    value1,
    value2,
    name,
    name2,
    data,
    children,
  } = props;

  return (
    <>
      <Styled.CardComponent width={width} height={isExpanded ? expandedHeight : height}>
        <Styled.Rectangle color={color} />
        <Styled.Container>
          <Styled.Line>
            <Styled.CardHeaderContainer>
              <Styled.IconContainer iconColor={iconColor} backgroundColor={backgroundColorContainer}>
                {customIcon}
              </Styled.IconContainer>
              <Styled.Name>{name}</Styled.Name>
            </Styled.CardHeaderContainer>
            <Styled.CardHeaderContainer>
              <Styled.Button size="small" onClick={() => setIsExpanded(!isExpanded)} data-cy="btn-isExpanded-cards">
                {isExpanded ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
              </Styled.Button>
            </Styled.CardHeaderContainer>
          </Styled.Line>
          <Styled.LineValue>
            <Styled.NameValue>{text}</Styled.NameValue>
            <Styled.Value>{name2 === 'Empréstimos' ? value : 'R$' + formatMoney(value)}</Styled.Value>
          </Styled.LineValue>

          {verifyArrayExists(data) &&
            data.map((card, index) => {
              return (
                <div key={`${index}-${name}`}>
                  <Styled.LineInCard display={isExpanded ? 'flex' : 'none'}>
                    {card.name === 'Serviços Digitais' ? (
                      <Tooltip
                        title={
                          <>
                            <h6 style={{ color: 'white' }}>Recarga de Celulares</h6>
                            <h6 style={{ color: 'white' }}>Serviços Digitais</h6>
                          </>
                        }
                        placement="right"
                      >
                        <Styled.TextInCard>{card.name}</Styled.TextInCard>
                      </Tooltip>
                    ) : (
                      <Styled.TextInCard>{card.name}</Styled.TextInCard>
                    )}
                    <Styled.ValueInCard>
                      {card.value === 'valor' ? card.value : 'R$ ' + formatMoney(card.value)}
                    </Styled.ValueInCard>
                  </Styled.LineInCard>
                  {isExpanded && index !== data.length - 1 && <Styled.LineSeparator />}
                </div>
              );
            })}

          {name2 === 'Empréstimos' && localStorage.getItem('nivelAcesso') === '1' ? (
            <>
              <Styled.LineDate>
                {' '}
                <p className="mt-3 mb-3 text-muted text-sm">
                  <span className="text-success mr-2">
                    {value1 !== 0 ? <i style={{ marginRight: '2px' }} className="fa fa-arrow-up" /> : ''}
                    {value1 !== 0 ? value1 : ''}
                  </span>{' '}
                  {value1 !== 0 ? <span className="text-wrap">Última semana</span> : null}
                </p>
              </Styled.LineDate>
              <Styled.LineSeparator />
              <Styled.Line>
                <Styled.CardHeaderContainer>
                  <Styled.IconContainer iconColor={iconColor} backgroundColor={backgroundColorContainer}>
                    {icon2}
                  </Styled.IconContainer>
                  <Styled.Name>{name2}</Styled.Name>
                </Styled.CardHeaderContainer>
              </Styled.Line>
              <Styled.LineValue>
                <Styled.NameValue>{text2}</Styled.NameValue>
                <Styled.Value>{name2 !== '' ? value2 : 'R$' + formatMoney(value2)}</Styled.Value>
              </Styled.LineValue>
            </>
          ) : null}

          {name === 'Saldo Conta Digital' ? <Styled.LineDate>{children}</Styled.LineDate> : null}

          {name === 'Aporte Taksim' ? <Styled.LineDate>{children}</Styled.LineDate> : null}
        </Styled.Container>
      </Styled.CardComponent>
    </>
  );
}

Card.propTypes = {
  icon: PropTypes.string,
  width: PropTypes.string,
  expandedHeight: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.number,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  data: PropTypes.array,
  color: PropTypes.string,
  backgroundColorContainer: PropTypes.string,
  iconColor: PropTypes.string,
};
