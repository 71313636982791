import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Redux
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';

import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/argon-dashboard-react.css';

import AdminLayout from './layouts/Admin.jsx';
import Login from './views/Login/Login.jsx';

import reducers from './store';

export const history = createBrowserHistory();
//const store = createStore(reducers, {}, applyMiddleware(ReduxThunk))

// const store = createStore(
//   reducers,
//   compose(
//     applyMiddleware(ReduxThunk),

//     // typeof window === 'object' &&
//     //   typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
//     //   ? window.__REDUX_DEVTOOLS_EXTENSION__()
//     //   : (f) => f

//     window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(ReduxThunk),
  // other store enhancers if any
);

const store = createStore(reducers, enhancer);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
