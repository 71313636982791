export const TRANSACTION_RESPONSE = 'TRANSACTION_RESPONSE';
export const TRANSACTION_RESPONSE_TED = 'TRANSACTION_RESPONSE_TED';
export const TRANSACTION_REMESSA = 'TRANSACTION_REMESSA';
export const LISTANF_RESPONSE = 'LISTANF_RESPONSE';
export const DOWNLOADNF_RESPONSE = 'DOWNLOADNF_RESPONSE';
export const TRANSACTION_AVULSE = 'TRANSACTION_AVULSE';
//ANTECIPAÇÃO
export const GET_ANTICIPATION = 'GET_ANTICIPATION';
export const GET_ANTICIPATION_SUCCESS = 'GET_ANTICIPATION_SUCCESS';
export const GET_ANTICIPATION_FAIL = 'GET_ANTICIPATION_FAIL';
export const LANCAMENTO = 'LANCAMENTO';
