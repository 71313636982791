import PropTypes from 'prop-types';
import React from 'react';
import * as Styled from './StatusContainer.styles';
export default function StatusContainerTed({ code, description }) {
  let background = '';
  let color = '';

  switch (code) {
    case '1':
      background = 'rgba(236, 114, 2, 0.15)';
      color = '#EC7202';
      break;
    case '2':
      background = 'rgba(0, 180, 35, 0.15)';
      color = '#00B423';
      break;
    case '3':
      background = 'rgba(38, 36, 36, 0.15)';
      color = '#262424';
      break;
    case '4':
      background = '';
      color = '';
      break;
    case '5':
      background = 'rgba(234, 168, 0, 0.15)';
      color = '#EAA800';
      break;
    case '6':
      background = 'rgba(187, 0, 32, 0.15)';
      color = '#BB0020';
      break;
    case '7':
      background = 'rgba(0, 62, 182, 0.15)';
      color = '#003EB6';
      break;
    case '8':
      background = 'rgba(0, 146, 212, 0.15)';
      color = '#0092D4';
      break;
    case 'TAKSIM':
      background = '#F29F05';
      color = 'black';
      break;
    case 'CDC':
      background = '#085CA6';
      color = 'white';
      break;
    default:
      background = 'rgba(125, 125, 125, 0.15)';
      color = '#7D7D7D';
  }

  return (
    <div>
      <Styled.ContainerStatus background={background} color={color}>
        {description}
      </Styled.ContainerStatus>
    </div>
  );
}

StatusContainerTed.propTypes = {
  code: PropTypes.string,
  description: PropTypes.string,
};
