import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Table from '../../../components/Table/Table';
import arredondarCasasDecimais from '../../../utils/arredondarCasasDecimais';
import formatCPF from '../../../utils/formatCPF';
import { formatMoney } from '../../../utils/formatMoneyBRL';
import { setColor } from '../../../utils/setColor';

const ModalEmprestimo = (props) => {
  const { modal, toggle, title, dataInfo, transactions } = props;

  const { CPFCNPJ, DATA_HORA, CODOPERACAO, PRIMEIRO_VENCIMENTO, NOME, VL_PARCELA, TAXA, VALOR, DESCRICAO_STATUS } =
    dataInfo;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>{title}</h3>
      </ModalHeader>
      <ModalBody
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          wordBreak: 'break-word',
        }}
      >
        <Row style={styles.metade}>
          <Col>
            <h5>Código da operação</h5>
            <p>{CODOPERACAO}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Status do empréstimo</h5>
            <p>{DESCRICAO_STATUS}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Nome</h5>
            <p>{NOME}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>CPF/CNPJ</h5>
            <p>{formatCPF(CPFCNPJ)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Data / Hora</h5>
            <p>{DATA_HORA}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Valor</h5>
            <p>R$ {formatMoney(VALOR)}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Taxa</h5>
            <p>{formatMoney(arredondarCasasDecimais(TAXA))} %</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Primeiro vencimento</h5>
            <p>{moment(PRIMEIRO_VENCIMENTO).format('DD/MM/YYYY')}</p>
          </Col>
        </Row>
        <Row style={styles.metade}>
          <Col>
            <h5>Valor da parcela</h5>
            <p>R$ {formatMoney(VL_PARCELA)}</p>
          </Col>
        </Row>
        <Row style={{ width: '110%' }}>
          <Col>
            <h5>Cronograma</h5>
            {transactions.cronograma && (
              <Table
                headers={[
                  {
                    info: 'VENCIMENTO',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'PARCELA',
                    style: { textAlign: 'center' },
                  },
                  {
                    info: 'VALOR',
                    style: { textAlign: 'right' },
                  },
                  {
                    info: 'STATUS',
                    style: { textAlign: 'center' },
                  },
                ]}
                data={
                  transactions.cronograma &&
                  transactions.cronograma.map((loan) => {
                    return {
                      data: [
                        {
                          info: moment(loan.vencimento).format('DD/MM/YYYY'),
                          style: { textAlign: 'center' },
                        },
                        {
                          info: loan.numeroParcela,
                          style: { textAlign: 'center' },
                        },
                        {
                          info: 'R$' + formatMoney(loan.valor),
                          style: { textAlign: 'right' },
                        },
                        {
                          info: setColor(loan.status),
                          style: { textAlign: 'center' },
                        },
                      ],
                    };
                  })
                }
              />
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

const mapStateToProps = ({ user, transactions }) => {
  return { user, transactions };
};

export default connect(mapStateToProps)(ModalEmprestimo);
