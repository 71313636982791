import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { StepThree, StepTwo } from '../../../../assets/icons';
import { vincularProduto } from '../../../../store/swap/actions';
import ModalMensagem from '../../../../views/Transaction/ModalMensagem';
import StepContent from './components/StepContent';

/* eslint-disable */

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,#0446AE 0%,#0446AE 50%,#0446AE 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,#0446AE 0%,#0446AE 50%,#0446AE 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient( 136deg, #0446AE 0%, #0446AE 50%, #0446AE 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: 'linear-gradient( 136deg, #0446AE 0%, #0446AE 50%, #0446AE 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <StepTwo />,
    2: <StepThree />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function getSteps() {
  return ['Produtos', 'Detalhes'];
}

export default function CustomizedSteppers(props) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [produto, setProduto] = useState([{ ID: '', SELECT: [], ID_ERRO: false, SELECT_ERRO: false }]);
  const [erroStepValidation, setErroStepValidation] = useState(false);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');

  const steps = getSteps();

  const setNewArrayProduto = produto.map((val, ind) => {
    val.ID_ERRO = val.ID ? false : true;
    val.SELECT_ERRO = val.SELECT.length > 0 ? false : true;

    return val;
  });
  const requestPass = setNewArrayProduto.filter((e) => e.ID_ERRO === true || e.SELECT_ERRO === true);

  const handleNext = (tab) => {
    const param = produto.map((e) => {
      let array = {
        ID_SWAP: props.idSwap,
        CARD_ID_TELENET: e.ID,
        ID_PRODUTO: e.SELECT,
      };
      return array;
    });

    switch (tab) {
      case 0:
        setErroStepValidation(true);
        if (!requestPass.length > 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        dispatch(
          vincularProduto(param, (res) => {
            setModalMensagem(true);
            setMensagem(res.mensagem);
            res.retorno === 'sucesso' ? setSucesso(true) : setSucesso(false);
          }),
        );
        break;
    }
  };

  useEffect(() => {
    setErroStepValidation(false);
  }, [activeStep]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Modal
        isOpen={props.modal}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          props.toggle();
          handleReset();
        }}
      >
        <ModalHeader
          toggle={() => {
            props.toggle();
            handleReset();
          }}
        >
          <h2>Vincular Cartão Swap</h2>
        </ModalHeader>

        <FormGroup>
          <ModalBody>
            <div>
              <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset}>Reset</Button>
                  </div>
                ) : (
                  <div>
                    <Typography>
                      <StepContent
                        activeStep={activeStep}
                        erroStepValidationCallback={erroStepValidation}
                        setProdutoCallback={(e) => setProduto(e)}
                        setErroStepValidationCallback={(e) => setErroStepValidation(e)}
                        dataId={props.idSwap}
                        dataProd={produto}
                        idSwap={props.idSwap}
                      />
                    </Typography>

                    <ModalFooter>
                      <Button disabled={activeStep === 0} onClick={handleBack}>
                        {activeStep === steps.length - 1 ? 'Voltar' : null}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          handleNext(activeStep);
                        }}
                      >
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Proximo'}
                      </Button>
                    </ModalFooter>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </FormGroup>
      </Modal>
      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          setModalMensagem(false);
        }}
      />
    </>
  );
}
