import { DASHBOARD_MOVIMENTACAO, DASHBOARD_RESPONSE, DASHBOARD_RESPONSE_CARDS, DASHBOARD_USERS } from './types';

const INITIAL_STATE = {
  loading_dashboard: false,
  loading_dashboard_cards: true,
  erro_dashboard: false,
  erro_message_dashboard: null,
  drawerToogle: true,
  drawerWidth: 240,
  infos: {
    saldoBanco: 0,
    saldoContaDigital: 0,
    antecipacoesAPagar: 0,
    antecipacoesPagas: 0,
    antecipacoesPendentes: 0,
    comissoesAPagar: 0,
    comissoesPagas: 0,
    saquesDisponiveis: 0,
    saquesRealizados: 0,
    totalAntecipacoes: 0,
    totalComissoes: 0,
    totalDeVendas: 0,
    totalSaques: 0,
    usuariosPendentes: 4,
    vendaRiscoPendentes: 0,
    vendasBoleto: 0,
    vendasCartao: 0,
    usuarioPorSemana: '0',
  },
  messageSaldo: '',
  retorno: '',
  listUsers: [],
  dadoSaques: {},
  transacaoCredito: {},
  transacaoDebito: {},
  listMovimentacaoDia: [],
  movimentacaoDashboard: [],
  moviventacoesDia: {},
  pagination: {},
  userPagination: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_RESPONSE:
      return { ...state, ...action.payload };
    case DASHBOARD_MOVIMENTACAO:
      var listMovimentacaoDia = state.listMovimentacaoDia;
      listMovimentacaoDia = [...listMovimentacaoDia, ...action.payload.listMovimentacaoDia];
      return {
        ...state,
        ...action.payload,
        listMovimentacaoDia,
      };
    case DASHBOARD_USERS:
      var listUsers = state.listUsers;
      listUsers = [...action.payload.listUsers];
      return {
        ...state,
        ...action.payload,
        listUsers,
      };
    case DASHBOARD_RESPONSE_CARDS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
