import React from 'react';
import { Input, Label } from 'reactstrap';

const InputDate = ({ label, setValue, value = '', name, style = '' }) => {
  return (
    <div style={style.div}>
      <Label size="sm">{label}</Label>
      <Input
        type="date"
        name={name}
        size="sm"
        max="9999-12-31"
        value={value}
        onChange={(event) => setValue(event.target.value)}
        style={style.input}
      />
    </div>
  );
};

export default InputDate;
