import Styled from 'styled-components';

export const Conteiner = Styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const SelectConteiner = Styled.div`
    padding-top: 1rem;
`;

export const CheckConteiner = Styled.div`
    padding-top: 1.3rem;
`;
