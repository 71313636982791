import styled from 'styled-components';
import { Button } from 'reactstrap';

const ButtonTab = styled(Button)`
  border-bottom-color: ${(props) => props.borderBottomColor};
  color: ${(props) => (props.color ? props.color : 'secondary')};
  background-color: ${(props) => props.color && '#fff'};
`;

export { ButtonTab };
