import { COBAN_ADD_STATE, COBAN_ALL, COBAN_TAXA_REQUEST_FAIL, COBAN_TAXA_REQUEST_SUCCESS } from './types';

const INITIAL_STATE = {
  coban: {},
  list: [],
  listLevels: [],

  loading_coban: false,
  erro_coban: false,
  erro_message_coban: null,

  taxaSucesso: [],
  taxaFail: [],
  mensagem: '',
  sucesso: false,
  modoExibe: false,
  dataVencimento: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COBAN_ALL:
      return { ...state, ...action.payload };
    case COBAN_TAXA_REQUEST_SUCCESS:
      var taxaSucesso = state.taxaSucesso;
      taxaSucesso.push(action.payload);
      return {
        ...state,
        taxaSucesso,
      };

    case COBAN_TAXA_REQUEST_FAIL:
      var taxaFail = state.taxaFail;
      taxaFail.push(action.payload);
      return {
        ...state,
        taxaFail,
      };
    case COBAN_ADD_STATE:
      return { ...state, [action.key]: action.payload };

    default:
      return state;
  }
};
