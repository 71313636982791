import Styled from 'styled-components';

export const Header = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Produts = Styled.div`
    display: flex;
    justify-content: space-around;
    padding: 2rem 0 0.8rem 0;
`;

export const Container = Styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 0.8rem 0;

    & .MuiInputBase-root.Mui-disabled {
        color: black !important
    }
`;

export const Detail = Styled.div`
    /* background-color: #fcfafa; */
    & .MuiInputBase-root.Mui-disabled {
        color: black !important
    }
`;

export const Line = Styled.div`
    border-bottom: 1px solid;
    /* padding-top: 1rem; */
    /* margin-bottom: 0.6rem; */
`;

export const InformationId = Styled.div`
    padding: 1rem 0 0 1rem;
`;

export const Information = Styled.div`
        padding-bottom: 0.4rem;
    & > span{
        padding-left: 2rem;
    }
`;
