import React from 'react';
import { useDispatch } from 'react-redux';
import { listarCartao, listarSaldoGeral } from '../../../../store/coban/actions';
import { FiRefreshCw } from 'react-icons/fi';
import formatMoney from '../../../../utils/formatMoneyBRL';
import {
  Container,
  ReloadButtonStyle,
  SaldoColor,
  SaldoContainer,
  SaldoDisponivelColor,
  SaldoStyle,
  SaldoUtilizadoColor,
} from './style';

function DigitalAccountBalaceContainer({ user, searchWithFilter, saldoGeral }) {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(listarCartao(user.CPFCNPJ));
    dispatch(listarSaldoGeral(user.CPFCNPJ));
    searchWithFilter();
  }

  return (
    <Container>
      <SaldoContainer>
        <SaldoStyle>
          Saldo Conta Digital: <SaldoColor> R$ {formatMoney(saldoGeral.SALDO_ATUAL)}</SaldoColor>
        </SaldoStyle>
        <SaldoStyle>
          Saldo utilizado:
          <SaldoUtilizadoColor> R$ {formatMoney(saldoGeral.SALDO_UTILIZADO)}</SaldoUtilizadoColor>
        </SaldoStyle>
        <SaldoStyle>
          Saldo disponível:
          <SaldoDisponivelColor> R$ {formatMoney(saldoGeral.SALDO_DISPONIVEL)}</SaldoDisponivelColor>
        </SaldoStyle>
      </SaldoContainer>

      <ReloadButtonStyle onClick={() => handleClick()}>
        <FiRefreshCw />
      </ReloadButtonStyle>
    </Container>
  );
}

export default DigitalAccountBalaceContainer;
