import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from '../../utils/formatMoneyBRL.js';
import * as Styled from './StatusContainer.styles';
export default function StatusContainerValue({ code, description, icon }) {
  let background = '';
  let color = '';

  switch (code) {
    case 'CRÉDITO':
      color = '#00B423';
      icon = 'R$ + ';
      break;
    case 'DÉBITO':
      color = '#BB0020';
      icon = 'R$ - ';
      break;
    default:
      color = '#7D7D7D';
      icon = 'R$ ';
  }

  return (
    <div>
      <Styled.ContainerStatus background={background} color={color}>
        {icon + formatMoney(description)}
      </Styled.ContainerStatus>
    </div>
  );
}

StatusContainerValue.propTypes = {
  code: PropTypes.string,
  description: PropTypes.string,
};
