import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Add, Trash } from '../../../../../assets/icons';
import Icon from '../../../../../components/Icon/Icon.styles.js';
import Select from '../../../../../components/Select/Select';
import { listarProduto, produtosVinculados } from '../../../../../store/swap/actions';
import { AdicionarProduto, EditarProduto } from '../index';
import * as Styled from './Steps.styles';
/* eslint-disable */

const StepTwo = (props) => {
  const dispatch = useDispatch();
  const initial = { ID: '', SELECT: [], ID_ERRO: false, SELECT_ERRO: false };
  useEffect(() => {
    dispatch(produtosVinculados(props.idSwap, (res) => {}));
  }, []);

  const [produto, setProduto] = useState([{ ...initial }]);
  const [modalAddProduto, setModalAddProduto] = useState(false);
  const [modalEditProduto, setModalEditProduto] = useState(false);

  useEffect(() => {
    dispatch(listarProduto());
  }, [modalEditProduto, modalAddProduto]);

  const { listagemProduto, loadingProduct, listSwap, loadingProductList } = useSelector((seg) => seg.swap);

  let listagem_swap = [];
  if (!loadingProductList) {
    listagem_swap = listSwap.map((e) => {
      return {
        ID: e.CARD_ID_TELENET,
        SELECT: e.ID_PRODUTO,
        ID_ERRO: false,
        SELECT_ERRO: false,
      };
    });
  }

  useEffect(() => {
    setProduto(listagem_swap);
    props.setProduto(listagem_swap);
  }, [listagem_swap.length > 0]);

  let listagemAtiva = [];
  if (loadingProduct === false) {
    listagemAtiva = listagemProduto.filter((e) => e.ATIVO === 'S');
  }

  const addProduto = () => {
    setProduto((state) => [...state, { ...initial }]);
    props.setProduto((state) => [...state, { ...initial }]);
    props.setErroStepValidation(false);
  };

  const changePoduto = (e, ix, arr) => {
    const { name, value } = e.target;
    let values = [...produto];
    // arr ? (values[ix][name] = [...value]) : (
    values[ix][name] = value;
    // );
    setProduto((state) => [...values]);
    props.setProduto((state) => [...values]);
  };

  const deleteProduto = (ix) => {
    let values = produto.filter((a, b) => {
      if (b !== ix) {
        return a;
      }
    });
    setProduto((state) => [...values]);
    props.setProduto((state) => [...values]);
  };

  let setNewArrayProduto = '';
  if (props.erroStepValidation === true) {
    setNewArrayProduto = produto.map((val, ind) => {
      val.ID_ERRO = val.ID ? false : true;
      val.SELECT_ERRO = val.SELECT.length > 0 ? false : true;

      return val;
    });
  }

  return (
    <div>
      <Styled.Header>
        <Button
          width={'110px'}
          marginTop={'34px !important'}
          marginLeft={'15px'}
          onClick={() => setModalAddProduto(true)}
        >
          <Icon className="fas fa-plus"></Icon>Cadastrar Produto
        </Button>
        <Button
          width={'110px'}
          marginTop={'34px !important'}
          marginLeft={'15px'}
          onClick={() => setModalEditProduto(true)}
        >
          <Icon className="fas fa-edit"></Icon>Editar Produto
        </Button>
      </Styled.Header>
      <container>
        {produto.map((val, ind) => (
          <Styled.Produts key={ind}>
            <TextField
              label="ID Cartão Telenet *"
              name="ID"
              multiline
              value={produto[ind].ID}
              onChange={(e) => changePoduto(e, ind)}
              error={val.ID_ERRO}
            />
            {loadingProduct === false && (
              <Select
                key={ind}
                inputName={'Produtos *'}
                name="SELECT"
                handleChange={(e) => changePoduto(e, ind, true)}
                selects={produto[ind].SELECT}
                options={listagemAtiva}
                error={val.SELECT_ERRO}
              />
            )}
            <Button width={'10px'} onClick={() => deleteProduto(ind)}>
              <Trash />
            </Button>
          </Styled.Produts>
        ))}
        <Button style={{ marginTop: '10px', width: '188px' }} marginLeft={'15px'} onClick={() => addProduto()}>
          <Add /> Cartão telenet
        </Button>
      </container>
      <AdicionarProduto open={modalAddProduto} close={(e) => setModalAddProduto(false)} />
      <EditarProduto open={modalEditProduto} close={(e) => setModalEditProduto(false)} />
    </div>
  );
};

export default StepTwo;
