import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { baseIMG } from '../../../../service/api';

/* eslint-disable */

const Document = (props) => {
  return (
    <Modal
      size="lg"
      style={{ maxWidth: '1000px', width: '100%' }}
      className="modal-dialog-centered"
      isOpen={props.state.openDocument}
      toggle={() => props.toggle()}
    >
      <ModalHeader toggle={() => props.toggle()}>{props.state.documentLabel}</ModalHeader>

      <ModalBody>
        <a
          href={baseIMG + props.state.urlImage}
          target="_blank"
          rel="noopener noreferrer"
          download
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingBottom: '1rem',
          }}
        >
          <Button>
            <i style={{ paddingRight: '1rem' }} className="fas fa-download" />
            Download
          </Button>
        </a>
        <object data={baseIMG + props.state.urlImage} type="application/pdf" width="100%" height="800px"></object>
      </ModalBody>
    </Modal>
  );
};

export default Document;
