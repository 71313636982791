import { Box, Modal, Typography } from '@material-ui/core';
import React from 'react';
import { CustomBadge } from '../../../components/ProductTable/ProductTableStyled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  maxWidth: '48vw',
};

export default function ModalSegmentos({ isOpen, handleOpen, segmentos }) {
  return (
    <Modal
      open={isOpen}
      onClose={() => handleOpen()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h3">
          Segmentos
        </Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1.5rem', gap: '.4rem' }}>
          {segmentos &&
            segmentos.map((segmento) => (
              <CustomBadge style={{ margin: '.2rem' }} color="primary">
                {segmento.DESCRICAO}
              </CustomBadge>
            ))}
        </div>
      </Box>
    </Modal>
  );
}
