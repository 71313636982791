import { TextField } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Edit from '../../../../assets/icons/Edit';
import { editRepresentantes } from '../../../../store/user/actions';
import formatCPF from '../../../../utils/formatCPF';
import ModalMensagem from '../../../../views/Transaction/ModalMensagem';
import { ContainerLegalRepresentative } from './ModalRepresentantesLegais.styles';

/* eslint-disable */

export default function CustomizedSteppers(props) {
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(true);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');

  const { REPRESENTANTES } = useSelector((state) => state.user.user);

  const { register, handleSubmit } = useForm({});

  const editRepresentative = (data) => {
    const result = data.REPRESENTANTES.map((val, index) => {
      val.NOME = val.NOME != undefined ? val.NOME : REPRESENTANTES[index].NOME;
      val.NOME_MAE = val.NOME_MAE != undefined ? val.NOME_MAE : REPRESENTANTES[index].NOME_MAE;
      val.EMAIL = val.EMAIL != undefined ? val.EMAIL : REPRESENTANTES[index].EMAIL;
      val.ENDERECO = val.ENDERECO != undefined ? val.ENDERECO : REPRESENTANTES[index].ENDERECO;
      val.CIDADE = val.CIDADE != undefined ? val.CIDADE : REPRESENTANTES[index].CIDADE;
      val.BAIRRO = val.BAIRRO != undefined ? val.BAIRRO : REPRESENTANTES[index].BAIRRO;
      val.CEP = val.CEP != undefined ? val.CEP : REPRESENTANTES[index].CEP;
      val.UF = val.UF != undefined ? val.UF : REPRESENTANTES[index].UF;
      val.NUMENDERECO = val.NUMENDERECO != undefined ? val.NUMENDERECO : REPRESENTANTES[index].NUMENDERECO;
      val.COMPLEMENTO = val.COMPLEMENTO != undefined ? val.COMPLEMENTO : REPRESENTANTES[index].COMPLEMENTO;
      val.NASCIMENTO =
        val.NASCIMENTO != undefined
          ? val.NASCIMENTO.split('-').reverse().join('/')
          : REPRESENTANTES[index].DATA_FUND_NASC.split('-').reverse().join('/');
      val.SIGNATARIO = val.SIGNATARIO === undefined || val.SIGNATARIO === false ? 'N' : 'S';
      val.ID = REPRESENTANTES[index].ID;
      val.CPFCNPJ = REPRESENTANTES[index].CPF;
      val.CNPJ_EMPRESA = REPRESENTANTES[index].CNPJ_EMPRESA;
      val.CELULAR =
        val.CELULAR != undefined
          ? val.CELULAR.replaceAll(/\D/g, '')
          : REPRESENTANTES[index].CELULAR.replaceAll(/\D/g, '');

      return val;
    });
    dispatch(
      editRepresentantes({ REPRESENTANTES: result }, (res) => {
        setSucesso(res.retorno === 'sucesso' ? true : false);
        setMensagem(res.mensagem);
        setModalMensagem(true);
      }),
    );
  };

  return (
    <>
      <Modal
        isOpen={props.modal}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          props.toggle();
        }}
        size="lg"
        style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader
          toggle={() => {
            props.toggle();
          }}
        >
          <h2>Representantes Legais</h2>
        </ModalHeader>

        <FormGroup>
          <ModalBody>
            <ContainerLegalRepresentative>
              <form onSubmit={handleSubmit(editRepresentative)}>
                <div>
                  {REPRESENTANTES.map((representative, index) => {
                    return (
                      <div className="card" key={index}>
                        <div className="box-description">
                          <h4>{representative.DESCRICAO}</h4>
                        </div>
                        <div className="icon-edit">
                          <FormControlLabel
                            onClick={(e) => {
                              setIsEditable(false);
                            }}
                            label="Editar"
                            control={<Edit />}
                          />
                        </div>
                        <div className="box-legal-representative-data">
                          <TextField
                            name="nome"
                            label="NOME"
                            id="nome"
                            defaultValue={representative.NOME}
                            fullWidth
                            {...register(`REPRESENTANTES.${index}.NOME`)}
                            disabled={isEditable}
                          />
                        </div>
                        <div className="box-legal-representative-data">
                          <TextField
                            name="nome_mae"
                            label="NOME MÃE"
                            id="nome_mae"
                            defaultValue={representative.NOME_MAE}
                            fullWidth
                            {...register(`REPRESENTANTES.${index}.NOME_MAE`)}
                            disabled={isEditable}
                          />
                        </div>
                        <div className="box-legal-representative-data">
                          <TextField
                            name="email"
                            label="E-MAIL"
                            id="email"
                            type="email"
                            defaultValue={representative.EMAIL}
                            fullWidth
                            {...register(`REPRESENTANTES.${index}.EMAIL`)}
                            disabled={isEditable}
                          />
                        </div>
                        <div
                          className="box-legal-representative-data"
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <TextField
                            name="NASCIMENTO"
                            label="DATA NASC"
                            id="NASCIMENTO"
                            type="date"
                            defaultValue={representative.DATA_FUND_NASC}
                            {...register(`REPRESENTANTES.${index}.NASCIMENTO`)}
                            disabled={isEditable}
                          />
                          <TextField
                            name="CPFCNPJ"
                            label="CPF"
                            id="CPFCNPJ"
                            defaultValue={formatCPF(representative.CPF)}
                            {...register(`REPRESENTANTES.${index}.CPFCNPJ`)}
                            disabled
                          />
                        </div>
                        <div
                          className="box-legal-representative-data"
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <TextField
                            name="bairro"
                            label="BAIRRO"
                            id="bairro"
                            defaultValue={representative.BAIRRO}
                            {...register(`REPRESENTANTES.${index}.BAIRRO`)}
                            disabled={isEditable}
                          />
                          <TextField
                            name="cidade"
                            label="CIDADE"
                            id="cidade"
                            defaultValue={representative.CIDADE}
                            {...register(`REPRESENTANTES.${index}.CIDADE`)}
                            disabled={isEditable}
                          />
                        </div>
                        <div className="box-legal-representative-data">
                          <TextField
                            name="endereco"
                            label="ENDERECO"
                            id="endereco"
                            defaultValue={representative.ENDERECO}
                            fullWidth
                            {...register(`REPRESENTANTES.${index}.ENDERECO`)}
                            disabled={isEditable}
                          />
                        </div>
                        <div
                          className="box-legal-representative-data"
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <TextField
                            name="numero"
                            label="Nº ENDERECO"
                            id="numero"
                            defaultValue={representative.NUMENDERECO}
                            {...register(`REPRESENTANTES.${index}.NUMENDERECO`)}
                            disabled={isEditable}
                          />
                          <TextField
                            name="complemento"
                            label="COMPLEMENTO"
                            id="complemento"
                            defaultValue={representative.COMPLEMENTO}
                            {...register(`REPRESENTANTES.${index}.COMPLEMENTO`)}
                            disabled={isEditable}
                          />
                          <TextField
                            name="uf"
                            label="UF"
                            id="uf"
                            defaultValue={representative.UF}
                            {...register(`REPRESENTANTES.${index}.UF`)}
                            disabled={isEditable}
                          />
                        </div>
                        <div
                          className="box-legal-representative-data-phone"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <TextField
                            name="cep"
                            label="CEP"
                            id="cep"
                            type="number"
                            defaultValue={representative.CEP}
                            {...register(`REPRESENTANTES.${index}.CEP`)}
                            disabled={isEditable}
                          />

                          <FormControlLabel
                            labelPlacement="top"
                            label="Celular"
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              cursor: 'context-menu',
                            }}
                            control={
                              <InputMask
                                defaultValue={representative.CELULAR}
                                label="CELULAR"
                                className="Mask"
                                mask={'(99) 99999-9999'}
                                maskChar=""
                                {...register(`REPRESENTANTES.${index}.CELULAR`)}
                                disabled={isEditable}
                                control={
                                  <TextField
                                    name="celular"
                                    label="CELULAR"
                                    id="celular"
                                    defaultValue={representative.CELULAR}
                                    fullWidth
                                    {...register(`REPRESENTANTES.${index}.CELULAR`)}
                                    disabled={isEditable}
                                  />
                                }
                              />
                            }
                          />
                        </div>
                        <div className="box-legal-representative-data" style={{ flexDirection: 'row' }}>
                          <TextField
                            name="ativo"
                            id="ativo"
                            defaultValue={
                              representative.ATIVO === '1' ? 'Representante legal ativo' : 'Representante legal inativo'
                            }
                            {...register(`REPRESENTANTES.${index}.ATIVO`)}
                            disabled
                          />

                          <FormControlLabel
                            label="SIGNATARIO"
                            control={
                              <Checkbox
                                key={index}
                                defaultChecked={representative.SIGNATARIO === 'S' ? true : false}
                                name={'SIGNATARIO'}
                                color="success"
                                {...register(`REPRESENTANTES.${index}.SIGNATARIO`)}
                                disabled={isEditable}
                              />
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {!isEditable && (
                  <footer>
                    <Button className="btn-save" width={'12.153vw'} height={'3.333vw'} type={'submit'}>
                      <span>SALVAR</span>
                    </Button>
                    <Button
                      className="btn-cancel"
                      width={'12.153vw'}
                      height={'3.333vw'}
                      type={'submit'}
                      onClick={() => {
                        setIsEditable(true);
                      }}
                    >
                      <span>CANCELAR</span>
                    </Button>
                  </footer>
                )}
              </form>
            </ContainerLegalRepresentative>
          </ModalBody>
        </FormGroup>
      </Modal>

      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          setModalMensagem(false);
        }}
      />
    </>
  );
}
