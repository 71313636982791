import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import StatusContainer from '../../components/StatusContainer/StatusContainer.js';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL.js';

import { getCardTransactionsID, updateConciliation } from '../../store/transactions/actions.js';
import formatCNPJ from '../../utils/formatCNPJ.js';
/* eslint-disable */
class ModalInformations extends Component {
  componentDidMount() {
    getCardTransactionsID(this.props.ID);
  }

  render() {
    const { modal, toggle, title, ID } = this.props;
    const {
      DATA_HORA,
      STATUS,
      VALOR,
      PRODUTO,
      SPREAD,
      VALOR_TOTAL,
      CPFCNPJ,
      CPFCNPJ_ESTABELECIMENTO,
      NOME,
      ESTABELECIMENTO,
      TID,
      TIPO_TRANSACAO,
      PARCEIRO,
      MCC,
    } = this.props.transactions.dadosCardID;

    return (
      <Modal style={{ maxWidth: 800 }} isOpen={modal} toggle={toggle} ID={ID}>
        <ModalHeader toggle={toggle}>
          <h3>{title}</h3>
        </ModalHeader>
        <ModalBody>
          <Row xs="2">
            <Col>
              <h5>Codigo da transação</h5>
              <p>{ID}</p>
            </Col>
            <Col>
              <h5>Status Transação</h5>
              {STATUS == 'AGUARDANDO' && <Badge color="info">{STATUS}</Badge>}
              {STATUS == 'APROVADO' && <Badge color="success">{STATUS}</Badge>}
              {STATUS == 'CANCELADO' && <Badge color="danger">{STATUS}</Badge>}
              {STATUS == 'VENCIDO' && <Badge color="secondary">{STATUS}</Badge>}
            </Col>
          </Row>

          <Row xs="2">
            <Col>
              <h5>Nome</h5>
              <p>{NOME}</p>
            </Col>
            <Col>
              <h5>Tipo de Transação</h5>
              <p>{TIPO_TRANSACAO}</p>
            </Col>
          </Row>
          <Row xs="2">
            <Col>
              <h5>CPF/CNPJ</h5>
              <p>{CPFCNPJ && CPFCNPJ.length == 11 ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)}</p>
            </Col>
            <Col>
              <h5>Parceiro</h5>
              <p>
                {' '}
                <StatusContainer code={PARCEIRO} description={PARCEIRO}></StatusContainer>
              </p>
            </Col>
          </Row>

          <Row xs="2">
            <Col>
              <h5>Estabelecimento</h5>
              <p>{ESTABELECIMENTO}</p>
            </Col>
            <Col>
              <h5>CPF/CNPJ do estabelecimento</h5>
              <p>
                {CPFCNPJ_ESTABELECIMENTO && CPFCNPJ_ESTABELECIMENTO.length == 11
                  ? formatCPF(CPFCNPJ_ESTABELECIMENTO)
                  : formatCNPJ(CPFCNPJ_ESTABELECIMENTO)}
              </p>
            </Col>
          </Row>
          <Row xs="2">
            <Col>
              <h5>Data/Hora</h5>
              <p>{DATA_HORA}</p>
            </Col>

            <Col>
              <h5>Produto</h5>
              <p>{PRODUTO}</p>
            </Col>
          </Row>
          <Row xs="2">
            <Col>
              <h5>TID</h5>
              <p>{TID}</p>
            </Col>
            <Col>
              <h5>MCC</h5>
              <p>{MCC ? MCC : '----'}</p>
            </Col>
          </Row>
          <Row xs="2">
            {VALOR && (
              <Col>
                <h5>Valor</h5>
                <p>R$ {formatMoney(VALOR)}</p>
              </Col>
            )}
            {SPREAD && (
              <Col>
                <h5>Taxa</h5>
                <p>R$ {formatMoney(SPREAD)}</p>
              </Col>
            )}
          </Row>
          <Row xs="2">
            {VALOR_TOTAL && (
              <Col>
                <h5>Valor Total</h5>
                <p>R$ {formatMoney(VALOR_TOTAL)}</p>
              </Col>
            )}
            <Col></Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const styles = {
  Card: { border: '1px solid #f0f0f0', borderRadius: 10 },
};

const mapStateToProps = ({ user, transactions }) => {
  return { user, transactions };
};

export default connect(mapStateToProps, {
  getCardTransactionsID,
  updateConciliation,
})(ModalInformations);
