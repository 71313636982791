import styled from 'styled-components';
// import { TextField as InputMaterial } from '@material-ui/core';
import { TextField as InputMaterial } from '@material-ui/core';

export const Container = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;

export const Input = styled(InputMaterial)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  .MuiInputBase-input {
    color: ${(props) => props.color};
  }
`;
