import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Row } from 'reactstrap';
import CartoesContaDigital from './CartoesContaDigital';
// import DropdownSelecct from './Dropdown';
import moment from 'moment';
import InputMask from 'react-input-mask';
import ModalSenha from '../../../components/Modais/Confirmar/ModalSenha';
import formatCEP from '../../../utils/formatCEP';
import formatCNPJ from '../../../utils/formatCNPJ';
import formatCPF from '../../../utils/formatCPF';
import formatDate from '../../../utils/formatDate';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import isCpf, { isCnpj } from '../../../utils/isCpf';
import {
  ModalRepresentantesLegais,
  ModalTrocaSenha,
  VincularCartaoSwap as ModalVincularCartaoSwap,
} from './modals/index';

/* eslint-disable */
const InfosUser = (props) => {
  const [modalVincularCartaoSwap, setModalVincularCartaoSwap] = useState(false);
  const [idSwap, setIdSwap] = useState('');
  const [modalRepresentantes, setModalRepresentantes] = useState(false);
  const [modalSenha, setModalSenha] = useState(false);
  const [trocaSenha, setTrocaSenha] = useState(false);
  // const toggleDropdown = () => props.setState({ dropdownOpen: !props.state.dropdownOpen });

  return (
    <Card>
      <CardBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Row>
              <Col xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    {props.state.cpf_cnpj && isCpf(props.state.cpf_cnpj) ? <b>Nome: </b> : <b>Razão Social:</b>}
                  </p>
                  <Input
                    type="text"
                    name="Nome/Razão Social"
                    value={props.state.nome}
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              {isCpf(props.state.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Nome Mãe: </b>
                    </p>
                    <Input
                      type="text"
                      name="NOME_MAE"
                      value={props.state.nome_mae}
                      onChange={(e) => props.alterValueCallback(e)}
                      disabled={props.state.alteraCliente}
                      style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                    ></Input>
                  </InputGroup>
                </Col>
              )}

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    {props.state.cpf_cnpj && isCpf(props.state.cpf_cnpj) ? <b>CPF: </b> : <b>CNPJ:</b>}
                  </p>
                  <Input
                    type="text"
                    value={
                      props.state.cpf_cnpj && isCpf(props.state.cpf_cnpj)
                        ? formatCPF(props.state.cpf_cnpj)
                        : formatCNPJ(props.state.cpf_cnpj)
                    }
                    name="CPF/CNPJ"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={true}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle3}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>{isCpf(props.state.cpf_cnpj) ? <b>Nascimento: </b> : <b>Fundação:</b>}</p>
                  <Input
                    type="text"
                    value={formatDate(props.state.nasc_fund)}
                    name="Nascimento/Fundação"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Email: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.email}
                    name="EMAIL"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Status Solicitação Cartão: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.StatusSolicitacaoCartao ? props.state.StatusSolicitacaoCartao : '-----'}
                    name="StatusSolicitacaoCartao"
                    disabled={true}
                    style={styles.inputStyle2}
                  ></Input>
                </InputGroup>
              </Col>

              {isCnpj(props.state.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Conveniada </b>
                    </p>
                    <select
                      type="select"
                      id="conveniada"
                      value={props.state.conveniada}
                      onChange={(e) => props.alterValueCallback(e)}
                      name="Conveniada"
                      style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                      disabled={props.state.alteraCliente}
                    >
                      <option value="N">Não</option>
                      <option value="S">Sim</option>
                    </select>
                  </InputGroup>
                </Col>
              )}
              {/*   <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Risco PLD: </b>
                  </p>
                  <Input
                    type="select"
                    id="riscoPLD"
                    value={props.state.riscoPLD ? props.state.riscoPLD : '-----'}
                    onChange={(e) => props.alterValueCallback(e)}
                    name="RiscoPLD"
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                    disabled={props.state.alteraCliente}
                  >
                    {props.state.riscoPLD == null ? (
                      <>
                        <option value={null}>-----</option>
                        {props.risco &&
                          props.risco.data.map((risco) => <option value={risco.ID}>{risco.DESCRICAO}</option>)}
                      </>
                    ) : (
                      <>
                        {props.risco &&
                          props.risco.data.map((risco) => <option value={risco.ID}>{risco.DESCRICAO}</option>)}
                      </>
                    )}
                  </Input>
                </InputGroup>
              </Col> */}

              {isCpf(props.state.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Matrícula: </b>
                    </p>
                    <Input
                      type="text"
                      value={props.state.matricula}
                      name="MATRICULA"
                      onChange={(e) => props.alterValueCallback(e)}
                      disabled={props.state.alteraCliente}
                      style={
                        props.state.alteraCliente
                          ? {
                              border: 'none',
                              marginTop: '-1%',
                              marginLeft: '5%',
                            }
                          : styles.inputStyle
                      }
                    ></Input>
                  </InputGroup>
                </Col>
              )}

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Conta: </b>
                  </p>
                  <Input
                    type="text"
                    value={!props.state.data_encerramento && props.state.conta ? props.state.conta : '-----'}
                    name="CONTA"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={true}
                    style={styles.inputStyle2}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Data de Abertura:</b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.data_abertura ? moment(props.state.data_abertura).format('DD/MM/YYYY') : '-----'}
                    name="DATA_ABERTURA"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={true}
                    style={styles.inputStyle2}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Telefone: </b>
                  </p>
                  <Input
                    type="text"
                    tag={InputMask}
                    mask={
                      props.state.telefone.length == '14' || props.state.telefone.length == '11'
                        ? '(99)99999-9999'
                        : '(99)9999-99999'
                    }
                    maskChar=""
                    value={props.state.alteraCliente ? formatPhoneNumber(props.state.telefone) : props.state.telefone}
                    name="TELEFONE"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Data de Encerramento:</b>
                  </p>
                  <Input
                    type="text"
                    value={
                      props.state.data_encerramento
                        ? moment(props.state.data_encerramento).format('DD/MM/YYYY')
                        : '-----'
                    }
                    name="DATA_ENCERRAMENTO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={true}
                    style={styles.inputStyle2}
                  ></Input>
                </InputGroup>
              </Col>

              {isCpf(props.user.cpf_cnpj) && (
                <Col xs={5}>
                  <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={styles.textForm}>
                      <b>Empresa: </b>
                    </p>
                    <Input
                      type="text"
                      value={props.state.nome}
                      name="EMPRESA"
                      onChange={(e) => props.alterValueCallback(e)}
                      disabled={props.state.alteraCliente}
                      style={
                        props.state.alteraCliente
                          ? {
                              border: 'none',
                              marginTop: '-1%',
                              marginLeft: '5%',
                            }
                          : styles.inputStyle
                      }
                    ></Input>
                  </InputGroup>
                </Col>
              )}
            </Row>
            <hr />
            <Row>
              <Col xs={10}>
                <h3
                  style={{
                    marginBottom: '10px',
                    transform: `${!props.state.alteraCliente ? 'translateY(-10px)' : 'translateY(0)'} `,
                  }}
                >
                  {isCpf(props.user.cpf_cnpj) ? 'Endereço Cliente' : 'Endereço Empresa'}
                </h3>
              </Col>

              {isCnpj(props.user.cpf_cnpj) && (
                <div style={{ zIndex: '999' }}>
                  <Button color="primary" size="sm" onClick={(e) => setModalRepresentantes(true)}>
                    Representantes
                  </Button>
                </div>
              )}
              <ModalRepresentantesLegais modal={modalRepresentantes} toggle={(e) => setModalRepresentantes(false)} />

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>CEP: </b>
                  </p>
                  <Input
                    type="text"
                    value={formatCEP(props.state.cep)}
                    name="CEP"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Complemento: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.complemento}
                    name="COMPLEMENTO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={
                      props.state.alteraCliente
                        ? {
                            border: 'none',
                            marginTop: '-1%',
                            marginLeft: '5%',
                          }
                        : styles.inputStyle
                    }
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Endereço: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.endereco}
                    name="ENDERECO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Cidade: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.cidade}
                    name="CIDADE"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Bairro: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.bairro}
                    name="BAIRRO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>Número: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.numero}
                    name="NUMERO"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={
                      props.state.alteraCliente
                        ? {
                            border: 'none',
                            marginTop: '-1%',
                            marginLeft: '5%',
                          }
                        : styles.inputStyle
                    }
                  ></Input>
                </InputGroup>
              </Col>

              <Col xs={5}>
                <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={styles.textForm}>
                    <b>UF: </b>
                  </p>
                  <Input
                    type="text"
                    value={props.state.uf}
                    name="UF"
                    onChange={(e) => props.alterValueCallback(e)}
                    disabled={props.state.alteraCliente}
                    style={props.state.alteraCliente ? styles.inputStyle2 : styles.inputStyle}
                  ></Input>
                </InputGroup>
              </Col>

              {/*  <Col xs={5}>
              <InputGroup size="sm" className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                <p style={styles.textForm}>
                  <b>Perfil: </b>
                </p>
                <Input
                  type="text"
                  value={props.state.nome_coban}
                  name="NOME_COBAN"
                  onChange={(e) => props.alterValueCallback(e)}
                  disabled={true}
                  style={styles.inputStyle2}
                ></Input>
              </InputGroup>
            </Col> */}
            </Row>
          </FormGroup>
        </Form>

        {/* {props.user.TAXAS && <h3>Taxas Aplicadas</h3>}

        {props.user.TAXAS && props.renderTaxasUsuarioCallback(props.user.TAXAS, props.user.FINANCIAMENTO, props.user)}

        {props.user.CARTOES && props.user.CARTOES.length != 0 ? (
          <div id="toggler" className="mt-3">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h3>Cartões</h3>
            </div>
            <Table
              size="sm"
              headers={[
                {
                  info: 'Tipo',
                  style: { textAlign: 'left' },
                },
                {
                  info: 'Número',
                  style: { textAlign: 'left' },
                },
                {
                  info: '',
                  style: { textAlign: 'right' },
                },
              ]}
              data={
                props.user.CARTOES &&
                props.user.CARTOES.map((cartao, index) => {
                  return {
                    data: [
                      {
                        info: cartao.ROTULO ? cartao.ROTULO : cartao.TIPO,
                        style: { textAlign: 'left' },
                      },

                      {
                        info: cartao.NUMERO,
                        style: { textAlign: 'left' },
                      },
                      {
                        info: (
                          <Row
                            className="mb-2"
                            style={{
                              justifyContent: 'flex-end',
                              marginRight: 10,
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              {cartao.ID_TIPO_CARTAO == 2 && (
                                <>
                                  <Button
                                    size="sm"
                                    color="success"
                                    style={{
                                      alignContent: 'flex-end',
                                    }}
                                    name="cancelar"
                                    onClick={(e) => {
                                      setModalVincularCartaoSwap(true);
                                      setIdSwap(cartao.CODCARTAO);
                                    }}
                                  >
                                    Vincular
                                  </Button>
                                  {localStorage.getItem('nivelAcesso') == 1 && (
                                    <Button
                                      size="sm"
                                      color="danger"
                                      style={{
                                        alignContent: 'flex-end',
                                      }}
                                      name="cancelar"
                                      onClick={(e) => {
                                        setModalSenha(true);
                                        setIdSwap(cartao.CODCARTAO);
                                      }}
                                    >
                                      Trocar Senha
                                    </Button>
                                  )}
                                </>
                              )}
                              {cartao.BLOQUEADO == 1 ? (
                                <Button
                                  size="sm"
                                  name="0"
                                  color="primary"
                                  style={{
                                    alignContent: 'flex-end',
                                  }}
                                  onClick={() => {
                                    props.setState({
                                      BLOQUEADO: 0,
                                      ID: cartao.CODCARTAO,
                                      PARCEIRO: cartao.PARCEIRO,
                                      DATA_VENCIMENTO: cartao.DATA_VENCIMENTO,
                                    });
                                    props.toggleConfirmacaoCallback();
                                  }}
                                >
                                  Desbloquear
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  name="1"
                                  color="warning"
                                  style={{
                                    alignContent: 'flex-end',
                                  }}
                                  onClick={() => {
                                    props.setState({
                                      BLOQUEADO: 1,
                                      ID: cartao.CODCARTAO,
                                      PARCEIRO: cartao.PARCEIRO,
                                      DATA_VENCIMENTO: cartao.DATA_VENCIMENTO,
                                    });
                                    props.toggleConfirmacaoCallback();
                                  }}
                                >
                                  Bloquear
                                </Button>
                              )}
                            </div>
                          </Row>
                        ),
                        style: { alignSelf: 'center' },
                      },
                    ],
                  };
                })
              }
            />
          </div>
        ) : null} */}

        {props.user.cartaoContaDigital != undefined && props.user.cartaoContaDigital.CPFCNPJ != undefined ? (
          <CartoesContaDigital
            cartaoContaDigital={props.state.cartaoContaDigital}
            userContaDigitalCartao={props.user.cartaoContaDigital}
            openModalPasswordBlockDigitalCardCallback={(el) => props.openModalPasswordBlockDigitalCardCallback()}
            openModalSegundaViaCallback={(el) => props.openModalSegundaViaCallback()}
          />
        ) : null}
      </CardBody>

      <ModalVincularCartaoSwap
        modal={modalVincularCartaoSwap}
        toggle={(e) => setModalVincularCartaoSwap(false)}
        idSwap={idSwap}
      />
      <ModalSenha
        modal={modalSenha}
        close={() => setModalSenha(false)}
        operation={'trocaSenha'}
        modalTrocaSenha={(e) => setTrocaSenha(true)}
      ></ModalSenha>
      <ModalTrocaSenha isOpen={trocaSenha} id={idSwap} toggle={(e) => setTrocaSenha(false)}></ModalTrocaSenha>
    </Card>
  );
};

const styles = {
  formUserStyle: {
    fontSize: '0.9em',
    color: '#8898aa',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  textForm: {
    fontSize: '0.9em',
    color: '#8898aa',
    marginRight: '10px',
    marginBottom: '0',
  },
  inputStyle: {
    border: 'solid 1px #D3D3D3',
    marginTop: '2%',
    paddingLeft: '1%',
    width: '100%',
  },
  inputStyle2: {
    border: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
  },
  inputStyle3: {
    border: 'none',
    marginTop: '2%',
    display: 'block',
    width: '100%',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
  },
  Card: { border: '1px solid #f0f0f0', borderRadius: 10, marginBottom: 15 },
  foto: { height: '50%', width: '90%' },

  modalFooterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '1vw',
  },
};

export default InfosUser;
