import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { Input, Label, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalMensagem from '../Transaction/ModalMensagem';
import { sendDataUser, editDataUser, changePasswordOfPermission } from './services/fetchsApi';
import isValidEmail from '../../utils/isValidEmail';
import formatCPF from '../../utils/formatCPF';
import formatCNPJ from '../../utils/formatCNPJ';

/* eslint-disable */

function FormCoban(props) {
  const coban = props.coban.coban;
  const { changePermissionPassword, togglePersmissionPassword } = props;

  const initialStateModal = {
    viewModal: null,
    mensagem: '',
    sucesso: '',
    load: null,
    toggle: null,
  };

  const opcoesNivelAcesso = props.coban.listLevels;
  const [identificadorDigitado, setIdentificadorDigitado] = useState('');
  const [nomeDigitado, setNomeDigitado] = useState('');
  const [emailDigitado, setEmailDigitado] = useState('');
  const [telefoneDigitado, setTelefoneDigitado] = useState('');
  const [senhaDigitada, setSenhaDigitada] = useState('');
  const [confirmarSenhaDigitada, setConfirmarSenhaDigitada] = useState('');
  const [nivelAcesso, setNivelAcesso] = useState('');
  const [modal, setModal] = useState(initialStateModal);

  useEffect(() => {
    if (coban.ID) {
      setIdentificadorDigitado(coban.CPFCNPJ);
      setNomeDigitado(coban.NOME);
      setTelefoneDigitado(coban.FONECEL);
      setEmailDigitado(coban.EMAIL);
      setNivelAcesso(coban.ID_PERFIL_USUARIO);
    }
  }, [coban]);

  const sendData = async (object) => {
    const response = await sendDataUser(object);

    if (response.status === 200) {
      return setModal({
        viewModal: true,
        mensagem: response.mensagem,
        sucesso: true,
        load: null,
      });
    } else {
      return setModal({
        viewModal: true,
        mensagem: response.mensagem,
        sucesso: false,
        load: null,
      });
    }
  };

  const editData = async (object) => {
    const response = await editDataUser(object);
    if (response.status === 200) {
      return setModal({
        viewModal: true,
        mensagem: response.mensagem,
        sucesso: true,
        load: null,
      });
    } else {
      return setModal({
        viewModal: true,
        mensagem: response.mensagem,
        sucesso: false,
        load: null,
      });
    }
  };

  const addMask = (document) => {
    if (document && document.length === 11) {
      return formatCPF(document);
    } else {
      return formatCNPJ(document);
    }
  };

  async function enviarFormulario(event) {
    event.preventDefault();

    if (
      identificadorDigitado === '' ||
      nomeDigitado === '' ||
      emailDigitado === '' ||
      telefoneDigitado === '' ||
      senhaDigitada === '' ||
      confirmarSenhaDigitada === '' ||
      nivelAcesso === ''
    ) {
      return setModal({
        viewModal: true,
        mensagem: 'Campos não podem ser vazios!',
        sucesso: false,
        load: null,
      });
    }

    if (senhaDigitada !== confirmarSenhaDigitada) {
      return setModal({
        viewModal: true,
        mensagem: 'Senhas não coincidem!',
        sucesso: false,
        load: null,
      });
    }

    if (nivelAcesso === '') {
      return setModal({
        viewModal: true,
        mensagem: 'Selecione um nível de acesso!',
        sucesso: false,
        load: null,
      });
    }

    if (senhaDigitada.length < 4) {
      return setModal({
        viewModal: true,
        mensagem: 'Senhas devem conter no mínimo 4 caracteres !',
        sucesso: false,
        load: null,
      });
    }

    const verificaEmail = await isValidEmail(emailDigitado);
    if (verificaEmail === false) {
      return setModal({
        viewModal: true,
        mensagem: 'E-mail inválido!',
        sucesso: false,
        load: null,
      });
    }

    const object = {
      CPFCNPJCOBAN: identificadorDigitado,
      NOME: nomeDigitado,
      EMAIL: emailDigitado,
      FONECEL: telefoneDigitado,
      SENHA: senhaDigitada,
      NIVEL_ACESSO: nivelAcesso,
    };

    const objectEdit = {
      CPFCNPJCOBAN: identificadorDigitado,
      NOME: nomeDigitado,
      EMAIL: emailDigitado,
      FONECEL: telefoneDigitado,
      SENHA: senhaDigitada,
      ID: coban.ID,
      NIVEL_ACESSO: nivelAcesso,
    };

    if (coban.ID) {
      await editData(objectEdit);
    } else {
      await sendData(object);
    }
  }

  const editPermissionPassword = async () => {
    if (
      senhaDigitada &&
      senhaDigitada.length >= 4 &&
      confirmarSenhaDigitada &&
      senhaDigitada === confirmarSenhaDigitada
    ) {
      const data = {
        senha: senhaDigitada,
        cpf_coban: identificadorDigitado,
        cpf_admin: localStorage.getItem('CPFCNPJ'),
      };

      await changePasswordOfPermission(data);
      return;
    }

    return setModal({
      viewModal: true,
      mensagem: 'Senhas devem conter no mínimo 4 caracteres ou não coincidem!',
      sucesso: false,
      load: null,
    });
  };

  const renderProfilesName = (profileId) => {
    const profilesName = {
      1: 'ADMINISTRADOR',
      2: 'USUÁRIO MASTER',
      3: 'COBAN',
      4: 'VENDEDOR',
      5: 'OPERADOR',
      6: 'TAKSIM',
    };

    return profilesName[profileId];
  };

  return (
    <Modal
      autoFocus={false}
      isOpen={props.open}
      toggle={() => {
        props.close();
        props.toggle();
        togglePersmissionPassword();
      }}
    >
      <ModalMensagem
        modal={modal.viewModal}
        toggle={() => setModal(initialStateModal)}
        mensagem={modal.mensagem}
        sucesso={modal.sucesso}
        load={modal.load}
      />
      <ModalHeader
        toggle={() => {
          props.close();
          props.toggle();
          togglePersmissionPassword();
          /*  window.location.reload(); */
        }}
      >
        <div>
          <h3>Informações Básicas</h3>
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={enviarFormulario}>
          <div className="container" style={{ width: '100%', padding: 0 }}>
            <div style={{ width: '100%' }}>
              <Label>CPF/CNPJ</Label>
              {coban.ID ? (
                <h4>{addMask(coban.CPFCNPJ)}</h4>
              ) : (
                <InputMask
                  mask={identificadorDigitado.length < 15 ? '999.999.999-999' : '99.999.999/9999-99'}
                  maskChar=""
                  onChange={(e) => setIdentificadorDigitado(e.target.value)}
                  value={identificadorDigitado}
                >
                  {(inputProps) => <Input {...inputProps} style={inputStyle} placeholder="" required={true} />}
                </InputMask>
              )}
            </div>

            <div style={{ width: '100%' }}>
              <Label>Nome</Label>
              {changePermissionPassword ? (
                <h4>{nomeDigitado}</h4>
              ) : (
                <Input
                  autoFocus
                  style={inputStyle}
                  placeholder=""
                  value={nomeDigitado}
                  onChange={(e) => setNomeDigitado(e.target.value)}
                  required={true}
                />
              )}

              <div style={{ width: '100%' }}>
                <Label>E-mail</Label>
                {changePermissionPassword ? (
                  <h4>{emailDigitado}</h4>
                ) : (
                  <Input
                    style={inputStyle}
                    placeholder=""
                    value={emailDigitado}
                    onChange={(e) => setEmailDigitado(e.target.value)}
                    type="email"
                    required={true}
                  />
                )}
              </div>

              <div style={{ width: '100%' }}>
                <Label>Telefone</Label>
                {changePermissionPassword ? (
                  <h4>{telefoneDigitado}</h4>
                ) : (
                  <InputMask
                    mask={
                      telefoneDigitado.length >= 6 && telefoneDigitado[5] === '9'
                        ? '(99) 9 9999-9999'
                        : '(99) 9999-9999'
                    }
                    maskChar=""
                    value={telefoneDigitado}
                    onChange={(e) => setTelefoneDigitado(e.target.value)}
                  >
                    {(inputProps) => <Input {...inputProps} style={inputStyle} placeholder="" required={true} />}
                  </InputMask>
                )}
              </div>

              <div style={{ width: '100%' }}>
                <Label>Senha</Label>
                {changePermissionPassword ? (
                  <Input
                    autoFocus
                    style={inputStyle}
                    placeholder=""
                    type="password"
                    value={senhaDigitada}
                    onChange={(e) => setSenhaDigitada(e.target.value)}
                    required={true}
                  />
                ) : (
                  <Input
                    style={inputStyle}
                    placeholder=""
                    type="password"
                    value={senhaDigitada}
                    onChange={(e) => setSenhaDigitada(e.target.value)}
                    required={true}
                  />
                )}
              </div>

              <div style={{ width: '100%' }}>
                <Label>Confirmar senha</Label>
                <Input
                  style={inputStyle}
                  placeholder=""
                  type="password"
                  value={confirmarSenhaDigitada}
                  onChange={(e) => setConfirmarSenhaDigitada(e.target.value)}
                  required={true}
                />
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <Label>Nível de acesso</Label>
              {changePermissionPassword ? (
                <h4>{renderProfilesName(nivelAcesso)}</h4>
              ) : (
                <Input
                  size="sm"
                  type="select"
                  style={inputStyle}
                  required={true}
                  value={nivelAcesso}
                  onChange={(e) => setNivelAcesso(e.target.value)}
                >
                  <option>Selecione...</option>
                  {opcoesNivelAcesso &&
                    opcoesNivelAcesso.map((item) => (
                      <option key={item.ID} value={item.ID}>
                        {item.PERFIL}
                      </option>
                    ))}
                  {localStorage.getItem('nivelAcesso') == 6 ? (
                    <option key={6} value={6}>
                      TAKSIM
                    </option>
                  ) : null}
                </Input>
              )}
            </div>

            <div style={{ width: '100%', paddingTop: 30 }}>
              {changePermissionPassword ? (
                <Button color="success" size="sm" block onClick={editPermissionPassword}>
                  {coban.ID ? 'Salvar Edição' : 'Enviar'}
                </Button>
              ) : (
                <Button color="success" size="sm" block type="submit">
                  {coban.ID ? 'Salvar Edição' : 'Enviar'}
                </Button>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

const inputStyle = {
  border: '1px solid #ced4da',
  height: '31px',
};

const mapStateToProps = ({ user, coban }) => {
  return { user, coban };
};

export default connect(mapStateToProps, {})(FormCoban);
