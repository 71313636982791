import { Alert } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as XLSX from 'xlsx';
import Loading from '../../../../../../components/Loader/Loading';
import Table from '../../../../../../components/Table/Table';
import {
  adicionandoNovoSaldoCartaoEmLote,
  listarSaldoGeral,
  operarSaldoLote,
} from '../../../../../../store/coban/actions';
import { exportarExcelCartoesProduto } from '../../../../../../store/user/actions';
import formatMoney from '../../../../../../utils/formatMoneyBRL';
import ModalMensagemSemReload from '../../../../../Transaction/ModalMensagemSemReload';
import {
  HeaderProduct,
  HeaderStyleContainer,
  HeaderSubTitle,
  HeaderTitle,
  SaldoDisponivelColor,
  SaldoUtilizadoColor,
} from './style';

/* eslint-disable */
function OperarSaldoProdutoLote({ open, close, saldoGeral, selectValue, setReloadProdutos, reloadProdutos }) {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { user } = useSelector((state) => state.user);
  const { listagemProduto } = useSelector((state) => state.swap);
  const { CARTOES_PRODUTOS, loading_coban, SALDO } = useSelector((state) => state.coban);

  const readExcel = async (file) => {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async (evt) => {
        const buffer = evt.target.result;

        const wb = XLSX.read(buffer, { type: 'buffer' });

        const wsName = wb.SheetNames[0];

        const ws = wb.Sheets[wsName];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      reader.onerror = (err) => {
        reject(err);
      };
    });

    promise.then((data) => {
      const mapData = data.map((each) => ({
        [data[0]['CDC CARD - Operar Carga']]: each['CDC CARD - Operar Carga'],
        [data[0]['__EMPTY']]: each['__EMPTY'],
        [data[0]['__EMPTY_1']]: each['__EMPTY_1'],
        [data[0]['__EMPTY_2']]: each['__EMPTY_2'],
        [data[0]['__EMPTY_3']]: each['__EMPTY_3'],
      }));
      if (validateExcel(data)) {
        mapData.shift();
        dispatch(adicionandoNovoSaldoCartaoEmLote(CARTOES_PRODUTOS, mapData));
      } else {
      }
    });
  };

  const validateExcel = (data) => {
    if (data[0].hasOwnProperty('CDC CARD - Operar Carga')) {
      if (data[0].hasOwnProperty('Saldo Disponível')) {
        if (data[0]['Saldo Disponível'] < 0) {
          setErrorMessage('Não é possível salvar negativado');
          return false;
        }
        const values = Object.values(data[1]);
        values.find((value) => value === 'CODCARTAO' || 'NUMERO' || 'CODPRODUTO' || 'NOME_PRODUTO' || 'CARGA');
        setErrorMessage('');
        return true;
      }
    }
    setErrorMessage('Excel fora do padrão CDC');
    return false;
  };
  function operarSaldoLotefn() {
    dispatch(
      operarSaldoLote(user.CPFCNPJ, CARTOES_PRODUTOS, (res) => {
        setModalMensagem(true);
        setMensagem(res.mensagem);
        if (res.retorno === 'sucesso') {
          setSucesso(true);
          dispatch(listarSaldoGeral(user.CPFCNPJ));
          close();
        } else {
          setSucesso(false);
        }
      }),
    );
  }

  useEffect(() => {
    setErrorMessage('');
  }, [open]);

  return (
    <>
      <Modal
        isOpen={open}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        size="lg"
        style={{ maxWidth: '2000px', width: '90%' }}
        toggle={() => {
          close();
        }}
      >
        <ModalHeader
          toggle={() => {
            close();
          }}
        >
          <HeaderTitle>Operar saldo em lote</HeaderTitle>
        </ModalHeader>

        <ModalBody>
          {errorMessage && (
            <Alert severity="error" color="error" style={{ marginBottom: '1rem' }}>
              {errorMessage}
            </Alert>
          )}
          <HeaderStyleContainer style={{ height: '4rem' }}>
            <div>
              <HeaderSubTitle>Produto</HeaderSubTitle>
              <HeaderProduct>
                {listagemProduto && listagemProduto.map((produto) => produto.ID === selectValue && produto.DESCRICAO)}
              </HeaderProduct>
            </div>
            {SALDO && (
              <>
                <div>
                  <HeaderSubTitle>Saldo Utilizado</HeaderSubTitle>
                  <SaldoUtilizadoColor>R$ {formatMoney(SALDO.SALDO_UTILIZADO)}</SaldoUtilizadoColor>
                </div>
                <div>
                  <HeaderSubTitle>Saldo Disponível</HeaderSubTitle>
                  <SaldoDisponivelColor>R$ {formatMoney(SALDO.SALDO_DISPONIVEL)} </SaldoDisponivelColor>
                </div>
              </>
            )}
            <div style={{ display: 'flex' }}>
              <Button
                style={{ backgroundColor: '#0245AE', color: 'white' }}
                onClick={() => dispatch(exportarExcelCartoesProduto(user.CPFCNPJ, selectValue))}
              >
                Exportar
              </Button>
              <Button
                onClick={() => {
                  hiddenFileInput.current.value = null;
                  hiddenFileInput.current.click();
                }}
                style={{ backgroundColor: '#0092D4', color: 'white' }}
              >
                Importar
              </Button>
              <input
                type="file"
                ref={hiddenFileInput}
                accept=".csv,.xlsx,.xls"
                style={{ display: 'none' }}
                onChange={({ target: { files } }) => readExcel(files[0])}
              />
            </div>
            <Button style={{ backgroundColor: '#2DCE89', color: 'white' }} onClick={operarSaldoLotefn}>
              SALVAR
            </Button>
          </HeaderStyleContainer>

          <div>
            {CARTOES_PRODUTOS && CARTOES_PRODUTOS.length != 0 ? (
              <>
                {loading_coban ? (
                  <Loading color="#2405f2" />
                ) : (
                  <div id="toggler" className="mt-3">
                    <Table
                      size="sm"
                      headers={[
                        {
                          info: 'Tipo',
                          style: { textAlign: 'left' },
                        },
                        {
                          info: 'Número',
                          style: { textAlign: 'left' },
                        },
                        {
                          info: 'Saldo Atual',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: 'CARGA',
                          style: { textAlign: 'center' },
                        },
                      ]}
                      data={
                        CARTOES_PRODUTOS &&
                        CARTOES_PRODUTOS.map((cartao, index) => {
                          return {
                            data: [
                              {
                                info: cartao.TIPO,
                                style: { textAlign: 'left' },
                              },

                              {
                                info: cartao.NUMERO,
                                style: { textAlign: 'left' },
                              },

                              {
                                info: cartao.SALDO ? 'R$ ' + cartao.SALDO : 'R$ 0,00',
                                style: { textAlign: 'center' },
                              },
                              {
                                info: cartao.CARGA ? 'R$ ' + cartao.CARGA : '----',
                                style: { textAlign: 'center' },
                              },
                            ],
                          };
                        })
                      }
                    />
                  </div>
                )}
              </>
            ) : (
              'Cliente não possui esse produto'
            )}
          </div>
        </ModalBody>
      </Modal>
      <ModalMensagemSemReload
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={(e) => {
          setModalMensagem(false);
        }}
      />
    </>
  );
}

export default OperarSaldoProdutoLote;
