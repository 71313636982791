import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// reactstrap components
import { Form } from 'reactstrap';
// core components
import moment from 'moment';
import GenericButton from '../../components/Buttons/Button.js';
import CustomContainer from '../../components/Custom/CustomContainer';
import InputDate from '../../components/Filter/components/InputDate.js';
import InputSearch from '../../components/Filter/components/InputSearch.js';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults.js';
import Filter from '../../components/Filter/Filter.js';
import * as Styled from '../../components/Filter/styles/Filter.style';
import Icon from '../../components/Icons/IconsButton.js';
import Loading from '../../components/Loader/Loading';
import Table from '../../components/Table/Table.jsx';
import LogTabs from '../../components/Tabs/TabsPage';
import { getTotalAcessos } from '../../store/transactions/actions.js';
import formatCNPJ from '../../utils/formatCNPJ';
import formatCPF from '../../utils/formatCPF';
import valueEmpty from '../../utils/valueEmpty';
/* eslint-disable */

export default function Log() {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState({
    search: '',
    DATAINICIAL: '',
    DATAFINAL: moment().format('YYYY-MM-DD'),
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    dispatch(getTotalAcessos(filter));
  }, [filter.page]);

  const {
    totalAcesso: { logs },
    loading_log,
    pagination,
  } = useSelector((state) => state.transactions);

  let { page } = filter;

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) page = pageClick;
        break;
    }
    await setFilter({ ...filter, page });
  };

  const renderTableLogAppAccess = () => {
    return (
      <Table
        headers={[
          {
            info: 'DATA/HORA',
            style: { textAlign: 'center' },
          },
          {
            info: 'NOME',
            style: { textAlign: 'left' },
          },
          {
            info: 'CPF/CNPJ',
            style: { textAlign: 'left' },
          },
          {
            info: 'EMAIL',
            style: { textAlign: 'left' },
          },
          {
            info: 'IP',
            style: { textAlign: 'left' },
          },
          {
            info: 'ID DISPOSITIVO',
            style: { textAlign: 'left' },
          },
        ]}
        data={
          logs &&
          logs.map((item) => {
            return {
              data: [
                {
                  info: item.DATAHORA,
                  style: { textAlign: 'center' },
                },
                {
                  info: valueEmpty(item.NOME),
                  style: { textAlign: 'left' },
                },
                {
                  info: item.CPFCNPJ
                    ? item.CPFCNPJ.length == 11
                      ? formatCPF(item.CPFCNPJ)
                      : formatCNPJ(item.CPFCNPJ)
                    : null,
                  style: { textAlign: 'left' },
                },
                {
                  info: valueEmpty(item.EMAIL),
                  style: { textAlign: 'left' },
                },
                {
                  info: valueEmpty(item.IP),
                  style: { textAlign: 'left' },
                },
                {
                  info: valueEmpty(item.ID_DISPOSITIVO),
                  style: { textAlign: 'left' },
                },
              ],
            };
          })
        }
        pagination={pagination}
        previousPage={{ onClick: () => tablePagination('prev') }}
        nowPage={{ page, onClick: pagination }}
        nextPage={{ onClick: () => tablePagination('next') }}
      />
    );
  };

  const renderTableLogPermissionPassword = () => {
    return (
      <>
        <Table
          headers={[
            {
              info: 'DATA/HORA',
              style: { textAlign: 'center' },
            },
            {
              info: 'NOME USUÁRIO CONTA DIGITAL',
              style: { textAlign: 'left' },
            },
            {
              info: 'CPF/CNPJ CONTA DIGITAL',
              style: { textAlign: 'center' },
            },
            {
              info: 'USUÁRIO ADMIN',
              style: { textAlign: 'left' },
            },
            {
              info: 'USUÁRIO DA PERMISSÃO',
              style: { textAlign: 'left' },
            },
          ]}
          data={
            logs &&
            logs.map((item) => {
              return {
                data: [],
              };
            })
          }
          // pagination={pagination}
          // previousPage={{ onClick: () => tablePagination('prev') }}
          // nowPage={{ page, onClick: pagination }}
          // nextPage={{ onClick: () => tablePagination('next') }}
        />
        <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Nenhum registro encontrado</h3>
      </>
    );
  };

  return (
    <div>
      <CustomContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        ></div>
        <LogTabs
          tabs={['Log Acesso APP', 'Log senha de permissão']}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <Styled.InputsContainer>
              <InputSearch
                width="30vw"
                value={filter.search}
                setValue={(value) => setFilter({ ...filter, search: value })}
              />

              <InputDate
                label="Data Inicial"
                name="DATAINICIAL"
                value={filter.DATAINICIAL}
                setValue={(value) => setFilter({ ...filter, DATAINICIAL: value })}
              />

              <InputDate
                label="Data Final"
                name="DATAFINAL"
                value={filter.DATAFINAL}
                setValue={(value) => setFilter({ ...filter, DATAFINAL: value })}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <GenericButton
                  className="mt-3"
                  color="primary"
                  type="submit"
                  size="sm"
                  onClick={() => {
                    dispatch(getTotalAcessos(filter));
                    filter.page = 1;
                  }}
                >
                  <Icon className="fas fa-search" />
                  Buscar
                </GenericButton>
              </div>
            </Styled.InputsContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <InputSelectAmountResults
                options={['10', '25', '50', '100', '1000']}
                value={filter.limit}
                setValue={(value) => setFilter({ ...filter, limit: value })}
                width={'25rem'}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3>Total de acessos:</h3>
                <h2 style={{ marginLeft: 5 }}>{logs && pagination && pagination.totalResult}</h2>
              </div>
            </div>
          </Form>
        </Filter>
        {selectedTab === 0 ? (
          loading_log ? (
            <Loading color="#11cdef" />
          ) : logs ? (
            renderTableLogAppAccess()
          ) : (
            <h3 style={{ textAlign: 'center' }}>Nenhum registro encontrado</h3>
          )
        ) : null}
        {selectedTab === 1 ? renderTableLogPermissionPassword() : null}
      </CustomContainer>
    </div>
  );
}
